import styled from 'styled-components';
import { CustomModal } from '../../../../components/modals/CustomModal';
import { FlexCol, FlexRow } from '../../../../styles/styles';

export const EditLegalTextModalContainer = styled(CustomModal)`
  ${ FlexCol }
  width: 75vw;
  overflow: hidden;

  h4 {
    margin-top: 20px;
  }

  .text-field {
    margin-top: 20px;

    label {
      color: ${({ theme }) => theme.colors.grey3};
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .custom-modal-body {
    overflow: auto;
    overflow-x: hidden;
    padding: 0;
  }
`;

export const CTAContainer = styled.div`
  ${ FlexRow }
  gap: 20px;
  margin-top: 20px;
  width: 100%;
`;
