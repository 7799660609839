import { WebServiceHelper } from '../../lib/webServiceHelperRecoil';
import { IInitiateGPADepositsRequest } from './types';

export const createGPADeposit = async (deposit: IInitiateGPADepositsRequest) => {
  try {
    const res = await WebServiceHelper.sendRequest({
      path: '/admin/transaction/gpa-deposits',
      method: 'POST',
      data: deposit,
    });
    return res;
  } catch (err: any) {
    throw new Error(err);
  }
};
