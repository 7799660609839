import styled from 'styled-components';
import { BoxShadow, FlexCenter, NoScrollBar } from '../../styles/styles';

const resultsToShowAtOnce = 3;

export const ArticleSearchContainer = styled.div`
  &.desktop-nav-global-search {
    .search-result-company-logo {
      margin-right: 15px;
    }
  }

  &.mobile-nav-global-search {
    .search-result-company-logo {
      margin-right: 10px;
    }
  }

  .company-search-result-item {
    padding: 5px 5px;
    &:not(:last-of-type) {
      border-bottom: solid 1px ${({ theme }) => theme.colors.light};
    }

    &:hover {
      background: ${({ theme }) => theme.colors.light};
      cursor: pointer;
    }
  }
`;

export const ArticleSearchWrapper = styled.div`
  position: relative;

  .search-text-field {
    margin-bottom: 0px;
  }

  .company-search-text-field label {
    margin: 0 0 10px;
  }

  .company-search-text-field input {
    border-radius: 0;
  }
`;

export const LoadingSpinnerContainer = styled.div`
  position: relative;
  height: 60px;
`;

export const NoResults = styled.div`
  ${FlexCenter}
  
  #no-company-results {
    color: ${({ theme }) => theme.colors.darkGray3};
  }
`;

export const ResultsDropdown = styled.div`
  ${BoxShadow}
  ${NoScrollBar}
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  max-height: 300px;
  padding: 15px 0 15px;
  border: 1px solid ${({ theme }) => theme.colors.darkGray3};
  background: ${({ theme }) => theme.colors.white};
  overflow: auto;
  z-index: 10;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    max-height: ${30 + (90 * resultsToShowAtOnce)}px;
  }
`;
