import styled from 'styled-components';
import { CustomModal } from '../CustomModal';

export const GPADepositModalContainer = styled(CustomModal)`
  width: 90%;
  max-width: 650px;

  .user-info {
    margin-bottom: 5px;
  }

  .deposit {
    margin: 20px 0 0;
  }

  .no-groups {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const GroupSelectionContainer = styled.div`
  margin-top: 10px;
`;

export const FieldsContainer = styled.div`
  margin-top: 15px;
`;

export const DepositTypeContainer = styled.div`
  margin-top: 5px;
`;
