import { Link } from 'react-router-dom';
import { animated } from 'react-spring';
import styled from 'styled-components';
import { FlexCol, FlexHorizontalCenter, PrimaryFont } from '../../styles/styles';

export const Nav = styled.div`
  margin-top: 10px;

  .nav-item {
    ${PrimaryFont}
    border-bottom: 1px solid ${({ theme }) => theme.colors.teal1 + '33'};
    color: ${({ theme }) => theme.colors.light};
    display: block;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    padding: 7px;
    text-decoration: none;

    &:hover {
      background: ${({ theme }) => theme.colors.teal1 + '22'};
      color: ${({ theme }) => theme.colors.tertiary};
    }

    &:focus-visible {
      outline: 1px dashed ${({ theme }) => theme.colors.darkGray1};
      outline-offset: 1px;
    }

    &.active {
      color: ${({ theme }) => theme.colors.tertiary};
    }
  }
`;

export const SideNavContainer = styled.div`
  ${ FlexCol }
  background: ${({ theme }) => theme.colors.primary};
  height: 100vh;
  justify-content: space-between;
  padding: 20px 20px 30px;
  position: fixed;
  top: 0;
  width: 300px;
`;

export const LogoContainer = styled(Link)`
  display: block;
  outline: none;
  width: 90%;

  &:focus-visible {
    outline: 1px dashed ${({ theme }) => theme.colors.darkGray1};
    outline-offset: 1px;
  }

  svg {
    display: block;
    width: 100%;
  }
`;

export const UserInfoButton = styled.button`
  ${ FlexHorizontalCenter }
  background: none;
  border: none;
  justify-content: space-between;
  min-width: 100%;
  outline: none;
  padding-right: 10px;
  
  &:focus-visible {
    outline: 1px dashed ${({ theme }) => theme.colors.darkGray1};
    outline-offset: 1px;
  }

  &:hover {
    cursor: pointer;
  }

  .user-info-wrapper {
    ${ FlexHorizontalCenter }
  }

  .user-name {
    color: ${({ theme }) => theme.colors.white};
    margin-left: 10px;
  }
`;

export const UserInfoContainer = styled.div`
  position: relative;
`;

export const UserInfoOptionsContainer = styled(animated.div)`
  background: ${({ theme }) => theme.colors.white};
  bottom: calc(100% + 10px);
  border-radius: 20px;
  left: 0;
  opacity: 0;
  padding: 10px 20px;
  position: absolute;
  width: 100%;
  
  &.hidden {
    pointer-events: none;
  }

  .user-option {
    padding: 5px 0;
  }
`;
