import { action, computed, observable, makeObservable } from 'mobx';
import { BaseModel } from './base';
import { CollectionModel, ICollectionConfig } from './collection';

type PrivateFields = '_busy' |
'_matchedCompanies' |
'_isAdmin' |
'_init';

type PrivateMatchedCompanyFields = '_busy' | '_matchedCompany';

export enum MatchType {
  Name = 'name',
  MerchantName = 'merchant_name',
}

export interface IMatchedCompanyPopulatedCompany {
  _id: string;
  companyName: string;
}

export interface IMatchedCompany {
  _id?: string;
  originalValue?: string;
  matchType?: MatchType;
  createdOn: Date;
  lastModified: Date;
  company: IMatchedCompanyPopulatedCompany;
}

export class MatchedCompanyModel extends BaseModel {
  private _busy = false;
  private _matchedCompany: IMatchedCompany = null;

  constructor(matchedCompanyInfo: IMatchedCompany) {
    super();
    makeObservable<MatchedCompanyModel, PrivateMatchedCompanyFields>(this, {
      _busy: observable,
      _matchedCompany: observable,
      _id: computed,
      busy: computed,
    });

    this._matchedCompany = matchedCompanyInfo;
  }

  get busy() { return this._busy; }
  get _id() { return this._matchedCompany._id; }
  get matchedCompany() { return this._matchedCompany; }
}

export class MatchedCompaniesModel extends BaseModel {
  private _busy = false;
  private _matchedCompanies: CollectionModel<IMatchedCompany, MatchedCompanyModel> = null;
  private _isAdmin = false;
  private _config: ICollectionConfig = {};

  constructor(config: ICollectionConfig = {}, isAdmin = false) {
    super({ basePath: '/admin/transaction/matched-companies' });
    makeObservable<MatchedCompaniesModel, PrivateFields>(this, {
      _busy: observable,
      _matchedCompanies: observable,
      _isAdmin: observable,
      matchedCompanies: computed,
      _init: action.bound,
    });

    this._config = config || {};
    this._isAdmin = isAdmin;
    this._init();
  }

  get busy() { return this._busy; }
  get matchedCompanies() { return this._matchedCompanies; }

  private _init = () => {
    const url = '/admin/transaction/matched-companies';
    this._matchedCompanies = new CollectionModel<IMatchedCompany, MatchedCompanyModel>(
      url,
      (matchedCompanyInfo: IMatchedCompany) => new MatchedCompanyModel(matchedCompanyInfo),
      this._config,
    );
  };
}
