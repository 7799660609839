import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { AvatarSkeleton } from '../Avatar/skeleton';
import { UserItemSkeletonContainer } from './styles';

interface IProps {
  className?: string;
}

export const UserListItemSkeleton: React.FC<IProps> = ({
  className = '',
}) => (
  <UserItemSkeletonContainer className={ className }>
    <div className='base-info-container'>
      <AvatarSkeleton className='avatar' />
      <div>
        <Skeleton className='user-name' />
        <Skeleton className='user-info' />
      </div>
    </div>
    <div className='right-accessory-container'>
      <Skeleton className='user-info' />
    </div>
  </UserItemSkeletonContainer>
);
