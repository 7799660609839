import React, { useCallback, useState } from 'react';
import { CTAContainer, EditLegalTextModalContainer } from './styles';
import { TextArea } from '../../../../components/TextArea';
import { IKarmaCardLegalText, KarmaCardLegalModel } from '../../../../models/karmaCard';
import { Button } from '../../../../components/Button';
import { useToaster } from '../../../../contexts/toaster-store';
import { ToastType } from '../../../../models/toaster';
import { ButtonKind } from '../../../../components/Button/styles';
import { H4 } from '../../../../styles/components/header';

interface IProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  karmaCardLegalTextModel: KarmaCardLegalModel;
  legalItem: IKarmaCardLegalText;
}

export const EditLegalTextModal: React.FC<IProps> = ({
  className = '',
  isOpen,
  onClose,
  karmaCardLegalTextModel,
  legalItem,
}) => {
  const toaster = useToaster();
  const { _id, text, name } = legalItem;
  const [updatedText, setUpdatedText] = useState( text || '');

  const onSaveClick = useCallback(async() => {
    try {
      await karmaCardLegalTextModel.editLegalText({ text: updatedText }, _id);
      await karmaCardLegalTextModel.getLegalText();
      onClose();
      toaster.push({
        type: ToastType.Success,
        message: 'Updating legal text was successful',
      });
    } catch (err: any) {
      toaster.push({
        type: ToastType.Error,
        message: err.message,
      });
    }
  },[updatedText, _id]);

  const onCancelClick = useCallback(() => {
    setUpdatedText(text || '');
    onClose();
  },[text]);

  const onTextChangeHandler = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUpdatedText(e.target.value);
  },[text, updatedText]);
  
  return (
    <EditLegalTextModalContainer
      className={ className }
      isOpen={ isOpen }
      onClose={ onClose }
      title={ 'Edit Karma Card Legal Text' }
    >
      <H4>{ name }</H4>
      <TextArea
        className='text-field'
        label='Edit legal text below'
        id='name'
        onChange={ onTextChangeHandler }
        placeholder='Enter legal text'
        value={ updatedText }
      />
      <CTAContainer>
        <Button
          kind={ ButtonKind.Primary }
          onClick={ onSaveClick }
          disabled={ text === updatedText || !!karmaCardLegalTextModel.busy }
        >
          Save
        </Button>
        <Button
          kind={ ButtonKind.Primary }
          onClick={ onCancelClick }
          disabled={ karmaCardLegalTextModel.busy }
        >
          Cancel
        </Button>
      </CTAContainer>
    </EditLegalTextModalContainer>
  );

};
