import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Button } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';
import { PencilIcon } from '../../../../components/svgs/icons/PencilIcon';
import { IThemeProps } from '../../../../styles/themes';
import { Label, ArticleItemContainer, SectionContainer } from './styles';
import { CreateUpdateArticleModal } from '../../../../components/modals/CreateUpdateArticleModal';
import { ArticleModel, ArticlesModel } from '../../../../models/article';

interface IProps extends IThemeProps {
  className?: string;
  article: ArticleModel;
  articlesModel: ArticlesModel;
  onClose: () => void;
}

const ArticleItemBase: React.FC<IProps> = ({
  className = '',
  article,
  articlesModel,
  theme,
  onClose,
}) => {
  const { title, publishedOn, introParagraph, headerBackground, headerLogo, headerTitle, headerType, listViewImage, featured, enabled, body, description, type, createdOn, company } = article;
  const [createArticleModalOpen, setCreateArticleModalOpen] = useState(false);

  const onEditClick = () => {
    setCreateArticleModalOpen(true);
  };

  const onModalClose = () => {
    setCreateArticleModalOpen(false);
    onClose();
  };

  if (!article) return null;

  const publishedOnDate = new Date(publishedOn).toDateString();
  const createdOnDate = new Date(createdOn).toDateString();

  return (
    <ArticleItemContainer className={ className }>
      <SectionContainer>
        <Label>Title: </Label>
        <div>{ title }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Created On: </Label>
        <div>{ createdOnDate }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Published On: </Label>
        <div>{ publishedOnDate }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Article Type: </Label>
        <div>{ type }</div>
      </SectionContainer>
      {
        !!company && (  
          <SectionContainer>
            <Label>Company: </Label>
            <div>{ String(company.companyName) }</div>
          </SectionContainer>
        )
      }
      {
        !!company && (  
          <SectionContainer>
            <Label>Company Id: </Label>
            <div>{ String(company._id) }</div>
          </SectionContainer>
        )
      }
      <SectionContainer>
        <Label>Intro Paragraph: </Label>
        <div>{ introParagraph }</div>
      </SectionContainer>
      {
        !!description && (
          <SectionContainer>
            <Label>Description: </Label>
            <div>{ description }</div>
          </SectionContainer>
        )
      }
      <SectionContainer>
        <Label>Body: </Label>
        <div>{ body }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Header Type: </Label>
        <div>{ headerType }</div>
      </SectionContainer>
      { 
        !!headerTitle && (
          <SectionContainer>
            <Label>Header Title: </Label>
            <div>{ headerTitle }</div>
          </SectionContainer> 
        )
      }
      {
        !!headerLogo && (
          <SectionContainer>
            <Label>Header Logo URL: </Label>
            <div>{ headerLogo }</div>
          </SectionContainer>
        )
      }
      <SectionContainer>
        <Label>Header Background URL: </Label>
        <div>{ headerBackground }</div>
      </SectionContainer>
      {
        !!listViewImage && (
          <SectionContainer>
            <Label>List View Image URL: </Label>
            <div>{ listViewImage }</div>
          </SectionContainer>
        )
      }
      <SectionContainer>
        <Label>Featured: </Label>
        <div>{ String(featured) }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Enabled: </Label>
        <div>{ String(enabled) }</div>
      </SectionContainer>
      <Button 
        kind={ ButtonKind.Blank }
        onClick={ onEditClick }
      >
        <PencilIcon stroke={ theme.colors.darkGray1 } />
      </Button>
      <CreateUpdateArticleModal
        isOpen={ createArticleModalOpen }
        onClose={ onModalClose }
        articlesModel={ articlesModel }
        article = { article }
      />
    </ArticleItemContainer>
  );
};

const ArticleItemObserver = observer(ArticleItemBase);
export const ArticleItem = withTheme(ArticleItemObserver);
