import React, { useCallback, useMemo, useState } from 'react';
import { AnalyticsItem, AnalyticsItemHeader, PageSectionContainer } from './styles';
import { H3 } from '../../../../styles/components/header';
import { PencilIcon } from '../../../../components/svgs/icons/PencilIcon';
import { TrashcanIcon } from '../../../../components/svgs/icons/TrashCanIcon';
import { observer } from 'mobx-react-lite';
import { IWebAnalytic, WebAnalyticsModel } from '../../../../models/web-analytics';
import { DeleteWebAnalyticConfirmationModal } from '../../../../components/modals/DeleteWebAnalyticConfirmationModal';
import { CreateUpdateWebAnalyticsModal } from '../../../../components/modals/CreateUpdateWebAnalyticsModal';

interface IProps {
  className?: string;
  page: string;
  analytics: IWebAnalytic[];
  analyticsModel: WebAnalyticsModel;
  deleteWebAnalyticClick: (id: string) => void;
}

const PageBase: React.FC<IProps> = ({
  className = '',
  page,
  analytics,
  analyticsModel,
  deleteWebAnalyticClick,
}) => {
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [analyticToDelete, setAnalyticToDelete] = useState('');
  const [analyticToEditId, setAnalyticToEditId] = useState('');

  const analyticToEdit = useMemo(() => analytics.find((item) => item._id === analyticToEditId), [analyticToEditId]);

  const onDeleteAnalyticClick = useCallback(() => {
    deleteWebAnalyticClick(analyticToDelete);
    setDeleteConfirmationModalOpen(false);
  }, [analyticToDelete]);

  const onDeleteWebAnalyticClickWrapper = useCallback((id: string) => {
    setDeleteConfirmationModalOpen(true);
    setAnalyticToDelete(id);
  }, []);

  const onUpdateWebAnalyticClickWrapper = useCallback((id: string) => {
    setAnalyticToEditId(id);
    setEditModalOpen(true);
  }, []);

  const onCloseDeleteConfirmationModal = useCallback(() => {
    setDeleteConfirmationModalOpen(false);
    setAnalyticToDelete('');
  }, []);

  const onCloseEditModal = useCallback(() => {
    setEditModalOpen(false);
  }, []);

  const renderAnalyticItems = useMemo(() => {
    let content = [];

    if (analytics.length === 0) {
      content.push(<AnalyticsItem key={ 'NoAnalytics' }>No Analytics Found.</AnalyticsItem>);
      return content;
    }

    content = analytics.map((item) => (
      <AnalyticsItem key={ item._id }>
        <div>{ item.name }</div>
        <div>{ item.description }</div>
        <div>{ item.subLocation }</div>
        <div className='icon-container'>
          <div onClick={ () => onUpdateWebAnalyticClickWrapper(item._id) }>
            <PencilIcon stroke='black' /> 
          </div>
          <div onClick={ () => onDeleteWebAnalyticClickWrapper(item._id) }>
            <TrashcanIcon stroke='black' />
          </div>
        </div>
      </AnalyticsItem>
    ));

    return content;
  }, [analytics]); 

  return (
    <PageSectionContainer className={ className }>
      <H3>{ page } Analytics</H3>
      <AnalyticsItemHeader>
        <div>Name</div>
        <div>Description</div>
        <div>Sub-location</div>
      </AnalyticsItemHeader>
      { renderAnalyticItems }
      <DeleteWebAnalyticConfirmationModal
        onClose={ onCloseDeleteConfirmationModal }
        onDelete={ onDeleteAnalyticClick }
        isOpen={ deleteConfirmationModalOpen }
      />
      <CreateUpdateWebAnalyticsModal
        onClose={ onCloseEditModal }
        isOpen={ editModalOpen }
        analyticsModel={ analyticsModel }
        analytic={ analyticToEdit }
      />
    </PageSectionContainer>
  );
};

export const Page = observer(PageBase);
