
import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { 
  MatchedCompanyItem,
  MatchedCompaniesHeader,
  MatchedCompaniesInfoContainer,
  MatchedCompaniesList,
  MatchedCompaniesListContainer,
  SearchContainer,
} from './styles';
import { MatchedCompaniesModel } from '../../../../models/matchedCompanies';
import { useErrorMessages } from '../../../../contexts/error-messages-store';
import { ButtonKind } from '../../../../components/Button/styles';
import { TextField, TextFieldKind } from '../../../../components/TextField';
import { debounce } from '../../../../lib/misc';
import { Waypoint } from 'react-waypoint';
import { LoadingSpinner } from '../../../../components/loading/LoadingSpinner';

interface IProps {
  className?: string;
}

export const MatchedCompaniesBase: React.FC<IProps> = ({
  className = '',
}) => {
  const errorMessages = useErrorMessages();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryParam, setSearchQueryParam] = useState('');
  const matchedCompaniesModel = useRef(new MatchedCompaniesModel({ limit: 100 }, true)).current;
  const setSearchQueryDebounced = useCallback(debounce(setSearchQueryParam, 500), []);

  const loadMatchedCompanies = useCallback((refresh?: boolean) => async (params: string, queryGeneration: boolean) => {
    try {
      await matchedCompaniesModel.matchedCompanies[refresh ? 'refresh' : 'loadMore'](params, queryGeneration);
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Loading Matched Companies',
        message: err.message,
      });
    }
  }, []);

  useEffect(() => {
    loadMatchedCompanies(true)(null, false);
  }, []);

  useEffect(() => {
    if (searchQuery) setSearchQueryDebounced(searchQuery);
    else setSearchQueryDebounced('');
  }, [searchQuery]);

  useEffect(() => {
    if (searchQueryParam) loadMatchedCompanies(true)(`search=${searchQueryParam}`, true);
    else if (matchedCompaniesModel.matchedCompanies.firstPageLoaded) loadMatchedCompanies(true)(null, false);
  }, [searchQueryParam]);

  const matchedCompaniesInfo = useMemo(() => {
    const resultsText = `${matchedCompaniesModel.matchedCompanies.total} ${searchQueryParam ? 'Results' : 'Matched Companies'}`;
    return ((matchedCompaniesModel.matchedCompanies.busy && searchQueryParam) || !matchedCompaniesModel.matchedCompanies.firstPageLoaded) ? 
      <div className='matched-companies-info-placeholder' /> :
      resultsText;
  }, [matchedCompaniesModel.matchedCompanies.busy, matchedCompaniesModel.matchedCompanies.firstPageLoaded, matchedCompaniesModel.matchedCompanies.total, searchQueryParam]);

  const matchedCompanies = matchedCompaniesModel.matchedCompanies.results;
  
  return (
    <MatchedCompaniesListContainer className={ className }>
      <SearchContainer>
        <TextField
          labelHidden
          fieldKind={ TextFieldKind.Pill }
          id='matched-companies-search-input'
          label='search matched companies'
          onChange={ (e) => setSearchQuery(e.target.value) }
          placeholder='Search Matched Companies'
          value={ searchQuery }
        />
      </SearchContainer>
      <MatchedCompaniesInfoContainer>
        { matchedCompaniesInfo }
      </MatchedCompaniesInfoContainer>
      <MatchedCompaniesHeader>
        <div className='matched-company-original-value'>Original Value</div>
        <div className='matched-company-company'>Company</div>
        <div className='matched-company-match-type'>Match Type</div>
      </MatchedCompaniesHeader>
      <MatchedCompaniesList>
        { 
          matchedCompaniesModel.matchedCompanies.busy && <LoadingSpinner />
        }
        { (matchedCompanies.length > 0 && !matchedCompaniesModel.busy) && matchedCompanies.map((mmmodel) => {
          const matchedCompany = mmmodel.matchedCompany;
          return (
            <MatchedCompanyItem
              key={ matchedCompany._id }
              className='matched-company-item'
              kind={ ButtonKind.Blank }
              onClick={ null }
            >
              <div className='matched-company-original-value'>{ matchedCompany.originalValue }</div>
              <div className='matched-company-company'>{ matchedCompany.company?.companyName || 'Null company' }</div>
              <div className='matched-company-match-type'>{ matchedCompany.matchType }</div>
            </MatchedCompanyItem>
          );
        }) }
        { (!matchedCompaniesModel.matchedCompanies.allResultsFetched && !matchedCompaniesModel.matchedCompanies.busy) && (
          <Waypoint onEnter={ matchedCompaniesModel.matchedCompanies.loadMore } />
        ) }
      </MatchedCompaniesList>
    </MatchedCompaniesListContainer>
  );
};

export const MatchedCompanies = observer(MatchedCompaniesBase);
