import React from 'react';
import { UserModel } from '../../models/users';
import { H4 } from '../../styles/components/header';
import { Avatar } from '../Avatar';
import { UserItemContainer } from './styles';

interface IProps {
  className?: string;
  rightAccessory?: JSX.Element;
  user: UserModel;
  onClick?(user: UserModel): void;
  onNameClick?(user: UserModel): void;
  onAvatarClick?(user: UserModel): void;
}

export const UserListItem: React.FC<IProps> = ({
  className = '',
  rightAccessory,
  user,
  onClick,
  onNameClick,
  onAvatarClick,
}) => {
  const _onClick = !!onClick && !onNameClick && !onAvatarClick
    ? () => onClick(user)
    : null;

  const _onNameClick = !!onNameClick
    ? () => onNameClick(user)
    : null;
  
  const _onAvatarClick = !!onAvatarClick
    ? () => onAvatarClick(user)
    : null;

  return (
    <UserItemContainer className={ `${!!onClick ? 'clickable' : '' } ${className}` }>
      <div
        className={ `base-info-container ${!rightAccessory ? 'full' : ''} ${!!onClick ? 'clickable' : '' }` }
        onClick={ _onClick }
      >
        <Avatar
          className='avatar'
          onClick={ _onAvatarClick }
          url={ user.avatar }
        />
        <div>
          <H4
            className={ `name ${!!onNameClick ? 'clickable' : '' }` }
            onClick={ _onNameClick }
          >
            { user.name }
          </H4>
          <p className='email'>{ user?.primaryEmail?.email }</p>
          <p className='date-joined'>joined on { user.dateJoined.format('MMM DD, YYYY') }</p>
        </div>
      </div>
      {
        !!rightAccessory && (
          <div className='right-accessory-container'>
            { rightAccessory }
          </div>
        )
      }
    </UserItemContainer>
  );
};
