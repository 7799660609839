import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { CommissionPayoutsOverviewsContainer, CommissionPayoutsOverviewsListContainer } from './styles';
import { CommissionsModel } from '../../../models/commisions';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { H1 } from '../../../styles/components/header';
import { CommissionPayoutOverviewItem } from './CommissionPayoutOverviewItem';
import { observer } from 'mobx-react';

interface IProps {
  className?: string;
}

const CommissionPayoutsOverviewsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const commissionsModel = useRef(new CommissionsModel()).current;
  const errorMessages = useErrorMessages();

  const loadCommissionPayoutsOverviews = useCallback(async () => {
    try {
      await commissionsModel.loadCommissionPayoutsOverviews(true);
    } catch (err: any) {
      errorMessages.push({
        message: err.message,
        title: 'Error Loading Commission Payouts Overviews',
      });
    }
  }, []);

  useEffect(() => {
    loadCommissionPayoutsOverviews();
  }, []);

  const commissionPayoutsOverviewsList = useMemo(() => {
    if (!!commissionsModel.busy) return <LoadingSpinner />;
    if (!commissionsModel.commissionPayoutsOverviews && commissionsModel.errorLoadingCommissionPayoutsOverview) return <div>Error loading commission payouts overviews</div>;

    return (
      <CommissionPayoutsOverviewsListContainer>
        {
          commissionsModel.commissionPayoutsOverviews?.map((commissionPayoutsOverview) => (
            <CommissionPayoutOverviewItem 
              commissionsModel={ commissionsModel }
              key={ commissionPayoutsOverview._id }
              commissionPayoutOverviewItem={ commissionPayoutsOverview }
            />
          ))
        }
      </CommissionPayoutsOverviewsListContainer>
    );
  }, [commissionsModel.commissionPayoutsOverviews, commissionsModel.busy, commissionsModel.errorLoadingCommissionPayoutsOverview]);

  return (
    <CommissionPayoutsOverviewsContainer title='Commission Payouts Overviews' className={ className }>
      <H1>Commission Payouts Overviews</H1>
      { commissionPayoutsOverviewsList }
    </CommissionPayoutsOverviewsContainer>
  );
};

export const CommissionPayoutsOverviews = observer(CommissionPayoutsOverviewsBase);
