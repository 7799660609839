import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { Button } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';
import { GroupLogo } from '../../../../components/GroupLogo';
import { DeleteGroupConfirmationModal } from '../../../../components/modals/DeleteGroupConfirmationModal';
import { IMoreMenuOption, MoreMenu } from '../../../../components/MoreMenu';
import { LockIcon } from '../../../../components/svgs/icons/LockIcon';
import { useToaster } from '../../../../contexts/toaster-store';
import { buildBaseKarmaUrl } from '../../../../lib/urlBuilders';
import { GroupModel, GroupStatus } from '../../../../models/group';
import { ToastType } from '../../../../models/toaster';
import { H5 } from '../../../../styles/components/header';
import { DeleteOption, GroupItemContainer, StatusContainer } from './styles';

interface IProps {
  className?: string;
  group: GroupModel;
  onAction?(): void;
  onClick(): void;
  onDelete?(): void;
}

enum GroupOptions {
  CopyUrl = 'copy-url',
  Lock = 'lock',
  Unlock = 'unlock',
  Delete = 'delete',
}

const GroupItemBase: React.FC<IProps> = ({
  className = '',
  group,
  onAction,
  onClick,
  onDelete,
}) => {
  const toaster = useToaster();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  
  const getMoreMenuOptions = useCallback(() => {
    const menuOptions: IMoreMenuOption[] = [
      {
        id: GroupOptions.CopyUrl,
        context: GroupOptions.CopyUrl,
        content: 'Copy Signup Url',
      },
    ];

    if (group.status === GroupStatus.Locked) {
      menuOptions.push({
        id: GroupOptions.Unlock,
        context: GroupOptions.Unlock,
        content: 'Unlock Group',
      });
    } else {
      menuOptions.push({
        id: GroupOptions.Lock,
        context: GroupOptions.Lock,
        content: 'Lock Group',
      });
    }

    menuOptions.push({
      id: GroupOptions.Delete,
      context: GroupOptions.Delete,
      content: <DeleteOption>Delete Group</DeleteOption>,
    });
    
    return menuOptions;
  }, []);

  const onCancelDeleteClick = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const onConfirmDeleteClick = useCallback(async () => {
    await group.delete();
    onDelete();
    setShowDeleteModal(false);
    toaster.push({ message: 'Group Deleted.', type: ToastType.Error });
  }, [group]);

  const onMoreMenuOptionClick = useCallback(async (option: IMoreMenuOption) => {
    switch (option.context) {
      case GroupOptions.CopyUrl:
        navigator.clipboard.writeText(`${buildBaseKarmaUrl('karma')}?groupCode=${group.code}`);
        toaster.push({ message: 'Url Copied to Cliboard.' });
        onAction();
        break;
      case GroupOptions.Lock:
        await group.update({ status: GroupStatus.Locked });
        toaster.push({ message: 'Group Locked.', type: ToastType.Warning });
        onAction();
        break;
      case GroupOptions.Unlock:
        await group.update({ status: GroupStatus.Open });
        toaster.push({ message: 'Group Unlocked.' });
        onAction();
        break;
      case GroupOptions.Delete:
        setShowDeleteModal(true);
        onAction();
        break;
      default:
        toaster.push({ message: `Invalid Option ${option.context}.`, type: ToastType.Error });
        break;
    }
  }, []);

  return (
    <GroupItemContainer className={ className }>
      <div className='base-info-container'>
        <div className='logo-container'>
          {
            group.status === GroupStatus.Locked && (
              <StatusContainer>
                <LockIcon className='locked' />
              </StatusContainer>
            )
          }
          <GroupLogo group={ group } />
        </div>
        <div>
          <Button
            kind={ ButtonKind.Blank }
            onClick={ onClick }
          >
            <div className='group-name'>{ group.name }</div>
          </Button>
          <p>{ `Owned by ${ group.owner.name }` }</p>
          <p className='created-on'>{ `Created on ${ group.createdOn.format('MMM DD, YYYY') }` }</p>
        </div>
      </div>
      <div className='group-code-container'>
        <div>Group Code:</div>
        <H5 className='group-code'>{ group.code }</H5>
      </div>
      <div className='group-members-container'>
        { `${group.totalMembers} members` }
      </div>
      <div>
        <MoreMenu
          options={ getMoreMenuOptions() }
          onOptionClick={ onMoreMenuOptionClick }
        />
      </div>
      <DeleteGroupConfirmationModal
        group={ group }
        isOpen={ showDeleteModal }
        onDelete={ onConfirmDeleteClick }
        onClose={ onCancelDeleteClick }
      />
    </GroupItemContainer>
  );
};

export const GroupItem = observer(GroupItemBase);
