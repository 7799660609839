import styled from 'styled-components';
import { MainAdminContainer } from '../../../components/MainAdminContainer';
import { Tabs } from '../../../components/Tabs';
import { BasicContainer, FlexHorizontalCenter } from '../../../styles/styles';

export const DataContainer = styled(MainAdminContainer)``;

export const DataTabs = styled(Tabs)`
  margin-top: 20px;
`;

export const MainContainer = styled.div`
  flex-grow: 1;
  align-items: flex-start;
  height: calc(100% - 90px);
  padding-top: 20px;

  & > * {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
  }
`;

export const SummaryContainer = styled.div`
  ${ BasicContainer };
  ${ FlexHorizontalCenter }
  justify-content: center;
  height: 200px;
  margin-top: 20px;
  text-align: center;
`;
