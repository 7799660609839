import styled from 'styled-components';
import { FlexCol, FlexHorizontalCenter, FlexRow } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';

export const BannerDataContainer = styled.div``;

export const CreateUpdateBannerModalContainer = styled(CustomModal)`
  ${ FlexCol }
  width: 75vw;
  height: 70vh;
  overflow: hidden;

  .modal-header {
    margin-bottom: 20px;
  }

  .text-field {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .custom-modal-body {
    overflow: scroll;
  }
`;

export const Row = styled.div`
  ${ FlexHorizontalCenter }
  align-items: flex-start;
  
  &:not(:last-child) {
    padding-bottom: 20px;
  }
`;

export const BannerInfoContainer = styled.div`
  flex-grow: 1;
`;

export const BannerContainer = styled.div`
  .banner-dropdown {
    max-width: 300px;
  }
`;

export const InputContainer = styled.div`
  padding-bottom: 20px;
    
  .regular-label {
    font-weight: 600;
  }
`;

export const BannerLoggedInStateSection = styled(InputContainer)`
  ${ FlexRow }
  align-items: center;
  justify-content: flex-start;

  .banner-loggedin-state-dropdown  {
    max-width: 300px;
    margin-left: 10px;
  }
`;

export const BannerColorSection = styled(InputContainer)`
  ${ FlexRow }
  align-items: center;
  justify-content: flex-start;

  .banner-color-dropdown {
    max-width: 300px;
    margin-left: 10px;
  }
`;

export const DateContainer = styled.div`
  margin-left: 10px;

  label {
    margin-right: 10px;
    font-size: 15px;
  }
`;

export const DatesSection = styled(InputContainer)`
  .dates-subtext {
    margin: 10px 0;
    font-size: 14px;
    font-style: italic;
  }
`;

export const DatesWrapper = styled.div`
  ${ FlexRow }
`;
