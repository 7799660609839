import styled, { css } from 'styled-components';
import { Button } from '../../../../components/Button';
import { BasicContainer, FlexCenter, FlexHorizontalCenter, NoScrollBar, PrimaryFont } from '../../../../styles/styles';

export const FilterHeader = styled.div`
  ${ PrimaryFont }
  margin: 0;
  font-size: 18px;
  font-weight: 600;
`;

export const FilterItemHeader = styled(FilterHeader)`
  margin: 15px 0 5px;
  font-size: 16px;
  text-align: left;
`;

export const DataSectorsContainer = styled.div`
  ${FlexHorizontalCenter}
  align-items: flex-start;
`;

export const NoFilter = styled.div`
  height: 100%;
  color: ${({ theme }) => theme.colors.lightGray1};
  text-align: center;
`;

export const NoSectors = styled.div`
  ${FlexCenter}
  min-height: 50px;
  color: ${({ theme }) => theme.colors.lightGray1};
`;

export const SearchContainer = styled.div`
  padding-bottom: 10px;
`;

export const SectorsCount = styled.span`
  position: relative;
`;

export const SectorsFilter = styled.div`
  ${BasicContainer}
  position: relative;
  min-width: 200px;
  max-width: 200px;
  min-height: 250px;
  padding: 20px 10px;

  &.failed-to-load {
    ${FlexCenter}
  }

  .sectors-carbon-multiplier-slider {
    margin: 0 auto;
    width: calc(100% - 20px);
  }

  .rc-slider-track {
    background: ${({ theme }) => theme.colors.green};
  }

  .rc-slider-handle {
    border: 2px solid ${({ theme }) => theme.colors.green};
  }
`;

export const ManualMatchesInfoContainer = styled.div`
  ${ FlexHorizontalCenter }
  justify-content: space-between;
  padding: 0 20px 10px;
`;

export const ManualMatchesList = styled.div`
  ${ NoScrollBar }
  flex-grow: 1;
  max-height: calc(100% - 145px);
  padding-bottom: 10px;
  overflow: auto;

  .loading-sectors-spinner {
    position: relative;
    min-height: 40px;
  }
`;

export const ManualMatchesListContainer = styled.div`
  flex-grow: 1;
  position: relative;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  padding-left: 20px;
  overflow: hidden;
`;

const ManualMatchRowStyles = css`
  ${FlexHorizontalCenter}
  min-width: 100%;
  padding: 5px 10px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray1};
  border-top: none;
  border-radius: 0;

  .manual-match-original-value {
    flex-grow: 1;
    max-width: calc(100% - 350px);
    padding: 0 10px;
  }

  .manual-match-match-type {
    width: 150px;
    text-align: center;
  }

  .manual-match-company {
    width: 200px;
    text-align: left;
  }
`;

export const ManualMatchesHeader = styled.div`
  ${ManualMatchRowStyles}
  border: 1px solid ${({ theme }) => theme.colors.darkGray1};
  background: ${({ theme }) => theme.colors.darkGray1};
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  & > * {
    color: #fff;
  }
`;

export const ManualMatchItem = styled(Button)`
  ${ManualMatchRowStyles}
  text-align: left;

  &:hover {
    background: ${({ theme }) => theme.colors.lightGray2};
  }
`;

export const SectorItemSkeletonContainer = styled.div`
  ${ManualMatchRowStyles}

  & > span {
    display: block;
    
    &:first-child {
      width: 50px;
      text-align: center;
    }

    &:nth-child(2) {
      flex-grow: 1;
      max-width: calc(100% - 190px);
      padding: 0 10px;
    }

    &:last-child {
      width: 140px; 
    }
  }
`;
