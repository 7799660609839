import React, { useRef } from 'react';
import { UserCircleIcon } from '../svgs/icons/UserCircleIcon';
import { AvatarContainer, AvatarSize } from './styles';

interface IProps {
  className?: string;
  fill?: string;
  onClick?(): void;
  size?: AvatarSize;
  url?: string;
}

export const Avatar: React.FC<IProps> = ({
  className = '',
  fill = 'white',
  onClick,
  size = AvatarSize.Regular,
  url,
}) => {
  const isDefault = useRef(!url).current;

  const renderAvatar = () => {
    if (isDefault) return <UserCircleIcon fill={ fill } />;

    return <img src={ url } />;
  };

  return (
    <AvatarContainer
      className={ `${isDefault ? 'default' : ''}${!!onClick ? ' clickable' : ''} ${className}` }
      onClick={ onClick }
      size={ size }
    >
      { renderAvatar() }
    </AvatarContainer>
  );
};
