import styled from 'styled-components';
import { MainAdminContainer } from '../../../components/MainAdminContainer';

export const ActionsListContainer = styled.div`
  padding: 20px 0;

  a {
    display: block;
    font-size: 21px;
    color: ${({ theme }) => theme.colors.darkGray1};
    text-decoration: none;

    &:hover,
    &:focus-visible {
      color: ${({ theme }) => theme.colors.primary};
    }

    &:hover {
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export const HomeContainer = styled(MainAdminContainer)``;
