import React, { useCallback, useRef, useState } from 'react';
import { FileUploaderModel } from '../../models/fileUploader';
import { ButtonKind } from '../Button/styles';
import { FileUploader } from '../FileUploader';
import { IGroupLogoProps, EditButtonContainer, UploaderCTAs } from '../GroupLogo/styles';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { PencilIcon } from '../svgs/icons/PencilIcon';
import { ImageUploaderContainer } from './styles';

interface IProps extends IGroupLogoProps {
  allowEditing?: boolean;
  className?: string;
  setNewImage: React.Dispatch<React.SetStateAction<any>>;
  setFileToUpload: React.Dispatch<React.SetStateAction<any>>;
  setPreviewImg: React.Dispatch<React.SetStateAction<any>>;
  setImageUrl: React.Dispatch<React.SetStateAction<any>>;
  previewImg: string;
}

const EditButton: React.FC = () => (
  <EditButtonContainer>
    <PencilIcon />
  </EditButtonContainer>
); 

export const ImageUploader: React.FC<IProps> = ({
  allowEditing,
  className = '',
  setNewImage,
  setImageUrl,
  setFileToUpload,
  previewImg, 
  setPreviewImg,
}) => {
  const fileUploader = useRef(new FileUploaderModel('karma')).current;
  const [loadingPreview, setLoadingPreview] = useState(false);

  const onImageChange = useCallback((file: File) => {
    if (!!file) {
      setFileToUpload(file);
      setLoadingPreview(true);
      const reader = new FileReader();

      reader.onload = function (e: ProgressEvent<FileReader>) {
        setPreviewImg(e.target.result.toString());
        setLoadingPreview(false);
        setImageUrl('');
      };

      reader.readAsDataURL(file);
      setNewImage(true);
    }
  }, []);

  const onCancelUploadClick = useCallback(() => {
    setFileToUpload(null);
    setPreviewImg('');
    setImageUrl('');
  }, []);

  const renderEditButton = () => {
    if (!allowEditing) return null;

    if (!previewImg) {
      return (
        <FileUploader
          className='file-uploader'
          label='Edit Image'
          anchor={ <EditButton /> }
          onChange={ onImageChange }
        />
      );
    }

    return (
      <UploaderCTAs
        className='file-uploader'
        ctas={ [
          {
            id: 'cancel-company-logo-upload',
            text: 'Clear',
            kind: ButtonKind.PrimaryGhost,
            onClick: onCancelUploadClick,
          },
        ] }
      />
    );
  };

  const renderImage = () => {
    if (loadingPreview) return <LoadingSpinner />;
    if (!!previewImg) return <img src={ previewImg } alt='preview of newly uploaded logo' />;
  };

  return (
    <ImageUploaderContainer
      className={ className }
    >
      { !!fileUploader.uploadingImage && <LoadingSpinner /> }
      { renderImage() }
      { renderEditButton() }
    </ImageUploaderContainer>
  );
};
