import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { SideNav } from '../SideNav';
import { MainAdminContainerContainer } from './styles';

interface IProps {
  className?: string;
  title: string;
  children: React.ReactNode;
}

const MainAdminContainerBase: React.FC<IProps> = ({
  children,
  className = '',
  title,
}) => {
  useEffect(() => {
    const pageTitle = `${title || 'Admin'} - `;
    document.title = `${pageTitle}Karma Wallet`;
  }, [title]);

  return (
    <MainAdminContainerContainer className={ className }>
      <SideNav className='side-nav' />
      <div className='main-content'>
        { children }
      </div>
    </MainAdminContainerContainer>
  );
};

export const MainAdminContainer = observer(MainAdminContainerBase);
