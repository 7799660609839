import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { withTheme } from 'styled-components';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { IPromosCampaignReport, IPromosReport, IPromosSourcesReport } from '../../../models/chart';
import { ReportModel, ReportType } from '../../../models/reports';
import { IThemeProps } from '../../../styles/themes';
import { LoadingSpinner } from '../../loading/LoadingSpinner';
import { ChartContainer, PromosReportContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  report: ReportModel;
}
interface GenericReport {
  [key: string]: unknown;
}

const PromosReportBase: React.FC<IProps> = ({ className = '', report, theme }) => {
  const errorMessages = useErrorMessages();
  const [data, setData] = useState<GenericReport[]>([]);
  const [reportType, setReportType] = useState<ReportType | null>(null);
  const barBreakdownColors = [
    theme.colors.lightGreen1,
    theme.colors.tertiary,
    theme.colors.lightBlue1,
    theme.colors.lightDanger,
  ];

  useEffect(() => {
    report
      .load()
      .then(() => {
        /* use the report type to choose parsing strategy */
        setReportType(report.reportId);
        setData(mapPromoChartToGenericReport(report.chart.data as IPromosReport[], report.reportId));
      })
      .catch((err) => {
        errorMessages.push({
          title: 'Error Loading Report',
          message: err.message,
        });
      });
  }, []);

  const mapPromoChartToGenericReport = (chart: IPromosReport[], reportType: ReportType): GenericReport[] => {
    if (reportType === ReportType.PromoUsersByCampaign) {
      return chart?.map((campaign) => ({
        ...(campaign as IPromosCampaignReport)?.campaigns,
        total: campaign?.total,
        promo: campaign?.promo,
      }));
    } else if (reportType === ReportType.PromoUsersBySource) {
      return chart?.map((source) => ({
        ...(source as IPromosSourcesReport)?.sources,
        total: source?.total,
        promo: source?.promo,
      }));
    }
    return chart as unknown as GenericReport[];
  };

  const renderBars = (): JSX.Element => {
    try {
      const bars: JSX.Element[] = [];
      if (reportType === ReportType.PromoUsersByAccountStatus) {
        bars.push(<Bar key={ 'notLinked' } dataKey='notLinked' stackId='a' barSize={ 40 } fill={ barBreakdownColors[0] } />);
        bars.push(<Bar key={ 'linked' } dataKey='linked' stackId='a' barSize={ 40 } fill={ barBreakdownColors[1] } />);
      } else if (reportType === ReportType.PromoUsersByCampaign) {
        const campaignData = data;
        campaignData?.forEach((campaign) => {
          Object.keys(campaign).forEach((key, i) => {
            if (key === 'total' || key === 'promo') {
              return;
            }
            bars.push(
              <Bar
                key={ key }
                dataKey={ key }
                stackId='a'
                barSize={ 40 }
                fill={ barBreakdownColors[i % barBreakdownColors.length] }
              />,
            );
          });
        });
      } else if (reportType === ReportType.PromoUsersBySource) {
        const sourcesData = data;
        sourcesData?.forEach((source) => {
          Object.keys(source).forEach((key, i) => {
            if (key === 'total' || key === 'promo') {
              return;
            }
            bars.push(
              <Bar
                key={ key }
                dataKey={ key }
                stackId='a'
                barSize={ 40 }
                fill={ barBreakdownColors[i % barBreakdownColors.length] }
              />,
            );
          });
        });
      }
      return <>{ bars || undefined }</>;
    } catch (err) {
      errorMessages.push({
        title: 'Error Parsing Report',
        message: (err as { message: string })?.message || 'An error occurred while parsing the report',
      });
      return <></>;
    }
  };

  const renderBarsCallback = useCallback(() => renderBars(), [data]);

  const renderChart = () => {
    if (report.busy || !data) {
      return <LoadingSpinner />;
    }

    return (
      <ResponsiveContainer width='100%' height={ 325 }>
        <ComposedChart
          width={ 500 }
          height={ 200 }
          data={ data }
          margin={ {
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          } }
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='promo' />
          <YAxis />
          <Tooltip />
          <Legend />
          { renderBarsCallback() }
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  return (
    <PromosReportContainer className={ className }>
      <ChartContainer>{ renderChart() }</ChartContainer>
      { !!report.lastUpdated && (
        <p className='last-updated'>last updated { report.lastUpdated.format('MMM DD, YYYY @ HH:MM A') }</p>
      ) }
    </PromosReportContainer>
  );
};

const PromosReportAsObserver = observer(PromosReportBase);
export const PromosReport = withTheme(PromosReportAsObserver);
