import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { CreateUpdateCampaignModal } from '../../../components/modals/CreateUpdateCampaignModal';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { CampaignModel, CampaignsModel } from '../../../models/campaigns';
import { H1 } from '../../../styles/components/header';
import { CampaignItem } from './CampaignItem';
import { ButtonRow, CampaignsContainer } from './styles';

interface IProps {
  className?: string;
}

const CampaignsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const campaignsModel = useRef(new CampaignsModel({ limit: 300 }, true)).current;
  const errorMessages = useErrorMessages();
  const [createCampaignModalOpen, setCreateCampaignModalOpen] = useState(false);

  const loadCampaigns = useCallback((refresh?: boolean) => async () => {
    try {
      await campaignsModel.campaigns[refresh ? 'refresh' : 'loadMore']();
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Loading Campaigns',
        message: err.message,
      });
    }
  }, []);

  useEffect(() => {
    loadCampaigns(true)();
  }, []);

  const reloadCampaigns = () => {
    loadCampaigns(true)();
  };

  const onModalClose = () => {
    setCreateCampaignModalOpen(false);
    reloadCampaigns();
  };
  
  const onCreateClick = () => setCreateCampaignModalOpen(true);

  const currentCampaigns = () => {
    if (campaignsModel.campaigns.busy) return <LoadingSpinner />;
    if (!campaignsModel.campaigns.busy && campaignsModel.campaigns.total === 0) return <p>No campaigns found.</p>;

    return campaignsModel.campaigns.results.map((campaign: CampaignModel) => (
      <CampaignItem 
        key={ campaign._id }
        campaign={ campaign }
        campaignsModel={ campaignsModel }
        onClose={ reloadCampaigns }
      />
    ));
  };

  return (
    <CampaignsContainer className={ className } title='Campaigns'>
      <H1>Campaigns</H1>
      <ButtonRow>
        <Button
          kind={ ButtonKind.Primary }
          onClick={ onCreateClick }
          className='create-campaign-button'
        >
          Create Campaign
        </Button>
      </ButtonRow>
      <CreateUpdateCampaignModal
        isOpen={ createCampaignModalOpen }
        onClose={ onModalClose }
        campaignsModel={ campaignsModel }
      />
      { currentCampaigns() }
    </CampaignsContainer>
  );
};

export const Campaigns = observer(CampaignsBase);
