import React, { useCallback } from 'react';
import { useAnalytics } from '../../contexts/analytics-store';
import { ButtonKind } from '../Button/styles';
import { Tab, TabsContainer } from './styles';

export interface ITab<T> {
  text: string | JSX.Element;
  context: T;
  className?: string;
  selected?: boolean;
}

interface IProps<T> {
  analyticsEventNamePrefix?: string;
  className?: string;
  tabs: ITab<T>[];
  onTabClick(tab: ITab<T>): void;
}

export const Tabs = <T extends string>({
  analyticsEventNamePrefix,
  className = '',
  tabs,
  onTabClick,
}: IProps<T>) => {
  const anaylytics = useAnalytics();
  const _onTabClick = useCallback((tab: ITab<T>) => () => {
    if (!!analyticsEventNamePrefix) anaylytics.fireEvent(`${analyticsEventNamePrefix}_${tab.context}`);
    onTabClick(tab);
  }, [onTabClick]);

  const renderTabs = () => tabs.map(tab => (
    <Tab
      key={ tab.context }
      className={ `${tab.className || ''} ${tab.selected ? 'selected' : ''}` }
      kind={ ButtonKind.Blank }
      onClick={ _onTabClick(tab) }
    >
      { tab.text }
    </Tab>
  ));

  return (
    <TabsContainer className={ className }>
      { renderTabs() }
    </TabsContainer>
  );
};
