import styled from 'styled-components';
import { FlexCol, FlexHorizontalCenter } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';

export const CampaignDataContainer = styled.div``;

export const CampaignModalContainer = styled(CustomModal)`
  ${ FlexCol }
  width: 75vw;
  height: 70vh;
  overflow: hidden;

  .modal-header {
    margin-bottom: 20px;
  }

  .text-field {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const Row = styled.div`
  ${ FlexHorizontalCenter }
  align-items: flex-start;
  
  &:not(:last-child) {
    padding-bottom: 20px;
  }
`;

export const CampaignInfoContainer = styled.div`
  flex-grow: 1;
`;

export const CampaignContainer = styled.div`
  .campaign-dropdown {
    max-width: 300px;
  }
`;
