import styled from 'styled-components';
import { FlexHorizontalCenter } from '../../../../styles/styles';

export const CompanyItemContainer = styled.button`
  ${ FlexHorizontalCenter };
  justify-content: space-between;
  min-width: calc(100% - 4px);
  margin-right: 2px;
  margin-left: 2px;
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray1};
  border-radius: 20px;
  background: none;
  outline: none;
  text-align: left;
  transition: border .25s ease-in-out;

  &:first-child {
    margin-top: 2px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.darkGray1};
  }

  &:focus-visible {
    outline: 1px dashed ${({ theme }) => theme.colors.darkGray1};
    outline-offset: 1px;
  }

  .company-logo {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }

  .default-company-logo {
    max-height: 60%;
  }

  .base-info-container {
    ${ FlexHorizontalCenter }

    h4 {
      margin-bottom: 2px;
    }
  }

  .url {
    margin-top: 5px;

    a {
      color: ${({ theme }) => theme.colors.green};
      text-decoration: none;
      outline: none;

      &:focus-visible {
        outline: 1px dashed ${({ theme }) => theme.colors.darkGray1};
      }

      &:hover {
        color: ${({ theme }) => theme.colors.darkGreen1};
      }
    }
  }

  .company-rating {
    width: 50px;
    height: 50px;
  }
`;

export const CompanyItemSkeletonContainer = styled(CompanyItemContainer)`
  .company-name {
    width: 300px;
  }

  .company-info {
    width: 125px;
  }
`;
