import React, { useEffect, useRef, useState } from 'react';
import equal from 'fast-deep-equal/es6';
import { UserRoles } from '../../models/users';
import { Checkbox, CheckboxType } from '../Checkbox';

import { OptionsContainer, UsersFilterContainer } from './styles';

export interface IUserFilter {
  role: UserRoles[];
}

export type UserFilterOptions = 'role';

interface IProps {
  className?: string;
  /**
   * some use cases do not allow the None role to be
   * used...had to add specific prop to disable this single
   * role option.
   */
  disableNone?: boolean;
  defaultFilter?: IUserFilter;
  options?: UserFilterOptions[];
  onChange(filter: IUserFilter): void;
}

export const defaultUsersFilter: IUserFilter = {
  role: [UserRoles.None, UserRoles.Member, UserRoles.Admin, UserRoles.SuperAdmin],
};

export const defaultUserFilterOptions: UserFilterOptions[] = [
  'role',
];

export const UsersFilter: React.FC<IProps> = ({
  className = '',
  disableNone,
  defaultFilter = defaultUsersFilter,
  options = defaultUserFilterOptions,
  onChange,
}) => {
  const _defaultUsersFilter = useRef({ ...defaultUsersFilter, ...defaultFilter });
  const [optionsFilter, setOptionsFilter] = useState<IUserFilter>(_defaultUsersFilter.current);

  useEffect(() => {
    if (!equal(optionsFilter, _defaultUsersFilter.current)) {
      _defaultUsersFilter.current = optionsFilter;
      onChange(optionsFilter); 
    }
  }, [optionsFilter]);

  const onRoleChange = (userRole: UserRoles) => () => {
    const _filter = { ...optionsFilter };
    const selected = !_filter.role.find(role => role === userRole);

    if (selected) {
      _filter.role = [..._filter.role, userRole];
    } else {
      _filter.role = _filter.role.filter(role => role !== userRole);
    }

    setOptionsFilter(_filter);
  };

  const renderRolesFilter = () => {
    const rolesOptions = Object.values(UserRoles).map(userRole => (
      <Checkbox
        key={ userRole }
        id={ userRole }
        className='role-checkbox'
        checked={ !!optionsFilter.role.find(role => role === userRole) }
        disabled={ !!disableNone && userRole === UserRoles.None }
        label={ userRole }
        type={ CheckboxType.Square }
        onChange={ onRoleChange(userRole) }
      />
    ));

    return (
      <OptionsContainer key='user-roles-filter'>
        { rolesOptions }
      </OptionsContainer>
    );
  };

  const renderOptions = () => options.map(o => {
    switch (o) {
      case 'role': return renderRolesFilter();
      default: return null;
    }
  });

  return (
    <UsersFilterContainer className={ className }>
      { renderOptions() }
    </UsersFilterContainer>
  );
};
