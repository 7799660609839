import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { FlexCenter } from '../../styles/styles';
import { IThemeProps } from '../../styles/themes';

export enum AvatarSize {
  Tiny = 'tiny',
  Small = 'small',
  Regular = 'regular',
  Large = 'large',
  XLarge = 'xlarge',
}

interface IProps extends IThemeProps {
  size: AvatarSize;
}

const tinySize = 30;
const smallSize = 40;
const regularSize = 60;
const largeSize = 100;
const xLargeSize = 175;

export const AvatarContainer = styled.div<IProps>`
  ${ FlexCenter }
  border-radius: 50%;
  overflow: hidden;

  &:not(.default) {
    border: 2px solid ${({ theme }) => theme.colors.darkGray1};
  }

  &.clickable:hover {
    cursor: pointer;
  }

  ${({ size, theme }) => {
    switch (size) {
      case AvatarSize.Tiny: return `
        width: ${tinySize}px;
        height: ${tinySize}px;
      `;
      case AvatarSize.Small: return `
        width: ${smallSize}px;
        height: ${smallSize}px;
      `;
      case AvatarSize.Large: return `
        width: ${largeSize}px;
        height: ${largeSize}px;

        &:not(.default) {
          border: 3px solid ${theme.colors.darkGray1};
        }
      `;
      case AvatarSize.XLarge: return `
        width: ${xLargeSize}px;
        height: ${xLargeSize}px;

        &:not(.default) {
          border: 5px solid ${theme.colors.darkGray1};
        }
      `;
      default: return `
        width: ${regularSize}px;
        height: ${regularSize}px;
      `;
    }
  }}

  .default-icon {
    fill: ${({ theme }) => theme.colors.darkGray1};
  }

  & > * {
    display: block;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
`;

export const AvatarSkeletonContainer = styled(Skeleton)<IProps>`
  ${({ size }) => {
    switch (size) {
      case AvatarSize.Tiny: return `
        width: ${tinySize}px;
        height: ${tinySize}px;
      `;
      case AvatarSize.Small: return `
        width: ${smallSize}px;
        height: ${smallSize}px;
      `;
      case AvatarSize.Large: return `
        width: ${largeSize}px;
        height: ${largeSize}px;
      `;
      case AvatarSize.XLarge: return `
        width: ${xLargeSize}px;
        height: ${xLargeSize}px;
      `;
      default: return `
        width: ${regularSize}px;
        height: ${regularSize}px;
      `;
    }
  }}
`;
