import styled from 'styled-components';
import { FlexColCenter, FlexRow } from '../../../../styles/styles';

export const LegalItemContainer = styled.div`
  ${ FlexColCenter }
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 8px 25px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid ${({ theme }) => theme.colors.grey3}};
  border-radius: 8px;
  padding: 10px 15px;
  margin-top: 20px;
  max-width: 950px;

  .legal-text-name {
    margin-bottom: 10px;
  }

  .bottom-section {
    ${ FlexRow }
    justify-content: space-between;

    .date-container {
      ${ FlexRow }
      gap: 20px;

      p {
        color: ${({ theme }) => theme.colors.grey4};
        font-size: 14px;
        font-style: italic;
        margin: unset;
      }
    }

  }
`;
