import styled from 'styled-components';
import { BasicContainer, FlexCenter } from '../../../styles/styles';

export const LoginContainer = styled.div`
  ${ FlexCenter }
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => `linear-gradient(to bottom, ${theme.colors.lightGreen1}, ${theme.colors.green})`};
  

  .logo-container {
    ${ FlexCenter }
    margin-bottom: 20px;
  }

  .not-permitted {
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.red};
    text-align: center;
  }

  .form-container {
    ${ BasicContainer }
    min-width: 400px;
    max-width: 400px;
    padding: 30px 50px;
    background: #fff;

    .text-field {
      margin-bottom: 10px;
    }
  }
`;
