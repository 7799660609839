import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import 'rc-slider/assets/index.css';
import { Button } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';
import { LoadingSpinner } from '../../../../components/loading/LoadingSpinner';
import { SpinnerSize } from '../../../../components/loading/LoadingSpinner/styles';
import { TextField, TextFieldKind } from '../../../../components/TextField';
import { useErrorMessages } from '../../../../contexts/error-messages-store';
import { SectorModel, SectorsModel } from '../../../../models/sectors';
import { DataSectorsContainer, FilterHeader, FilterItemHeader, NoFilter, NoSectors, SearchContainer, SectorHeader, SectorItem, SectorsCount, SectorsFilter, SectorsInfoContainer, SectorsList, SectorsListContainer } from './styles';
import { Slider } from '../../../../components/Slider';
import { Checkbox } from '../../../../components/Checkbox';
import { nanoid } from 'nanoid';
import { SectorModal } from '../../../../components/modals/SectorModal';
import { SectorItemSkeleton } from './skeleton';

interface ISectorQueryItem { [key: string]: any }
interface ISectorQuery {
  $and: ISectorQueryItem[];
}

interface ITierFilterOption {
  id: string;
  checked: boolean;
  tier: number;
  count: number;
}

interface IProps {
  className?: string;
}

export const DataSectorsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const errorMessages = useErrorMessages();
  const sectorsModel = useRef(new SectorsModel()).current;
  const cmRangeFilterEngaged = useRef(false);
  const tiersFilterEngaged = useRef(false);
  const mounted = useRef(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchThrottle, setSearchThrottle] = useState<number>(null);
  const [filterFailedToLoad, setFilterFailedToLoad] = useState(false);
  const [cmRangeValues, setCMRangeValues] = useState<[number, number]>(null);
  const [cmRangeThrottle, setCMRangeThrottle] = useState<number>(null);
  const [tierFilterOptions, setTierFilterOptions] = useState<ITierFilterOption[]>([]);
  const [showSectorModal, setShowSectorModal] = useState(false);
  const [selectedSector, setSelectedSector] = useState<SectorModel>(null);

  const loadMore = (refresh?: boolean) => async () => {
    const query: ISectorQuery = {
      $and: [
        { carbonMultiplier: { $gte: cmRangeValues[0] } },
        { carbonMultiplier: { $lte: cmRangeValues[1] } },
        { tier: { $in: tierFilterOptions.filter(tier => tier.checked).map(tier => tier.tier) } },
      ],
    };

    if (!!searchQuery) {
      query.$and.push({ name: searchQuery });
    }

    try {
      await sectorsModel.sectors[refresh ? 'refresh' : 'loadMore'](`filter=${JSON.stringify(query)}`, true);
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Loading Sectors',
        message: err.message,
      });
    }
  };

  const loadFilter = async () => {
    try {
      await sectorsModel.loadFilterOptions();

      setCMRangeValues([
        sectorsModel.filterOptions.carbonMultiplierRange.min,
        sectorsModel.filterOptions.carbonMultiplierRange.max,
      ]);

      setTierFilterOptions(sectorsModel.filterOptions.tiers.map(({ tier, count }) => ({
        id: nanoid(12),
        tier,
        count,
        checked: true,
      })));
    } catch (err: any) {
      setFilterFailedToLoad(true);
      errorMessages.push({
        title: 'Error Loading Sectors Filter Options',
        message: err.message,
      });
    } 
  };

  useEffect(() => {
    loadFilter();
  }, []);

  useEffect(() => {
    setShowSectorModal(!!selectedSector);
  }, [selectedSector]);

  useEffect(() => {
    window.clearTimeout(searchThrottle);

    if (sectorsModel.sectors.firstPageLoaded) {
      setSearchThrottle(window.setTimeout(() => {
        // sectorsModel.sectors.refresh({ name: !!searchQuery ? `/${searchQuery}/gi` : null });
        loadMore(true)();
      }, 400));
    }
  }, [searchQuery]);

  useEffect(() => {
    if (cmRangeFilterEngaged.current) {
      window.clearTimeout(cmRangeThrottle);
      setCMRangeThrottle(window.setTimeout(loadMore(true), 500));
    }
  }, [cmRangeValues]);

  useEffect(() => {
    if (tiersFilterEngaged.current) {
      loadMore(true)();
    }
  }, [tierFilterOptions]);

  useEffect(() => {
    if (!mounted.current && !sectorsModel.sectors.firstPageLoaded && !!cmRangeValues && !!tierFilterOptions.length) {
      mounted.current = true;
      loadMore(true)();
    } 
  }, [cmRangeValues, tierFilterOptions]);

  const onCMRangeChange = useCallback((value: number | number[]) => {
    cmRangeFilterEngaged.current = true;
    const [v1, v2] = value as number[];
    setCMRangeValues([v1, v2]);
  }, []);

  const onCreateSectorClick = useCallback(() => {
    setSelectedSector(null);
    setShowSectorModal(true);
  }, []);

  const onSectorClick = useCallback((sector: any) => () => {
    setSelectedSector(sector);
  }, []);

  const onSectorModalClose = useCallback(() => {
    setShowSectorModal(false);
    setSelectedSector(null);
  }, []);

  const onSectorModalSave = useCallback(async (_: SectorModel, isNew: boolean) => {
    setShowSectorModal(false);
    setSelectedSector(null);

    if (isNew) {
      await loadFilter();
      loadMore(true)();
    }
  }, [loadFilter, loadMore]);

  const onSectorsSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, []);

  const onTierChange = useCallback((tierOption: ITierFilterOption) => () => {
    tiersFilterEngaged.current = true;
    setTierFilterOptions(tierFilterOptions.map(tier => {
      if (tier.id === tierOption.id) tier.checked = !tier.checked;

      return tier;
    }));
  }, [tierFilterOptions]);

  const renderFilter = () => {
    if (sectorsModel.loadingFilterOptions) return <LoadingSpinner />;
    if (filterFailedToLoad) return <NoFilter>Filter Unavailable</NoFilter>;
    if (!sectorsModel.filterOptions) return null;

    const tiers = tierFilterOptions.map(tier => (
      <Checkbox
        key={ tier.id }
        checked={ tier.checked }
        label={ `tier ${tier.tier} (${tier.count})` }
        onChange={ onTierChange(tier) }
      />
    ));

    return (
      <SectorsFilter className={ filterFailedToLoad ? 'failed-to-load' : '' }>
        <FilterHeader>Filter Sectors By:</FilterHeader>
        <FilterItemHeader>Carbon Multiplier</FilterItemHeader>
        <Slider
          range
          withToolTip
          className='sectors-carbon-multiplier-slider'
          step={ 0.1 }
          min={ sectorsModel.filterOptions.carbonMultiplierRange.min }
          max={ sectorsModel.filterOptions.carbonMultiplierRange.max }
          value={ cmRangeValues }
          onChange={ onCMRangeChange }
        />
        <FilterItemHeader>Tier</FilterItemHeader>
        { tiers }
      </SectorsFilter>
    );
  };

  const renderSectors = () => {
    let sectors: JSX.Element[] = [];

    if (sectorsModel.sectors.results.length > 0) {
      sectors = sectorsModel.sectors.results.map(sector => (
        <SectorItem
          key={ sector._id }
          className='sector-item'
          kind={ ButtonKind.Blank }
          onClick={ onSectorClick(sector) }
        >
          <div className='sector-tier'>{ sector.tier }</div>
          <div className='sector-name'>{ sector.name }</div>
          <div className='sector-carbon-multiplier'>{ sector.carbonMultiplier }</div>
        </SectorItem>
      ));
    } else {
      sectors.push(<NoSectors key='no-sectors'>No sectors found with name "{ searchQuery }"</NoSectors>);
    }

    if (sectorsModel.sectors.busy) {
      if (sectorsModel.sectors.firstPageLoaded) {
        sectors.push((
          <div key='loading-sectors-spinner'>
            <LoadingSpinner />
          </div>
        ));
      } else {
        const skeletons: JSX.Element[] = [];

        for (let i = 0; i < 25; i++) {
          skeletons.push(<SectorItemSkeleton key={ `company-item-skele-${i}` } />);
        }

        sectors = [...sectors, ...skeletons];
      }
    }

    if (!sectorsModel.sectors.allResultsFetched && !sectorsModel.sectors.busy) {
      sectors.push(<Waypoint key='waypoint' onEnter={ loadMore() } topOffset={ 200 } />);
    }

    return sectors;
  };

  const renderSectorsCount = () => {
    const content = sectorsModel.sectors.busy || sectorsModel.loadingFilterOptions
      ? <LoadingSpinner size={ SpinnerSize.Small } />
      : `${ sectorsModel.sectors.total } Sectors`;

    return <SectorsCount>{ content }</SectorsCount>;
  };

  return (
    <DataSectorsContainer className={ className }>
      { renderFilter() }
      <SectorsListContainer>
        <SearchContainer>
          <TextField
            labelHidden
            fieldKind={ TextFieldKind.Pill }
            id='sectors-search-input'
            label='search sectors'
            onChange={ onSectorsSearchChange }
            placeholder='Search Sectors'
            value={ searchQuery }
          />
        </SearchContainer>
        <SectorsInfoContainer>
          { renderSectorsCount() }
          <div>
            <Button
              kind={ ButtonKind.PrimaryGhost }
              onClick={ onCreateSectorClick }
            >
              + Create Sector
            </Button>
          </div>
        </SectorsInfoContainer>
        <SectorHeader>
          <div className='sector-tier'>Tier</div>
          <div className='sector-name'>Name</div>
          <div className='sector-carbon-multiplier'>Carbon Multiplier</div>
        </SectorHeader>
        <SectorsList>
          { renderSectors() }
        </SectorsList>
      </SectorsListContainer>
      <SectorModal
        isOpen={ showSectorModal }
        sector={ selectedSector }
        onClose={ onSectorModalClose }
        onSave={ onSectorModalSave }
      />
    </DataSectorsContainer>
  );
};

export const DataSectors = observer(DataSectorsBase);
