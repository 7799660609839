import styled from 'styled-components';
import { MainAdminContainer } from '../../../components/MainAdminContainer';
import { FlexCol } from '../../../styles/styles';

export const LegalTextContainer = styled(MainAdminContainer)`
  background: ${({ theme }) => theme.colors.grey1};
  height: 100%;
  min-height: 100vh;

  .main-content {
    ${ FlexCol }
    justify-content: flex-start;
    min-height: 100vh;
  }
`;

export const ButtonRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`; 
