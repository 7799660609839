import styled from 'styled-components';
import { NoScrollBar } from '../../styles/styles';

export const TextAreaContainer = styled.div< {sizeOfArea?: number} >`
  height: ${props => !!props.sizeOfArea ? `${String(props.sizeOfArea)}`+'px' : '200px'};
  min-height: 200px;
  position: relative;

  .textarea-label {
    display: block;
    margin-bottom: 10px;
  }

  textarea {
    ${ NoScrollBar }
    white-space: pre-line;
    font-family: unset;
    font-size: 16px;
    line-height: 1.5;
    padding: unset;
    margin: 0;
    display: block;
    min-width: 100%;
    width: 100%;
    height: calc(100% - 30px);
    padding: 15px;
    border-radius: 20px;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: transparent;
    background: transparent;
    caret-color: ${({ theme }) => theme.colors.darkGray1};
    resize: none;
    overflow: hidden;
  }

  pre {
    ${ NoScrollBar }
    overflow: auto;
    font-family: unset;
    font-size: 16px;
    line-height: 1.5;
    width: 100%;
    display: block;
    min-width: 100%;
    height: calc(100% - 30px);
    padding: 15px;
    border: ${({ theme }) => `1px solid ${theme.colors.darkGray1}`};
    border-radius: 20px;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    padding: uset;
    margin: 0;

    code {
      font-family: unset;
      font-size: 16px;
      line-height: 1.5;
      padding: unset;
      margin: 0;
      text-shadow: none;
      outline: none;
      white-space: pre-line;
    }
  }
  `;
