import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { useToaster } from '../../../contexts/toaster-store';
import { CompanyModel, ICompany } from '../../../models/companies';
import { FileUploaderModel, ResourceTypes } from '../../../models/fileUploader';
import { ImageUpload } from '../../../pages/admin/Companies/ImageUpload';
import { ButtonKind } from '../../Button/styles';
import { TextField, TextFieldSize } from '../../TextField';
import { BasicCompanyInfoContainer, CompanyDataContainer, CompanyModalContainer, LoadingSpinnerContainer, LogoContainer, Row } from './styles';

interface IProps {
  className?: string;
  isOpen: boolean;
  onClose(): void;
  company: CompanyModel;
}

const CompanyModalBase: React.FC<IProps> = ({
  className = '',
  isOpen,
  onClose,
  company,
}) => {
  const toaster = useToaster();
  const fileUploader = useRef(new FileUploaderModel('karma')).current;
  const errorMessages = useErrorMessages();
  const [companyName, setCompanyName] = useState(company?.companyName || '');
  const [url, setUrl] = useState(company?.url || '');
  const [newLogo, setNewLogo] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [fileToUpload, setFileToUpload] = useState<File>(null);
  const [previewImg, setPreviewImg] = useState('');

  const resetModal = () => {
    setCompanyName('');
    setUrl('');
    setNewLogo(false);
    setLogoUrl('');
    setFileToUpload(null);
    setPreviewImg('');
  };

  const changesFound = () => {
    let changes = false;

    if (!!company) {
      if (companyName !== company.companyName || url !== company.url || !!newLogo || !!logoUrl) changes = true;
    } else {
      if (!companyName && !url) changes = true;
    }

    return changes;
  };

  const onCompanyModalClose = () => {
    resetModal();
    onClose();
  };

  const onCompanyNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
  }, []);

  const updateCompany = (data: Partial<ICompany>) => {
    company.update(data)
      .then(() => {
        toaster.push({ message: 'Company updated successfully.' });
        onCompanyModalClose();
      })
      .catch(err => {
        errorMessages.push({
          title: 'Error Updating Company',
          message: err.message,
        });
      });
  };

  const onSaveClick = () => {
    if (changesFound()) {
      if (!!company) {

        if (!!fileToUpload) {
          const config = {
            resourceType: ResourceTypes.CompanyLogo,
            resourceId: company._id,
          };

          fileUploader.uploadImage(fileToUpload, config)
            .then((res) => {
              const data = {
                companyName,
                url,
                logo: res.url,
              };

              updateCompany(data);
            })
            .catch((err: any) => {
              errorMessages.push({
                title: 'Error Updatiung Company Logo',
                message: err.message,
              });
            });
        }

        if (!!logoUrl) {            
          fileUploader.uploadImageFromUrl(logoUrl, company._id, company.companyName)
            .then((res) => {
              const data = {
                companyName,
                url,
                logo: res.url,
              };

              updateCompany(data);
            })
            .catch((err: any) => {
              errorMessages.push({
                title: 'Error Updatiung Company Logo',
                message: err.message,
              });
            });
        }

        if (!fileToUpload && !logoUrl) {
          const data = {
            companyName,
            url,
          };

          updateCompany(data);
        }
      } else {
        
        // TODO: create a new company
      }
    }
  };

  const ctas = useMemo(() => ([
    {
      id: 'cancel-company-mod',
      text: 'Cancel',
      kind: ButtonKind.SecondaryWithIcon,
      onClick: onCompanyModalClose,
    },
    {
      disabled: !changesFound(),
      id: 'save-company-mod',
      text: 'Save',
      kind: ButtonKind.Primary,
      onClick: onSaveClick,
    },
  ]), [onCompanyModalClose, onSaveClick, changesFound]);

  const onUrlChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  }, []);

  const onLogoUrlChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLogoUrl(e.target.value);
    setPreviewImg(e.target.value);
  }, []);

  return (
    <CompanyModalContainer
      className={ className }
      closeOnOverlayClick
      title={ `${!!company ? 'Edit' : 'New'} Company` }
      ctas={ ctas }
      isOpen={ isOpen }
      onClose={ onCompanyModalClose }
    >
      <CompanyDataContainer>
        <Row>
          <LogoContainer>
            <ImageUpload 
              className='logo'
              company={ company }
              allowEditing={ true }
              setNewLogo={ setNewLogo }
              setFileToUpload={ setFileToUpload }
              previewImg={ previewImg }
              setPreviewImg={ setPreviewImg }
              setLogoUrl={ setLogoUrl }
            />
          </LogoContainer>
          <BasicCompanyInfoContainer>
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Large }
              id='company-name-field'
              label='Company Name'
              onChange={ onCompanyNameChange }
              placeholder='Enter a company name'
              value={ companyName }
            />
            <TextField
              className='text-field'
              id='company-url-field'
              label='Company Url'
              onChange={ onUrlChange }
              placeholder='Enter a company url'
              value={ url }
            />
            <TextField
              className='text-field'
              id='company-logo-url-field'
              label='Company Logo Url'
              onChange={ onLogoUrlChange }
              placeholder='Enter a company logo url'
              value={ logoUrl }
            />
          </BasicCompanyInfoContainer>
        </Row>
        <Row>
            more data...
        </Row>
      </CompanyDataContainer>
      {
        company?.busy && (
          <LoadingSpinnerContainer>
            updating...
          </LoadingSpinnerContainer>
        )
      }
    </CompanyModalContainer>
  );
};

export const CompanyModal = observer(CompanyModalBase);
