import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BannersContainer, ButtonRow } from './styles';
import { H1 } from '../../../styles/components/header';
import { BannerModel, BannersModel } from '../../../models/banners';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { BannerItem } from './BannerItem';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { observer } from 'mobx-react';
import { CreateUpdateBannerModal } from '../../../components/modals/CreateUpdateBannerModal';
import { ButtonKind } from '../../../components/Button/styles';
import { Button } from '../../../components/Button';

interface IProps {
  className?: string;
}

const BannersBase: React.FC<IProps> = ({
  className = '',
}) => {
  const bannersModel = useRef(new BannersModel({ limit: 300 }, true)).current;
  const errorMessages = useErrorMessages();
  const [bannerModalOpen, setBannerModalOpen] = useState(false);

  const loadBanners = useCallback((refresh?: boolean) => async () => {
    try {
      await bannersModel.banners[refresh ? 'refresh' : 'loadMore']();
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Loading Banners',
        message: err.message,
      });
    }
  }, []);

  useEffect(() => {
    loadBanners(true)();
  }, []);

  const onModalClose = () => {
    setBannerModalOpen(false);
    loadBanners(true)();
  };

  const onCreateClick = () => setBannerModalOpen(true);

  const bannersList = () => {
    if (bannersModel.busy) return <LoadingSpinner />;

    if (!bannersModel.busy && bannersModel.banners.total === 0) return <p>No banners found.</p>;

    return bannersModel.banners.results.map((banner: BannerModel) => (
      <BannerItem
        key={ banner._id }
        banner={ banner }
        onClose={ onModalClose }
        bannersModel={ bannersModel }
      />
    ));
  };

  return (
    <BannersContainer title='Admin Banners' className={ className }>
      <H1>Banners</H1>
      <ButtonRow>
        <Button
          kind={ ButtonKind.Primary }
          onClick={ onCreateClick }
          className='create-banner-button'
        >
          Create Banner
        </Button>
      </ButtonRow>
      { bannersList() }
      <CreateUpdateBannerModal
        isOpen={ bannerModalOpen }
        onClose={ onModalClose }
        bannersModel={ bannersModel }
      />
    </BannersContainer>
  );
};

export const Banners = observer(BannersBase);
