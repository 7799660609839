import React, { useEffect, useState } from 'react';
import { IntroSection } from '../../../../pages/IndustryReports/IntroSection';
import { ArticlePreviewContainer } from './styles';
import { IArticleType } from '../../../../models/article';
import { CompanyModel } from '../../../../models/companies';
import { HeaderSection } from '../../../../pages/IndustryReports/HeaderSection';
import { BodySection } from '../../../../pages/IndustryReports/BodySection';
import { LoadingSpinner } from '../../../loading/LoadingSpinner';

interface IProps {
  className?: string;
  article?: any;
}

export const ArticlePreview: React.FC<IProps> = ({ className = '', article }) => {
  const [company, setCompany] = useState<CompanyModel>(null);
  const [loading, setLoading] = useState(false);
  const [loadingCompanyError, setLoadingCompanyError] = useState(false);
  
  useEffect(() => {
    if (!article.companyId) return;
    if (article.type !== IArticleType.CompanySpotlight || article.type !== IArticleType.GoodAndBad) {
      setLoading(true);
      CompanyModel.loadCompany(article.companyId)
        .then(setCompany).then(() => setLoading(false))
        .catch(() => {
          setLoading(false);
          setLoadingCompanyError(true);
        });
    }
  }, [article.companyId]);

  const renderPreview = () => {
    if (!!loadingCompanyError) return <p>Error Loading Preview. Please try again.</p>;
    if (loading) return <LoadingSpinner />;
    if (!loading) {
      return (
        <>
          <HeaderSection article={ { ...article, company } } />
          <IntroSection article={ article } />
          <BodySection article={ article } />
        </>
      );
    } 
    return null;
  };

  return (
    <ArticlePreviewContainer className={ className }>
      {
        renderPreview()
      }
    </ArticlePreviewContainer>
  );
};
