import React from 'react';
import { AvatarSize, AvatarSkeletonContainer } from './styles';

interface IProps {
  className?: string;
  size?: AvatarSize;
}

export const AvatarSkeleton: React.FC<IProps> = ({
  className = '',
  size = AvatarSize.Regular,
}) => (
  <AvatarSkeletonContainer
    circle
    className={ className }
    size={ size }
  />
);
