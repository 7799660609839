import styled from 'styled-components';
import { MainAdminContainer } from '../../../components/MainAdminContainer';
import { FlexCol, FlexHorizontalCenter } from '../../../styles/styles';

export const CompaniesContainer = styled(MainAdminContainer)``;

export const CompaniesInfoContainer = styled.div`
  padding: 0 20px 10px;
`;

export const CompaniesList = styled.div``;

export const CompaniesListContainer = styled.div`
  ${ FlexCol }
  min-height: 100%;
  max-height: 100%;
  margin-left: 20px;
  padding-bottom: 10px;
  width: 100%;

  .company-logo {
    img {
      max-width: 60%;
      max-height: 60%;
    }
  }

  .no-logo-only {
    margin-bottom: 20px;
  }
`;

export const MainContainer = styled.div`
  ${ FlexHorizontalCenter }
  align-items: flex-start;
  padding-top: 20px;
`;

export const SearchContainer = styled.div`
  padding-bottom: 20px;
`;
