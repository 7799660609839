import { action, computed, observable, makeObservable, runInAction } from 'mobx';
import { BaseModel } from './base';
import { CollectionModel, ICollectionConfig } from './collection';
import { WebServiceHelper } from '../lib/webServiceHelper';

type PrivateFields = '_busy' |
'_falsePositives' |
'_isAdmin' |
'_init';

type PrivateFalsePositiveFields = '_busy' | '_falsePositive';

export enum MatchType {
  Name = 'name',
  MerchantName = 'merchant_name',
}

export interface IFalsePositiveUpdate {
  _id?: string;
  originalValue?: string;
  matchType?: MatchType;
}

export interface IFalsePositive extends IFalsePositiveUpdate {
  createdOn: Date;
  lastModified: Date;
}

export class FalsePositiveModel extends BaseModel {
  private _busy = false;
  private _falsePositive: IFalsePositive = null;

  constructor(falsePositiveInfo: IFalsePositive) {
    super();
    makeObservable<FalsePositiveModel, PrivateFalsePositiveFields>(this, {
      _busy: observable,
      _falsePositive: observable,
      _id: computed,
      busy: computed,
    });

    this._falsePositive = falsePositiveInfo;
  }

  get busy() { return this._busy; }
  get _id() { return this._falsePositive._id; }
  get falsePositive() { return this._falsePositive; }

  public updateFalsePositive = async (data: IFalsePositiveUpdate) => {
    if (this._busy) return;

    runInAction(() => {
      this._busy = true;
    });

    const result = await this.webServiceHelper.sendRequest<IFalsePositive>(
      {
        path: `/admin/transaction/false-positives/${this._id}`,
        method: 'PUT',
        data,
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }

    return result.value;
  };

  static createFalsePositive = async (data: IFalsePositiveUpdate) => {
    const webServiceHelper = new WebServiceHelper();
    const result = await webServiceHelper.sendRequest<IFalsePositive>(
      {
        path: '/admin/transaction/false-positives',
        method: 'POST',
        data,
      },
      true,
    );

    if (result.success) {
      return new FalsePositiveModel(result.value);
    } else {
      throw new Error(result.error);
    }
  };

  public deleteFalsePositive = async () => {
    if (this._busy) return;

    runInAction(() => {
      this._busy = true;
    });

    const result = await this.webServiceHelper.sendRequest<IFalsePositive>(
      {
        path: `/admin/transaction/false-positives/${this._id}`,
        method: 'DELETE',
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }

    return result.value;
  };
}

export class FalsePositivesModel extends BaseModel {
  private _busy = false;
  private _falsePositives: CollectionModel<IFalsePositive, FalsePositiveModel> = null;
  private _isAdmin = false;
  private _config: ICollectionConfig = {};

  constructor(config: ICollectionConfig = {}, isAdmin = false) {
    super({ basePath: '/admin/transaction/false-positives' });
    makeObservable<FalsePositivesModel, PrivateFields>(this, {
      _busy: observable,
      _falsePositives: observable,
      _isAdmin: observable,
      falsePositives: computed,
      _init: action.bound,
    });

    this._config = config || {};
    this._isAdmin = isAdmin;
    this._init();
  }

  get busy() { return this._busy; }
  get falsePositives() { return this._falsePositives; }

  private _init = () => {
    const url = '/admin/transaction/false-positives';
    this._falsePositives = new CollectionModel<IFalsePositive, FalsePositiveModel>(
      url,
      (falsePositiveInfo: IFalsePositive) => new FalsePositiveModel(falsePositiveInfo),
      this._config,
    );
  };
}
