import styled from 'styled-components';

export const ArticlePreviewContainer = styled.div`
  margin-top: 40px;
  width: 100%;

  img {
    max-width: 100%;
  }

  .article-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .article-image-right-container {
    flex-direction: column;
  }
`;
