import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  stroke?: string;
  id?: string;
}

export const TrashcanIcon: FC<IProps> = ({ className = '', stroke = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }trashcan-icon` }
    id={ id }
    width={ 20 }
    height={ 20 }
    fill='none'
    stroke={ stroke }
  >
    <path d='M7.41667 4.58333V4.58333C7.41667 3.01853 8.68519 1.75 10.25 1.75V1.75C11.8148 1.75 13.0833 3.01853 13.0833 4.58333V4.58333M7.41667 4.58333H13.0833M7.41667 4.58333H3.63889M13.0833 4.58333H16.8611M18.75 4.58333H16.8611M1.75 4.58333H3.63889M3.63889 4.58333V16.75C3.63889 17.8546 4.53432 18.75 5.63889 18.75H14.8611C15.9657 18.75 16.8611 17.8546 16.8611 16.75V4.58333M8.36111 8.36111V14.9722M12.1389 14.9722V8.36111' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </SvgIcon>
);
