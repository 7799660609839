import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Button } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';
import { PromoModal } from '../../../../components/modals/CreateUpdatePromoModal';
import { PencilIcon } from '../../../../components/svgs/icons/PencilIcon';
import { PromoModel, PromosModel } from '../../../../models/promos';
import { IThemeProps } from '../../../../styles/themes';
import { Label, PromoItemContainer, SectionContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  promo: PromoModel;
  promosModel: PromosModel;
}

const PromoItemBase: React.FC<IProps> = ({
  className = '',
  promo,
  theme,
  promosModel,
}) => {
  const { name, promoText, disclaimerText, enabled, limit, amount, campaign, headerText, type, startDate, endDate, events, imageUrl, successText } = promo;
  const [updatePromoModalOpen, setUpdatePromoModalOpen] = useState(false);

  const openModal = () => setUpdatePromoModalOpen(true);

  const onModalClose = () => {
    setUpdatePromoModalOpen(false);
  };
  
  if (!promo) return null;

  return (
    <PromoItemContainer className={ className }>
      <SectionContainer>
        <Label>Promo Name: </Label>
        <div>{ name }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Header Text: </Label>
        <div>{ headerText }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Promo Text: </Label>
        <div>{ promoText }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Success Text: </Label>
        <div>{ successText }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Disclaimer Text: </Label>
        <div>{ disclaimerText }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Image Url: </Label>
        <div>{ imageUrl }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Limit: </Label>
        <div>{ limit }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Type: </Label>
        <div>{ type }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Promo Events: </Label>
        <div>{ events.join(', ') }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Amount: </Label>
        <div>{ amount }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Campaign: </Label>
        <div>{ campaign?.name || 'No Campaign associated with this promo' }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Start Date: </Label>
        <div className='start-date'>{ (!!startDate ? startDate: 'No set date') as React.ReactNode }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>End Date: </Label>
        <div className='end-date'>{ (!!endDate ? endDate : 'No set date') as React.ReactNode }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Promo Status: </Label>
        <div className={ enabled ? 'active enabled-status' : '' }>{ enabled ? 'Active' : 'Inactive' }</div>
      </SectionContainer>
      <Button 
        kind={ ButtonKind.Blank }
        onClick={ openModal }
      >
        <PencilIcon stroke={ theme.colors.darkGray1 } />
      </Button>
      <PromoModal
        promo={ promo }
        isOpen={ updatePromoModalOpen }
        onClose={ onModalClose }
        promosModel={ promosModel }
      />
    </PromoItemContainer>
  );
};

const PromoItemObserver = observer(PromoItemBase);
export const PromoItem = withTheme(PromoItemObserver);
