import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useUserSession } from '../../contexts/user';
import { UserGroups, UserRoles } from '../../models/users';
import { AccessControl } from '../../pages/admin/AccessControl';
import { Campaigns } from '../../pages/admin/Campaigns';
import { Commissions } from '../../pages/admin/Commissions';
import { Companies } from '../../pages/admin/Companies';
import { Data } from '../../pages/admin/Data';
import { Groups } from '../../pages/admin/Groups';
import { Home } from '../../pages/admin/Home';
import { JobPostings } from '../../pages/admin/Jobs';
import { Login } from '../../pages/admin/Login';
import { Promos } from '../../pages/admin/Promos';
import { Reports } from '../../pages/admin/Reports';
import { Users } from '../../pages/admin/Users';
import { PrivatePage } from '../../pages/PrivatePage';
import { CommissionPayoutsOverviews } from '../../pages/admin/CommissionPayoutsOverviews';
import { Banners } from '../../pages/admin/Banners';
import { UploadImage } from '../../pages/admin/UploadImage';
import { Articles } from '../../pages/admin/Articles';
import { observer } from 'mobx-react';
import { KarmaCardLegalText } from '../../pages/admin/KarmaCardLegal';
import { WebAnalytics } from '../../pages/admin/WebAnalytics';

export const MainAdminRouterBase: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userSession = useUserSession();

  useEffect(() => {
    userSession.verifySession()
      .then(() => {
        if (userSession.initialized && !userSession.isLoggedIn) {
          if (location.pathname !== '/login') navigate('/login');
        }
      })
      .catch(() => {
        if (location.pathname !== '/login') navigate('/login');
      });
  }, []);

  const renderHome = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.NotNone } }
      element={ <Home /> }
    />
  );

  const renderCompanies = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.NotNone } }
      element={ <Companies /> }
    />
  );

  const renderUsers = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.NotNone } }
      element={ <Users /> }
    />
  );

  const renderReports = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.NotNone } }
      element={ <Reports /> }
    />
  );

  const renderAccessControl = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.Admins } }
      element={ <AccessControl /> }
    />
  );

  const renderData = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.NotNone } }
      element={ <Data /> }
    />
  );

  const renderJobPostings = () => (
    <PrivatePage
      requirements={ { roles: [UserRoles.SuperAdmin] } }
      element={ <JobPostings /> }
    />
  );

  const renderGroups = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.Admins } }
      element={ <Groups /> }
    />
  );

  const renderCommissions = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.Admins } }
      element={ <Commissions /> }
    />
  );

  const renderCommissionPayoutOverviews = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.SuperAdmins } }
      element={ <CommissionPayoutsOverviews /> }
    />
  );

  const renderPromos = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.Admins } }
      element={ <Promos /> }
    />
  );

  const renderCampaigns = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.Admins } }
      element={ <Campaigns /> }
    />
  );

  const renderBanners = () => (
    <PrivatePage 
      requirements={ { roles: UserGroups.Admins } }
      element={ <Banners /> }
    />
  );

  const renderUploadImage = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.Admins } }
      element={ <UploadImage /> }
    />
  );

  const renderArticles = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.SuperAdmins } }
      element={ <Articles /> }
    />
  );

  const renderKarmaCardLegal = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.SuperAdmins } }
      element={ <KarmaCardLegalText /> }
    />
  );

  const renderWebAnalytics = () => (
    <PrivatePage
      requirements={ { roles: UserGroups.Admins } }
      element={ <WebAnalytics /> }
    />
  );

  if (!userSession.isLoggedIn && userSession.authenticating) return null;

  return (
    <Routes>
      <Route path='/' element={ renderHome() } />
      <Route path='/login' element={ <Login /> } />
      <Route path='/companies' element={ renderCompanies() } />
      <Route path='/users' element={ renderUsers() } />
      <Route path='/reports' element={ renderReports() } />
      <Route path='/access-control' element={ renderAccessControl() } />
      <Route path='/data' element={ renderData() } />
      <Route path='/job-postings' element={ renderJobPostings() } />
      <Route path='/groups' element={ renderGroups() } />
      <Route path='/commissions' element={ renderCommissions() } />
      <Route path='/commission-payout-overviews' element={ renderCommissionPayoutOverviews() } />
      <Route path='/promos' element={ renderPromos() } />
      <Route path='/campaigns' element={ renderCampaigns() } />
      <Route path='/banners' element={ renderBanners() } />
      <Route path='/upload-image' element={ renderUploadImage() } />
      <Route path='/articles' element={ renderArticles() } />
      <Route path='/karma-card-legal' element={ renderKarmaCardLegal() } />
      <Route path='/web-analytics' element={ renderWebAnalytics() } />
      <Route element={ <div>Page not found</div> } />
    </Routes>
  );
};

export const MainAdminRouter = observer(MainAdminRouterBase);
