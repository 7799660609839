import styled from 'styled-components';
import { MainAdminContainer } from '../../../components/MainAdminContainer';
import { BasicContainer, FlexCol, FlexRow, NoScrollBar } from '../../../styles/styles';

export const CommissionsContainer = styled(MainAdminContainer)`
  background: ${({ theme }) => theme.colors.grey1};
  min-height: 100vh;
`;

export const CommissionsInnerWrapper = styled.div`
  ${FlexCol}
  justify-content: flex-start;
  min-height: 100vh;
`;

export const CommissionsHeaderContainer = styled.div`
  ${FlexRow}
  align-items: center;
  height: 75px;
  justify-content: space-between;
  margin-bottom: 20px;

  #find-commission {
    min-width: 300px;
  }

  .find-commission-button {
    margin-left: 10px;
  }

  div {
    ${FlexRow}
    align-items: center;

    * {
      margin-bottom: unset;
    }
  }
`;

export const UsersInfoContainer = styled.div`
  padding: 0 20px 10px;
`;

export const CommissionsList = styled.div`
  ${ NoScrollBar }

  .waypoint-support {
    border: 1px solid transparent;
  }
`;

export const CommissionsListContainer = styled.div`
  ${ FlexCol }
  ${ BasicContainer }
  width: 100%;
  position: relative;

  .clear-search-results-button {
    align-self: center;
  }
    

  .no-commissions {
    padding: 20px;
    text-align: center;
  }
`;

export const CommissionRow = styled.div`
  ${FlexRow}
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 10px;

  &:not(:last-child) {
    border-bottom: solid 1px ${({ theme }) => theme.colors.lightGray1};
  }
`;

export const CommissionRowItem = styled.div`
  display: flex;
  align-items: center;
  width: 15%;

  .edit-commission-button {
    margin-left: 10px;
  }

  &.status {
    width: 30%;
  }

  &.company {
    width: 25%;
  }
`;

export const CommissionHeader = styled(CommissionRow)``;

export const CommissionHeaderRowItem = styled(CommissionRowItem)`
  font-weight: bold;
`;
