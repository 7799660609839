import styled from 'styled-components';
import { FlexCol, FlexHorizontalCenter, FlexRow, NoScrollBar } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';

export const PromoDataContainer = styled.div`
  ${ NoScrollBar }
  flex-grow: 1;
  overflow: auto;
`;

export const PromoModalContainer = styled(CustomModal)`
  ${ FlexCol }
  width: 75vw;
  height: 70vh;
  overflow: hidden;

  .text-field {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .custom-modal-body {
    overflow: scroll;
  }

  .modal-header {
    margin-bottom: 20px;
  }
`;

export const Row = styled.div`
  ${ FlexHorizontalCenter }
  align-items: flex-start;
  
  &:not(:last-child) {
    padding-bottom: 20px;
  }
`;

export const PromoInfoContainer = styled.div`
  flex-grow: 1;
`;

export const InputContainer = styled.div`
  padding-bottom: 20px;
    
  .regular-label {
    font-weight: 600;
  }
`;

export const PromoEventsContainer = styled(InputContainer)`

`;

export const PromoTypeContainer = styled(InputContainer)`
  ${ FlexRow }
  align-items: center;
  justify-content: flex-start;

  .promo-type-dropdown {
    max-width: 300px;
    margin-left: 10px;
  }
`;

export const DateContainer = styled.div`
  margin-left: 10px;

  label {
    margin-right: 10px;
    font-size: 15px;
  }
`;

export const DatesSection = styled(InputContainer)`
  .dates-subtext {
    margin: 10px 0;
    font-size: 14px;
    font-style: italic;
  }
`;

export const DatesWrapper = styled.div`
  ${ FlexRow }
`;
