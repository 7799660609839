import React from 'react';
import { CompanyLogo } from '../../../../components/CompanyLogo';
import { CompanyRatingIndicator } from '../../../../components/CompanyRatingIndicator';
import { CompanyModel } from '../../../../models/companies';
import { H4 } from '../../../../styles/components/header';
import { CompanyItemContainer } from './styles';

interface IProps {
  className?: string;
  company: CompanyModel;
  onClick(): void;
}

export const CompanyItem: React.FC<IProps> = ({
  className = '',
  company,
  onClick,
}) => {
  const renderUrl = (company: CompanyModel) => {
    if (!company.url) return null;

    return (
      <p className='url'>
        <a href={ company.url } target='_blank' rel='noreferrer'>website</a>   
      </p>
    );
  };

  return (
    <CompanyItemContainer
      key={ company._id }
      className={ className }
      onClick={ onClick }
    >
      <div className='base-info-container'>
        <CompanyLogo withBorder className='company-logo' company={ company } />
        <div>
          <H4>{ company.companyName }</H4>
          { renderUrl(company) }
        </div>
      </div>
      <div>
        <CompanyRatingIndicator
          className='company-rating'
          rating={ company.rating }
        />
      </div>
    </CompanyItemContainer>
  );
};
