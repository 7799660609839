import styled from 'styled-components';
import { FlexHorizontalCenter, NoScrollBar, PrimaryFont } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';
import { Button } from '../../Button';
import { TextField } from '../../TextField';

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger};
`;

export const FieldContainer = styled.div`
  padding-top: 15px;
`;

export const CompanyFieldContainer = styled.div`
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
`;

export const CompanyNameTextField = styled(TextField)`
  width: 100%;
`;

export const ManualMatchModalContainer = styled(CustomModal)`
  width: 90%;
  max-width: 650px;
`;

export const FieldLabel = styled.span`
  font-weight: 600;
  ${PrimaryFont};
  font-size: 16px;
  margin-bottom: 10px;
`;

export const MatchTypeContainer = styled(FieldContainer)`
  ${FlexHorizontalCenter}
  position: relative;

  .match-type-dropdown {
    .dropdown-options-container {
      ${NoScrollBar}
      max-height: 200px;
      overflow: auto;
    }
  }

  .match-type-dropdown {
    .dropdown-options-container {
      min-width: 100px;
    }
  }
`;

export const CompanySelectContainer = styled.div`
  position: absolute;
  z-index: 10;
  background: white;
  border: 2px solid ${({ theme }) => theme.colors.darkGray1};
  width: 100%;
  top: 75px;
  border-radius: 12px;
  max-height: 200px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const CompanySelectOption = styled(Button)`
  width: 100%;
  text-align: left;
  padding: 10px;
  border-radius: 0;
  border: none;
  justify-content: flex-start;

  &:hover {
    background: ${({ theme }) => theme.colors.lightGray2};
  }
`;

export const SelectedCompany = styled(Button)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const CompanyResultsList = styled.div`
  ${NoScrollBar}
  overflow: auto;
  max-height: 155px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

export const CompanySearchResultsHeader = styled.div`
  position: relative;
  height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
`;

export const CloseButton = styled(Button)`
  height: 12.5px;
  width: 12.5px;
`;
