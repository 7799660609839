import React from 'react';
import { ButtonKind } from '../../Button/styles';
import { ConfirmationModal } from '../ConfirmationModal';

interface IProps {
  className?: string;
  isOpen: boolean;
  onDelete(): void;
  onClose(): void;
}

export const DeleteWebAnalyticConfirmationModal: React.FC<IProps> = ({
  onClose,
  onDelete,
  ...restProps
}) => 
  <ConfirmationModal
    body=' Are you sure you want to delete this web analytic? This action is permanent and cannot be undone.'
    className='modal'
    ctas={ [
      {
        id: 'confirm-delete',
        text: 'Delete',
        kind: ButtonKind.Danger,
        onClick: onDelete,
      },
      {
        id: 'cancel-delete',
        text: 'Cancel',
        kind: ButtonKind.SecondaryWithIcon,
        onClick: onClose,
      },
    ] }
    onClose={ onClose }
    title='Delete this Web Analytic Event?'
    { ...restProps }
  />;
