import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { useToaster } from '../../../contexts/toaster-store';
import { ButtonKind } from '../../Button/styles';
import { TextField, TextFieldSize } from '../../TextField';
import { ArticleDataContainer, ArticleInfoContainer, ArticleLabel, ArticleModalContainer, Row } from './styles';
import { ArticleHeaderTypes, ArticleModel, ArticlesModel, IArticleResponse, IArticleTemplate, IArticleType, IArticleUpdate } from '../../../models/article';
import { TextArea } from '../../TextArea';
import { Checkbox } from '../../Checkbox';
import { ConfirmationModal } from '../ConfirmationModal';
import { ChevronDirection, ChevronIcon } from '../../svgs/icons/ChevronIcon';
import { IThemeProps } from '../../../styles/themes';
import { withTheme } from 'styled-components';
import { HTMLTextArea } from '../../HTMLTextArea';
import { debounce } from '../../../lib/misc';
import { WebServiceHelper } from '../../../lib/webServiceHelper';
import { ArticleCompanySearch } from '../../ArticleCompanySearch';
import { ArticlePreview } from './ArticlePreview';
import { Button } from '../../Button';

interface IValidateHtmlResponse {
  isValid: boolean;
  errors: IValidateHtmlError[];
}

export interface IValidateHtmlError {
  error: string;
  location: string;
}

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps extends IThemeProps {
  className?: string;
  isOpen: boolean;
  onClose(): void;
  article?: ArticleModel;
  articlesModel: ArticlesModel;
}

const HTML_VALIDATE_DEBOUNCE_INTERVAL = 1500;

const CreateUpdateArticleModalBase: React.FC<IProps> = ({
  className = '',
  isOpen,
  onClose,
  article,
  articlesModel,
  theme,
}) => {
  const toaster = useToaster();
  const errorMessages = useErrorMessages();
  const [title, setTitle] = useState(article?.title || '');
  const [articleType, setArticleType] = useState(article?.type || '');
  const [introParagraph, setIntroParagraph] = useState(article?.introParagraph || '');
  const [description, setDescription] = useState(article?.description || '');
  const [companyId, setCompanyId] = useState(article?.company?._id || '');
  const [body, setBody] = useState(article?.body || '');
  const [headerBackground, setHeaderBackground] = useState(article?.headerBackground || '');
  const [headerType, setHeaderType] = useState(article?.headerType || '' as ArticleHeaderTypes);
  const [headerTitle, setHeaderTitle] = useState(article?.headerTitle || '');
  const [headerLogo, setHeaderLogo] = useState(article?.headerLogo || '');
  const [listViewImage, setListViewImage] = useState(article?.listViewImage || '');
  const [featured, setFeatured] = useState(article?.featured || false);
  const [enabled, setEnabled] = useState(article?.enabled || false);
  const [selectedArticleType, setSelectedArticleType] = useState(article?.type || null);
  const [introParagraphOpen, setIntroParagraphOpen] = useState(false);
  const [descriptionParagraphOpen, setDescriptionParagraphOpen] = useState(false);
  const [articleBodyOpen, setArticleBodyOpen] = useState(false);
  const [articleBodySize, setArticleBodySize] = useState(500);
  const [htmlValidationResult, setHtmlValidationResult] = useState<IValidateHtmlResponse>(null);
  const [htmlValidationError, setHtmlValidationError] = useState(false);
  const [showArticlePreview, setShowArticlePreview] = useState(false);

  const getHtmlValidationResult = useCallback(async (html: string) => {
    const webServiceHelper = new WebServiceHelper();
    try {
      const res = await webServiceHelper.sendRequest<IValidateHtmlResponse>({
        data: { html },
        path: '/admin/utilities/validate-html',
        method: 'POST',
      });
      setHtmlValidationResult(res.value);
    } catch (error) {
      setHtmlValidationError(true);
    }
  }, [setHtmlValidationResult]);

  const getHtmlValidationResultDebounced = useCallback(debounce(html => getHtmlValidationResult(html), HTML_VALIDATE_DEBOUNCE_INTERVAL), []);

  useEffect(() => {
    if (!isOpen) return;
    if (!body) {
      setHtmlValidationResult(null);
      return () => null;
    }
    getHtmlValidationResultDebounced(body);
  }, [body, isOpen]);

  const resetModal = () => {
    setTitle('');
    setArticleType('' as IArticleType);
    setBody('');
    setIntroParagraph('');
    setHeaderTitle('');
    setHeaderLogo('');
    setHeaderType('' as ArticleHeaderTypes);
    setCompanyId('');
    setDescription('');
    setEnabled(false);
    setFeatured(false);
    setHeaderBackground('');
    setListViewImage('');
  };

  const changesFound = () => {

    let changes = false;
    // update article
    if (!!article) {
      if (title !== article.title ||
          body !== article.body ||
          listViewImage !== article.listViewImage ||
          headerBackground !== article.headerBackground ||
          headerLogo !== article.headerLogo ||
          headerTitle !== article.headerTitle ||
          enabled !== article.enabled ||
          featured !== article.featured ||
          headerType !== article.headerType ||
          introParagraph !== article.introParagraph ||
          companyId !== article?.company?._id      
      ) changes = true;
    }
    if (!article && articleType === IArticleType.GoodAndBad || articleType === IArticleType.CompanySpotlight) {
      if (!!title &&
          !!headerBackground &&
          !!companyId &&
          !!introParagraph &&
          !!headerType &&
          !!body &&
          !!articleType) changes = true;
    }
    if (!article && articleType === IArticleType.Feature) {
      if (!!title &&
          !!headerBackground &&
          !!headerLogo &&
          !!headerTitle &&
          !!listViewImage &&
          !!introParagraph &&
          !!headerType &&
          !!body &&
          !!articleType) changes = true;
    }

    return changes;
  };

  const onArticleModalClose = () => {
    resetModal();
    onClose();
  };

  const onCancel = () => {
    setArticleBodyOpen(false);
    setDescriptionParagraphOpen(false);
    setIntroParagraphOpen(false);
    setTitle(article?.title || '');
    setArticleType(article?.type);
    setBody(article?.body || '');
    setIntroParagraph(article?.introParagraph || '');
    setHeaderTitle(article?.headerTitle || '');
    setHeaderLogo(article?.headerLogo || '');
    setHeaderType(article?.headerType);
    setCompanyId(article?.company?._id);
    setDescription(article?.description || '');
    setEnabled(article?.enabled);
    setFeatured(article?.featured);
    setHeaderBackground(article?.headerBackground || '');
    setListViewImage(article?.listViewImage || '');
    onClose();
  };

  const onContinueTemplateChange = () => {
    setArticleType(selectedArticleType);
    setBody(articlesModel.templates.find((template: IArticleTemplate) => template.type === selectedArticleType).html);
    setSelectedArticleType(null);
  };

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const onIntroParagraphChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setIntroParagraph(e.target.value);
  };

  const [headerBackgroundError, setHeaderBackgroundError] = useState(false);

  const onHeaderBackgroundChange = (e: ChangeEvent<HTMLInputElement>) => {
    const regex = /^https:\/\/cdn\.karmawallet\.io\//;
    const isMatch = regex.test(e.target.value);
    setHeaderBackground(e.target.value);
    if (!!isMatch || !e.target.value.length) {
      setHeaderBackgroundError(false);
    } else {
      setHeaderBackgroundError(true);
    }
  };

  const onHeaderLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHeaderLogo(e.target.value);
  };

  const onHeaderTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHeaderTitle(e.target.value);
  };

  const onBodyChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setBody(e.target.value);
    setArticleBodySize(() => {
      if (e.target.scrollHeight < 500) {
        return 500;
      } else {
        return e.target.scrollHeight + 20;
      } 
    });
  };

  const onCompanyIdChange = (id: string) => {
    setCompanyId(id);
  };

  const onDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onListViewImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setListViewImage(e.target.value);
  };

  const onEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEnabled(e.target.checked);
  };

  const onFeaturedChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFeatured(e.target.checked);
  };

  const onTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!articleType) {
      setArticleType(e.target.value as IArticleType);
      setBody(articlesModel.templates.find((template: IArticleTemplate) => template.type === e.target.value).html);
    } else {
      setSelectedArticleType(e.target.value as IArticleType);
    }
  };

  const onHeaderTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setHeaderType(e.target.value as ArticleHeaderTypes);
  };

  const updateArticle = async () => {
    try {
      const articleData: IArticleUpdate = {
        title: !title ? article.title : title,
        body: !body ? article.body : body,
        description: !description ? article.description : description,
        introParagraph: !introParagraph ? article.introParagraph : introParagraph,
        enabled: enabled === undefined ? article.enabled : enabled,
        featured: featured === undefined ? article.featured : featured,
        headerBackground: !headerBackground ? article.headerBackground : headerBackground,
        headerTitle: !headerTitle ? article.headerTitle : headerTitle,
        listViewImage: !listViewImage ? article.listViewImage : listViewImage,
        headerLogo: !headerLogo ? article.headerLogo : headerLogo,
        company: !companyId ? article.company?._id : companyId,
        type: articleType as IArticleType,
        headerType: headerType,
      };
      const response: IArticleResponse = await article.updateArticle(articleData);
      articlesModel.updateArticle(response);
      onClose();
      toaster.push({ 
        message: 'Article updated successfully',
      });
    } catch (err: any) {
      errorMessages.push({
        title: 'Error updating article',
        message: err.message,
      });
    }
  };

  const createArticle = async () => {
    try {
      const articleInfo: IArticleUpdate | any = {
        title,
        enabled,
        introParagraph,
        description,
        type: articleType,
        featured,
        body,
        headerBackground,
        headerType,
        listViewImage,
        headerLogo,
        headerTitle,
        createdOn: undefined,
        lastModified: undefined,
        deleted: false,
        company: companyId || '',
      };

      const response = await articlesModel.createArticle(articleInfo);
      articlesModel.addArticle(response);
      onClose();
      resetModal();
      toaster.push({ 
        message: 'Article created successfully',
      });
    } catch (err: any) {
      errorMessages.push({
        title: 'Error creating article',
        message: err.message,
      });
    }
  };

  const onSaveClick = () => {
    if (changesFound()) {
      if (!!article) updateArticle();
      else createArticle();
    }
  };

  const onPreview = () => {
    setShowArticlePreview(prev => !prev);
  };

  const onWarningModalClose = useCallback(() => {
    setSelectedArticleType(null);
  }, []);

  const ctas = useMemo(() => ([
    {
      id: 'cancel-promo-mod',
      text: 'Cancel',
      kind: ButtonKind.SecondaryWithIcon,
      onClick: onCancel,
    },
    {
      disabled: !changesFound(),
      id: 'save-promo-mod',
      text: 'Save',
      kind: ButtonKind.Primary,
      onClick: onSaveClick,
    },
  ]), [changesFound]);

  const renderArticleTypes = useCallback(() => {
    // get article types from db
    if(!!article) return null;

    return (
      <div className='article-type-select'>
        <label htmlFor='type-select'>Choose article type:</label>
        <select name='article-types' id='type-select' value={ articleType } onChange={ onTypeChange }>
          <option value=''>Select Article Type </option>
          { 
            
            articlesModel.templates.map((template: IArticleTemplate) => (
              <option value={ template.type } key={ template.type }>{ template.type }</option>
            ))
          }
        </select>
      </div>
    );
  }, [articlesModel, articleType, selectedArticleType]);

  const renderHeaderTypes = useCallback(() => {
    // get header types from db
    if (!!article) return null;
    let validHeaderTypes = articlesModel.headers;
    if (articleType === IArticleType.GoodAndBad || articleType === IArticleType.CompanySpotlight) {
      validHeaderTypes = validHeaderTypes.filter((header: ArticleHeaderTypes) => header === ArticleHeaderTypes.CompanyAndRating);
    }

    if (articleType === IArticleType.Feature || articleType === IArticleType.General) {
      validHeaderTypes = validHeaderTypes.filter((header: ArticleHeaderTypes) => header !== ArticleHeaderTypes.CompanyAndRating);
    }

    return (
      <div className='header-type-select'>
        <label htmlFor='type-select'>Choose header type:</label>
        <select name='article-types' id='type-select' onChange={ onHeaderTypeChange }>
          <option value=''> Select Header Type </option>
          { 
            validHeaderTypes.map((header: ArticleHeaderTypes) => (
              <option value={ header } key={ header }> { header } </option>
            )) 
          }
        </select>
      </div>
    );
  }, [articlesModel, articleType]);

  const warningModalCtas = [
    {
      id: 'cancel-warning',
      text: 'Cancel',
      kind: ButtonKind.SecondaryWithIcon,
      onClick: onWarningModalClose,
    },
    {
      id: 'continue-warning',
      text: 'Continue',
      kind: ButtonKind.Primary,
      onClick: onContinueTemplateChange,
    },
  ];

  const renderHtmlErrors = () => {
    if (!!htmlValidationError) return <div className='html-errors'>Error Validating HTML</div>;

    return (
      <div className='html-errors'>
        <ul>
          {
            htmlValidationResult.errors.map((error: IValidateHtmlError, index: number) => (
              <li key={ index }>
                { error.error }<br /><span className='error-location'>{ 'This error is located at ' + error.location }</span>
              </li>
            ))
          }
        </ul>
      </div>
    );
  };

  const bodyLabel = `Article Body* (html string)${!!htmlValidationResult?.isValid || !htmlValidationResult ? '' : ' ERRORS IN HTML!!!'}`;
    
  return (
    <ArticleModalContainer
      className={ className }
      title={ `${!!article ? 'Edit' : 'New'} Article` }
      ctas={ ctas }
      isOpen={ isOpen }
      onClose={ onArticleModalClose }
    >
      <ArticleDataContainer>
        <div className='info-text'>Use this modal to create a new article to display on our Industry Reports page. For information about the individual fields and what they correspond to please references this <a href='https://docs.google.com/presentation/d/11wr6Dgdu4qkNkgDDxn3w_N9I_SbEyHq1RUfGfxNMbFw/edit?usp=sharing' target='_blank' rel='noreferrer'>Guide to Articles</a></div>
        <Row>
          <ArticleInfoContainer>
            <div className='article-types-selection-container'>
              { renderArticleTypes() }
              { renderHeaderTypes() }
            </div>
            { 
              articleType === IArticleType.GoodAndBad || articleType === IArticleType.CompanySpotlight
                ? <>
                  <ArticleCompanySearch companyIdUpdate={ (id: string) => onCompanyIdChange(id) } />
                  <span className='company-id-label'>Company Id: { companyId }</span>
                </>
                : null
            }
            {
              headerType === ArticleHeaderTypes.LogoAndTitle
                ? <TextField
                  className='text-field'
                  fieldSize={ TextFieldSize.Small }
                  id='article-header-logo-field'
                  label='Header Logo*'
                  onChange={ onHeaderLogoChange }
                  placeholder='Enter article header logo image url'
                  value={ headerLogo }
                />
                : null 
            }
            {
              articleType === IArticleType.Feature || articleType === IArticleType.General || articleType === IArticleType.GoodAndBad
                ? <TextField
                  className='text-field'
                  fieldSize={ TextFieldSize.Small }
                  id='article-header-title-field'
                  label={ articleType !== IArticleType.GoodAndBad ? 'Header Title*' : 'Header Title (optional)' } 
                  onChange={ onHeaderTitleChange }
                  placeholder='Edit header title'
                  value={ headerTitle }
                /> 
                : null
            }
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Small }
              id='article-title-field'
              label='Article Title*'
              onChange={ onTitleChange }
              placeholder='Enter article title'
              value={ title }
            />
            <ArticleLabel
              kind={ ButtonKind.Blank }
              onClick={ () => setIntroParagraphOpen(prev => !prev) }
            >
              Intro Paragraph*
              <ChevronIcon
                className='chevron'
                direction={ introParagraphOpen ? ChevronDirection.Up : ChevronDirection.Down }
                stroke={ theme.colors.darkGray1 }
              />
            </ArticleLabel>
            { 
              introParagraphOpen &&
                <TextArea
                  labelHidden
                  className='text-field'
                  id='article-intro-field'
                  label='Intro Paragraph*'
                  onChange={ onIntroParagraphChange }
                  placeholder='Enter article intro paragraph'
                  value={ introParagraph }
                  size={ 500 }
                /> 
            }
            <ArticleLabel
              kind={ ButtonKind.Blank }
              onClick={ () => setDescriptionParagraphOpen(prev => !prev) }
            >
              Description (optional)
              <ChevronIcon
                className='chevron'
                direction={ descriptionParagraphOpen ? ChevronDirection.Up : ChevronDirection.Down }
                stroke={ theme.colors.darkGray1 }
              />
            </ArticleLabel>
            {
              descriptionParagraphOpen &&
                <TextArea
                  labelHidden
                  className='text-field'
                  id='article-description-field'
                  label='Description (optional)'
                  onChange={ onDescriptionChange }
                  placeholder='Enter article description'
                  value={ description }
                  size={ 500 }
                />
            }
            <ArticleLabel
              kind={ ButtonKind.Blank }
              onClick={ () => setArticleBodyOpen(prev => !prev) }
            >
              { bodyLabel }
              <ChevronIcon
                className='chevron'
                direction={ articleBodyOpen ? ChevronDirection.Up : ChevronDirection.Down }
                stroke={ theme.colors.darkGray1 }
              />
            </ArticleLabel>
            {
              articleBodyOpen &&
              <>
                <HTMLTextArea
                  labelHidden
                  className='text-field body-textarea'
                  type='input'
                  id='article-body-field'
                  label={ bodyLabel }
                  onChange={ onBodyChange }
                  placeholder='Edit article body'
                  value={ body }
                  size={ articleBodySize }
                />
                {
                  !!htmlValidationResult?.isValid || !htmlValidationResult ? null : renderHtmlErrors()
                }
              </>
            }
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Small }
              id='article-headerBackground-field'
              label={ !!headerBackgroundError ? <span>Header Background* <span className='url-error-text'>Please enter valid URL</span></span> : 'Header Background*' }
              onChange={ onHeaderBackgroundChange }
              placeholder='Enter article header background image url'
              value={ headerBackground }
            />
            {
              articleType === IArticleType.Feature || articleType === IArticleType.General ?
                (
                  <TextField
                    className='text-field'
                    fieldSize={ TextFieldSize.Small }
                    id='article-list-image-field'
                    label={ `List View Image ${headerType === ArticleHeaderTypes.TitleOnly ? '*' : '(optional)'}` }
                    onChange={ onListViewImageChange }
                    placeholder='Edit header title'
                    value={ listViewImage }
                  />
                ) : null
            }
            <Checkbox
              className='checkbox'
              id='article-enabled-field'
              label='Enabled'
              onChange={ onEnabledChange }
              placeholder='Edit enabled'
              checked={ enabled }
            />
            <Checkbox
              className='checkbox'
              id='article-enabled-field'
              label='Featured'
              onChange={ onFeaturedChange }
              placeholder='Edit Featured'
              checked={ featured }
            />
          </ArticleInfoContainer>
        </Row>
      </ArticleDataContainer>
      <ConfirmationModal 
        isOpen={ !!selectedArticleType }
        onClose={ onWarningModalClose }
        title='Hold on One Sec!'
        body='You have unsaved changes in your body field. Selecting this template will overwrite your changes. Are you sure you want to continue?'
        ctas={ warningModalCtas }
      />
      <Button
        className='preview-button'
        kind={ ButtonKind.Primary }
        onClick={ onPreview }
        disabled={ !changesFound() }
        id='preview-promo-mod'
      >
        {
          !!showArticlePreview ? 'Hide Preview' : 'Show Preview'
        }
      </Button>
      {
        !!showArticlePreview ?
          <ArticlePreview
            article={ {
              title, 
              type: articleType, 
              introParagraph, 
              description, 
              headerBackground, 
              headerLogo, 
              headerType, 
              body, 
              companyId, 
              headerTitle } } 
          /> : null
      }
    </ArticleModalContainer>
  );
};

const CreateUpdateArticleModalTheme = observer(CreateUpdateArticleModalBase);
export const CreateUpdateArticleModal = withTheme(CreateUpdateArticleModalTheme);
