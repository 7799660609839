import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { withTheme } from 'styled-components';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { IChart, IDoubleValueChartData } from '../../../models/chart';
import { ReportModel } from '../../../models/reports';
import { IThemeProps } from '../../../styles/themes';
import { LoadingSpinner } from '../../loading/LoadingSpinner';
import { ChartContainer, UserReportContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  report: ReportModel;
}

const mapData = ({ data }: IChart<string>): IDoubleValueChartData[] =>
  data.map((d) => ({
    name: d.label,
    value1: d.values[0].value,
    value2: d.values[1].value,
  }));

const UserReportBase: React.FC<IProps> = ({ className = '', report, theme }) => {
  const errorMessages = useErrorMessages();
  const [data, setData] = useState<IDoubleValueChartData[]>([]);
  const [maxLeftYAxisValue, setMaxLeftYAxisValue] = useState<number>(0);
  const [minLeftYAxisValue, setMinLeftYAxisValue] = useState<number>(0);
  const [maxRightYAxisValue, setMaxRightYAxisValue] = useState<number>(0);
  const [minRightYAxisValue, setMinRightYAxisValue] = useState<number>(0);

  useEffect(() => {
    report
      .load()
      .then(() => {
        const reportData = report?.chart as IChart<string>;
        setData(mapData(reportData));
        setMinLeftYAxisValue(reportData?.data[0]?.values[0].value);
        setMaxLeftYAxisValue(reportData?.data[reportData?.data?.length - 1]?.values[0].value);
        setMinRightYAxisValue(reportData?.data[0]?.values[1].value);
        setMaxRightYAxisValue(reportData?.data[reportData?.data?.length - 1]?.values[1].value);
      })
      .catch((err) => {
        errorMessages.push({
          title: 'Error Loading Report',
          message: err.message,
        });
      });
  }, []);

  const renderChart = () => {
    if (report.busy || !data.length) {
      return <LoadingSpinner />;
    }

    return (
      <div>
        <ResponsiveContainer width='100%' height={ 200 }>
          <LineChart
            width={ 500 }
            height={ 200 }
            data={ data }
            margin={ {
              top: 10,
              right: 0,
              left: 0,
              bottom: 0,
            } }
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis minTickGap={ 10 } dataKey='name' />
            <YAxis domain={ [() => minLeftYAxisValue, () => maxLeftYAxisValue] } yAxisId='left' />
            <YAxis domain={ [() => minRightYAxisValue, () => maxRightYAxisValue] } yAxisId='right' orientation='right' />
            <Tooltip />
            <Legend />
            <Line
              yAxisId='left'
              connectNulls
              type='monotone'
              dataKey='value1'
              stroke={ theme.colors.green }
              fill={ theme.colors.green }
              name='signups'
            />
            <Line
              yAxisId='right'
              connectNulls
              type='monotone'
              dataKey='value2'
              stroke={ theme.colors.primary }
              fill={ theme.colors.primary }
              name='with one or more accounts'
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <UserReportContainer className={ className }>
      <ChartContainer>{ renderChart() }</ChartContainer>
      { !!report.lastUpdated && (
        <p className='last-updated'>last updated { report.lastUpdated.format('MMM DD, YYYY @ HH:MM A') }</p>
      ) }
    </UserReportContainer>
  );
};

const UserReportAsObserver = observer(UserReportBase);
export const UserReport = withTheme(UserReportAsObserver);
