import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { H1 } from '../../../styles/components/header';
import { ButtonRow, LegalTextContainer } from './styles';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { IKarmaCardLegalText, KarmaCardLegalModel } from '../../../models/karmaCard';
import { ButtonKind } from '../../../components/Button/styles';
import { Button } from '../../../components/Button';
import { LegalItem } from './LegalItem';
import { CreateLegalTextModal } from './CreateLegalTextModal';

interface IProps {
  className?: string;
}

const KarmaCardLegalTextBase: React.FC<IProps> = ({
  className = '',
}) => {
  const errorMessages = useErrorMessages();
  const karmaCardLegalTextModel = useRef(new KarmaCardLegalModel()).current;
  const [createLegalTextModalOpen, setCreateLegalTextModalOpen] = useState(false);

  const getLegalTexts = useCallback(async () => {
    try {
      await karmaCardLegalTextModel.getLegalText();
    } catch (err: any) {
      errorMessages.push({
        title: 'Error loading legal texts',
        message: err.message,
      });
    }
  },[]);

  useEffect(() => {
    getLegalTexts();
  }, []);

  const onModalClose = useCallback(() => {
    setCreateLegalTextModalOpen(false);
    getLegalTexts();
  },[]);

  const onCreateClick = useCallback(() => {
    setCreateLegalTextModalOpen(true);
    getLegalTexts();
  },[]);
  
  const currentLegalTexts = useMemo(() => {
    if (!!karmaCardLegalTextModel.busy) return <LoadingSpinner />;
    if (!karmaCardLegalTextModel.legalText.length) return null;

    const content = karmaCardLegalTextModel.legalText.map((legalText: IKarmaCardLegalText) => (
      <LegalItem
        legalItem={ legalText }
        key={ legalText._id }
        karmaCardLegalTextModel={ karmaCardLegalTextModel }
      />
    ));

    return content;
  }, [karmaCardLegalTextModel.legalText]);

  return (
    <LegalTextContainer
      className={ className }
      title='Karma Card Legal'
    >
      <H1>Karma Card Legal Texts</H1>
      <ButtonRow>
        <Button
          kind={ ButtonKind.Primary }
          onClick={ onCreateClick }
        >
          New Karma Card Legal Text
        </Button>
      </ButtonRow>
      { currentLegalTexts }
      <CreateLegalTextModal 
        isOpen={ createLegalTextModalOpen }
        onClose={ onModalClose }
        karmaCardLegalTextModel={ karmaCardLegalTextModel }
      />
    </LegalTextContainer>
  );
};

export const KarmaCardLegalText = observer(KarmaCardLegalTextBase);
