import styled from 'styled-components';
import { NoScrollBar, PrimaryFont } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger};
`;

export const FieldContainer = styled.div`
  padding-top: 15px;
`;

export const FalsePositiveModalContainer = styled(CustomModal)`
  width: 90%;
  max-width: 650px;
`;

export const MatchTypeContainer = styled(FieldContainer)`
  position: relative;

  .match-type-dropdown {
    .dropdown-options-container {
      ${NoScrollBar}
      max-height: 200px;
      overflow: auto;
    }
  }

  .match-type-dropdown {
    .dropdown-options-container {
      min-width: 100px;
    }
  }
`;

export const FieldLabel = styled.div`
  font-weight: 600;
  ${PrimaryFont};
  font-size: 16px;
  margin-bottom: 10px;
`;
