import React, { useCallback, useState } from 'react';
import { CTAContainer, CreateLegalTextModalContainer } from './styles';
import { TextField, TextFieldKind } from '../../../../components/TextField';
import { TextArea } from '../../../../components/TextArea';
import { KarmaCardLegalModel } from '../../../../models/karmaCard';
import { Button } from '../../../../components/Button';
import { useToaster } from '../../../../contexts/toaster-store';
import { ToastType } from '../../../../models/toaster';
import { ButtonKind } from '../../../../components/Button/styles';

interface IProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  karmaCardLegalTextModel: KarmaCardLegalModel;
}

export const CreateLegalTextModal: React.FC<IProps> = ({
  className = '',
  isOpen,
  onClose,
  karmaCardLegalTextModel,
}) => {
  const toaster = useToaster();
  const [ name, setName ] = useState('');
  const [ text, setText ] = useState('');

  const resetFields = useCallback(() => {
    setName('');
    setText('');
  },[]);

  const onSaveClick = useCallback(async () => {
    try {
      await karmaCardLegalTextModel.createLegalText({ name, text });
      resetFields();
      onClose();
      toaster.push({
        type: ToastType.Success,
        message: 'Creating legal text was successful',
      });
    } catch (err: any) {
      toaster.push({
        type: ToastType.Error,
        message: err.message,
      });
    }
  },[name, text]);

  const onCancelClick = useCallback(() => {
    resetFields();
    onClose();
  },[]);

  const onNameChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const onTextChangeHandler = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  }, []);
  
  return (
    <CreateLegalTextModalContainer
      className={ className }
      isOpen={ isOpen }
      onClose={ onClose }
      title={ 'Create Karma Card Legal Text' }
    >
      <TextField
        fieldKind={ TextFieldKind.Regular }
        className='text-field'
        label='Legal Name'
        id='name'
        onChange={ onNameChangeHandler }
        placeholder='Enter name'
        value={ name }
      />
      <TextArea
        className='text-field'
        label='Legal Text'
        id='name'
        onChange={ onTextChangeHandler }
        placeholder='Enter legal text'
        value={ text }
      />
      <CTAContainer>
        <Button
          kind={ ButtonKind.Primary }
          onClick={ onSaveClick }
          disabled={ !name || !text }
        >
          Save
        </Button>
        <Button
          kind={ ButtonKind.Primary }
          onClick={ onCancelClick }
        >
          Cancel
        </Button>
      </CTAContainer>
    </CreateLegalTextModalContainer>
  );

};
