import styled from 'styled-components';
import { H1 } from '../../../styles/components/header';
import { NoScrollBar } from '../../../styles/styles';

export const JobPostingsContainer = styled.div``;

export const JobPostingsList = styled.div`
  ${ NoScrollBar }
  overflow: auto;

  .job-posting {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray1} `};
  }
`;

export const NoJobs = styled(H1)`
  margin-top: 50px;
  color: ${({ theme }) => theme.colors.lightGray1};
  text-align: center;
`;

export const OptionsContainer = styled.div`
  padding: 20px;
`;
