import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { UserModel, UsersModel } from '../../models/users';
import { TextField } from '../TextField';
import { UserListItem } from '../UserListItem';
import { SearchContainer, UserPickerContainer, UsersList, UsersListContainer } from './styles';

interface IProps {
  className?: string;
  onUserSelected(user: UserModel): void;
  usersModel: UsersModel;
}

const UserPickerBase: React.FC<IProps> = ({
  className = '',
  onUserSelected,
  usersModel,
}) => {
  const errorMessages = useErrorMessages();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchThrottle, setSearchThrottle] = useState<number>(null);

  const loadMore = () => {
    usersModel.users.loadMore({
      name: !!searchQuery ? `/${searchQuery}/gi` : null,
    })
      .catch(err => {
        errorMessages.push({
          title: 'Error getting users',
          message: err,
        });
      });
  };
  
  useEffect(() => {
    if (!usersModel.users.firstPageLoaded) {
      loadMore();
    }
  }, []);

  useEffect(() => {
    window.clearTimeout(searchThrottle);

    if (usersModel.users.firstPageLoaded) {
      setSearchThrottle(window.setTimeout(() => {
        usersModel.users.refresh({
          name: !!searchQuery ? `/${searchQuery}/gi` : null,
        });
      }, 300));
    }
  }, [searchQuery]);

  const onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, []);

  const onUserClick = useCallback((user: UserModel) => {
    onUserSelected(user);
  }, [onUserSelected]);

  const renderUsers = () => {
    let users: JSX.Element[] = [];

    if (usersModel.users.results.length > 0) {
      users = usersModel.users.results.map(user => (
        <UserListItem
          key={ user._id }
          onClick={ onUserClick }
          user={ user }
        />
      ));
    } else {
      users.push(<div key='no-users' className='no-users'>No users found</div>);
    }

    if (usersModel.users.busy) {
      if (usersModel.users.firstPageLoaded) {
        users.push((
          <div key='loading-users-spinner'>
            loading more...
          </div>
        ));
      } else {
        // const skeletons: JSX.Element[] = [];

        // for (let i = 0; i < 25; i++) {
        //   skeletons.push(<UserListItemSkeleton key={ `user-item-skele-${i}` } />);
        // }

        // users = [...users, ...skeletons];
      }
    }

    if (!usersModel.users.allResultsFetched && !usersModel.users.busy) {
      users.push(<Waypoint key='waypoint' onEnter={ loadMore } topOffset={ 200 } />);
      users.push(<div key='waypoint-support' className='waypoint-support' />);
    }

    return users;
  };

  return (
    <UserPickerContainer className={ className }>
      <SearchContainer>
        <TextField
          id='user-picker-search'
          label='Search Users'
          labelHidden
          onChange={ onSearchChange }
          placeholder='Search Users'
          value={ searchQuery }
        />
      </SearchContainer>
      <UsersListContainer>
        <UsersList>
          { renderUsers() }
        </UsersList>
      </UsersListContainer>
    </UserPickerContainer>
  );
};

export const UserPicker = observer(UserPickerBase);
