import styled from 'styled-components';

export const ChartContainer = styled.div`
  position: relative;
`;

export const AccountsAddedHistoryReportContainer = styled.div`
  width: 60vw;
  padding: 20px 0;

  .last-updated {
    margin-top: 20px;
    font-size: 12px;
    font-style: italic;
    color: ${({ theme }) => theme.colors.lightGray1};
    text-align: right;
  }
`;
