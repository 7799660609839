import styled from 'styled-components';
import { MainAdminContainer } from '../../../components/MainAdminContainer';
import { BasicContainer, FlexCol, FlexHorizontalCenter, FlexRow, PrimaryFont } from '../../../styles/styles';

export const MainContainer = styled.div`
  ${FlexHorizontalCenter}
  flex-wrap: wrap;
  gap: 20px;
  height: calc(100% - 50px);
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  font-style: normal;

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    margin: 0 50px;
  }
`;

export const ReportCard = styled.button`
  ${BasicContainer}
  ${FlexCol}
  min-width: 220px;
  max-width: 220px;
  min-height: 250px;
  max-height: 250px;
  margin: 0 10px 20px;
  transition: 0.25s ease-in-out;
  outline: none;
  height: 100%;

  &:hover,
  &:focus-visible {
    margin-top: 8px;
    transform: scale(1.05);
  }

  &:hover {
    cursor: pointer;
  }

  &:nth-child(4n) {
    background: ${({ theme }) => theme.colors.lightGreen2};
  }

  &:nth-child(4n + 1) {
    background: ${({ theme }) => theme.colors.lightBlue1};
  }

  &:nth-child(4n + 2) {
    background: ${({ theme }) => theme.colors.lightYellow1};
  }

  &:nth-child(4n + 3) {
    background: ${({ theme }) => theme.colors.white};
  }

  .header {
    ${PrimaryFont}
    font-size: 18px;
    font-weight: 600;
  }

  .desc {
    ${FlexHorizontalCenter}
    flex-grow: 1;
  }

  .last-updated {
    margin-bottom: 0;
    font-size: 12px;
    font-style: italic;
    text-align: center;
    width: 100%;
  }
`;

export const ReportCardsContainer = styled.div`
  ${FlexHorizontalCenter}
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const ReportsContainer = styled(MainAdminContainer)``;

export const ReportsListContainer = styled.div``;

export const SummaryContainer = styled.div`
  ${BasicContainer}
  ${FlexRow}
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  overflow: auto;
  max-height: 60%;

  & > .header {
    ${PrimaryFont}
    width: 100%;
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: 700;
    text-align: left;
  }

  @media (${({ theme }) => theme.breakpoints.lgMin}) {
    max-height: 100%;
  }
`;

export const SummaryItem = styled.div`
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 300px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .header {
    ${PrimaryFont}
    font-size: 16px;
    font-weight: 600;
    padding: 2px;
    padding-left: 5px;
    background-color: ${({ theme }) => theme.colors.lightGreen2};
  }

  .data {
    width: 100%;
    overflow: auto;
    max-height: 250px;

    .row:last-child {
      border-bottom: none;
    }

    .row:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.light};
    }

    .row {
      ${FlexHorizontalCenter}
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray2};
      min-width: 100%;
      column-gap: 10px;

      .label {
        margin-left: 20px;
        text-align: left;
        width: 50%;
        padding-top: 4px;
        padding-bottom: 4px;

        em {
          font-style: normal;
        }
      }

      .value {
        text-align: right;
        width: 50%;
        margin-right: 20px;
      }
    }
  }
`;
