import styled from 'styled-components';
import { FlexCenter, FlexCol, FlexHorizontalCenter } from '../../../styles/styles';

export const Accessory = styled.div`
  ${ FlexCenter }
`;

export const BaseInfoContainer = styled.div`
  ${ FlexHorizontalCenter }
  align-items: stretch;
  padding-bottom: 20px;

  & > * {
    flex-grow: 1;
  }

  .logo-container {
    ${ FlexCenter }
    min-width: 300px;
    max-width: 300px;

    & > * {
      min-width: 90%;
      max-width: 90%;
    }
  }

  .text-field {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &.group-code-field-input {
      margin-bottom: 0;
    }
  }
`;

export const GroupCodeSubFieldSection = styled.div`
  ${ FlexHorizontalCenter }
  justify-content: space-between;
  margin-bottom: 20px;

  .random-code-cta {
    padding: 10px 0 0;
  }
`;

export const GroupModalWrapper = styled.div`
  width: 70vw;
  max-width: 1100px;
  max-height: 60vh;
  padding-right: 15px;
  overflow: auto;

  .input-field-error,
  .input-field-success {
    margin: 0;
    font-size: 12px;
  }

  .input-field-error {
    color: ${({ theme }) => theme.colors.red};
  }

  .input-field-success {
    color: ${({ theme }) => theme.colors.green};
  }

  .input-field-support-text {
    margin: 0;
    padding-top: 3px;
    font-size: 12px;
    font-style: italic;
    line-height: 1.2em;
  }

  .primary-ctas-container {
    margin-top: 20px;
  }
`;

export const MatchTextFieldContainer = styled.div`
  min-width: 125px;
  max-width: 125px;
  margin-right: 20px;

  &:last-child {
    min-width: 230px;
    max-width: 230px;
  }
`;

export const Setting = styled.div`
  padding: 5px 0;

  &.indent {
    padding-left: 20px;
  }

  .setting-text-field {
    padding-top: 15px;
  }
`;

export const MatchSetting = styled(Setting)`
  ${ FlexHorizontalCenter }
  align-items: flex-start;
`;

export const OwnerContainer = styled.div`
  ${ FlexCol }
  align-items: flex-start;
`;

export const OwnerPill = styled.div`
  position: relative;
  display: inline-block;
  min-width: 100px;
  margin-top: 5px;
  padding: 8px 20px;
  background: ${({ theme }) => theme.colors.lightGreen2};
  border: ${({ theme }) => `1px solid ${theme.colors.green}`};
  border-radius: 999px;
  white-space: nowrap;
  text-align: center;

  &:hover {
    .remove-owner-button {
      display: flex;
    }
  }

  .remove-owner-button {
    ${ FlexCenter }
    position: absolute;
    top: 5px;
    left: calc(100% - 7px);
    display: none;
    width: 20px;
    height: 20px;
    padding: 0;
    background: #fff;
    border: ${({ theme }) => `1px solid ${theme.colors.green}`};
    transition: .25s ease-in-out;
    transform: translate(-50%, -50%);

    &:hover {
      right: -7px;
      bottom: -7px;
      width: 24px;
      height: 24px;
      border: ${({ theme }) => `1px solid ${theme.colors.darkGreen1}`};

      svg {
        stroke: ${({ theme }) => theme.colors.darkGreen1};
      }
    }

    svg {
      width: 60%;
      height: 60%;
      padding-left: 2px;
      stroke: ${({ theme }) => theme.colors.green};
    }
  }
`;

export const PrivacyStatusContainer = styled.div`
  ${ FlexHorizontalCenter }
  padding: 20px 0;
`;

export const SettingsContainer = styled.div``;
