import styled from 'styled-components';
import { FlexHorizontalCenter, NoScrollBar, TruncateText } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger};
`;

export const FieldContainer = styled.div`
  padding-top: 15px;
`;

export const SectorModalContainer = styled(CustomModal)`
  width: 90%;
  max-width: 650px;
`;

export const TierContainer = styled(FieldContainer)`
  ${FlexHorizontalCenter}
  position: relative;

  .tiers-dropdown,
  .parent-sectors-dropdown {
    .dropdown-options-container {
      ${NoScrollBar}
      max-height: 200px;
      overflow: auto;
    }
  }

  .tiers-dropdown {
    .dropdown-options-container {
      min-width: 100px;
    }
  }

  .parent-sectors-dropdown {
    min-width: 350px;
    max-width: 350px;
    margin-left: 10px;

    & > button {
      width: 100%;
    }

    .selected-dropdown-option {
      ${TruncateText}
      text-align: left;
    }

    .dropdown-options-container {
      min-width: 400px;

      button {
        min-height: 30px;
      }
    }
  }
`;
