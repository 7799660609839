import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AnalyticDetailsContainer, AnalyticInfoContainer, CreateUpdateAnalyticModalContainer, SelectField } from './styles';
import { TextField, TextFieldSize } from '../../TextField';
import { ButtonKind } from '../../Button/styles';
import { TextArea } from '../../TextArea';
import { IWebAnalytic, WebAnalyticsModel } from '../../../models/web-analytics';
import { useToaster } from '../../../contexts/toaster-store';
import { useErrorMessages } from '../../../contexts/error-messages-store';

interface IProps {
  className?: string;
  onClose: () => void;
  isOpen: boolean;
  analytic?: IWebAnalytic;
  analyticsModel?: WebAnalyticsModel;
  selectedPage?: any;
}

const CreateUpdateWebAnalyticsModalBase: React.FC<IProps> = ({
  className = '',
  isOpen,
  onClose,
  analytic,
  analyticsModel,
  selectedPage,
}) => {
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [subLocation, setSubLocation] = useState('');
  const toaster = useToaster();
  const errorMessages = useErrorMessages();

  const changesFound = () => {
    if (analytic) {
      return (
        analytic.name !== name ||
        analytic.description !== description ||
        analytic.location !== location ||
        analytic.subLocation !== subLocation
      );
    } else {
      return (
        !!name &&
        !!description &&
        !!location &&
        !!subLocation
      );
    }
  };
  
  useEffect(() => {
    if (analytic) {
      setDescription(analytic.description);
      setName(analytic.name);
      setLocation(analytic.location);
      setSubLocation(analytic.subLocation);
    }
  }, [analytic]);

  const resetModal = () => {
    setDescription(analytic?.description || '');
    setName(analytic?.name || '' );
    setLocation(analytic?.location || '');
    setSubLocation(analytic?.subLocation || '');
  };

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const onDescriptionChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  }, []);

  const onSubLocationChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSubLocation(e.target.value);
  }, []);

  const onLocationChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setLocation(e.target.value);
  }, []);

  const onSaveClick = async () => {
    try {
      if (!!analytic) {
        await analyticsModel.update({
          _id: analytic._id,
          name,
          description,
          subLocation,
          location,
        });
        onCloseModal();
        toaster.push({ message: 'Analytic updated successfully.' });
        return;
      } else {
        await analyticsModel.create({
          name,
          description,
          subLocation,
          location,
        }, selectedPage.key);
        onCloseModal();
        toaster.push({ message: 'Analytic created successfully.' });
      }
    } catch(err: any) {
      errorMessages.push({
        title: 'Something went wrong',
        message: err.message,
      });
    }
  };

  const onCloseModal = () => {
    resetModal();
    onClose();
  };

  const ctas = [
    {
      id: 'cancel-banner-mod',
      text: 'Cancel',
      kind: ButtonKind.SecondaryWithIcon,
      onClick: onCloseModal,
    },
    {
      disabled: !changesFound(),
      id: 'save-banner-mod',
      text: 'Save',
      kind: ButtonKind.Primary,
      onClick: onSaveClick,
    },
  ];

  return (
    <CreateUpdateAnalyticModalContainer
      className={ className }
      title={ `${analytic ? 'Edit' : 'Create'} Analytic` }
      ctas={ ctas }
      isOpen={ isOpen }
      onClose={ onCloseModal }
    >
      <AnalyticDetailsContainer>
        <AnalyticInfoContainer>
          <TextField
            className='text-field'
            fieldSize={ TextFieldSize.Small }
            id='analytic-name-field'
            label='Analytic Name'
            onChange={ onNameChange }
            placeholder='Enter an analytic name'
            value={ name }
          />
          <TextArea
            className='text-field'
            id='analytic-description-field'
            label='Analytic Description'
            onChange={ onDescriptionChange }
            placeholder='Enter analytic description'
            value={ description }
          />
          <TextField
            className='text-field'
            fieldSize={ TextFieldSize.Small }
            id='analytic-sublocation-field'
            label='Analytic Sub-Location'
            onChange={ onSubLocationChange }
            placeholder='Enter a sub-location'
            value={ subLocation }
          />
          <SelectField
            onChange={ onLocationChange }
            hidden={ !!analytic }
          >
            {
              <option value=''>
                Choose a location
              </option>
            }
            {
              analyticsModel.analyticsLocations.map((item) => (
                <option
                  key={ item._id }
                  value={ item.key }
                >
                  { item.value }
                </option>
              ))
            }
          </SelectField>
        </AnalyticInfoContainer>
      </AnalyticDetailsContainer>
    </CreateUpdateAnalyticModalContainer>
  );
};

export const CreateUpdateWebAnalyticsModal = observer(CreateUpdateWebAnalyticsModalBase);
