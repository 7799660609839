import * as React from 'react';
import 'rc-tooltip/assets/bootstrap.css';
import _Slider from 'rc-slider';
import type { SliderProps } from 'rc-slider';
import raf from 'rc-util/lib/raf';
import Tooltip from 'rc-tooltip';

interface IProps {
  withToolTip?: boolean;
}

const HandleTooltip = (props: {
  value: number;
  children: React.ReactElement;
  visible: boolean;
  tipFormatter?: (value: number) => React.ReactNode;
}) => {
  const { value, children, visible, tipFormatter = (val) => `${val} %`, ...restProps } = props;

  const tooltipRef = React.useRef<any>();
  const rafRef = React.useRef<number | null>(null);

  function cancelKeepAlign() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    raf.cancel(rafRef.current!);
  }

  function keepAlign() {
    rafRef.current = raf(() => {
      tooltipRef.current?.forcePopupAlign();
    });
  }

  React.useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible]);

  return (
    <Tooltip
      placement='top'
      overlay={ tipFormatter(value) }
      overlayInnerStyle={ { minHeight: 'auto' } }
      ref={ tooltipRef }
      visible={ visible }
      { ...restProps }
    >
      { children }
    </Tooltip>
  );
};

export const handleRender: SliderProps['handleRender'] = (node, props) => (
  <HandleTooltip value={ props.value } visible={ props.dragging }>
    { node }
  </HandleTooltip>
);

export const Slider = ({
  tipFormatter,
  tipProps,
  withToolTip = true,
  ...props
}: IProps & SliderProps & { tipFormatter?: (value: number) => React.ReactNode; tipProps?: any }) => {
  const tipHandleRender: SliderProps['handleRender'] = (node, handleProps) => {
    if (!withToolTip) return node;

    return (
      <HandleTooltip
        value={ handleProps.value }
        visible={ handleProps.dragging }
        tipFormatter={ tipFormatter }
        { ...tipProps }
      >
        { node }
      </HandleTooltip>
    );
  };

  return <_Slider { ...props } handleRender={ tipHandleRender } />;
};
