import styled from 'styled-components';
import { CustomModal } from '../../../../components/modals/CustomModal';

export const ManageCommissionModalContainer = styled(CustomModal)`
  height: 90vh;
  max-width: 600px;
  max-height: 450px;
  width: 90%;
`;

export const CommissionInfoLine = styled.div`
  margin-top: 10px;

  span {
    font-weight: 600;
  }
`;
