import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { BaseModel } from './base';
import { CollectionModel, ICollectionConfig } from './collection';

type PrivateFields = '_busy' |
'_campaigns' |
'_isAdmin' |
'_init';

type PrivateCampaignFields = '_busy' |
'_campaign';

export enum ICampaignParamKeys {
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
}

export interface ICampaignUpdate {
  name: string;
  description: string;
  paramKeyToUse?: ICampaignParamKeys
}

export interface ICampaign extends ICampaignUpdate {
  createdOn: Date;
  lastModified: Date;
  _id: string;
}

export class CampaignModel extends BaseModel {
  private _busy = false;
  private _campaign: ICampaign = null;

  constructor (campaignInfo: ICampaign) {
    super();
    makeObservable<CampaignModel, PrivateCampaignFields>(this, {
      _busy: observable,
      _campaign: observable,
      _id: computed,
      busy: computed,
      description: computed,
      name: computed,
      paramKeyToUse: computed,
    });

    this._campaign = campaignInfo;
  }

  get busy() { return this._busy; }
  get description() { return this._campaign.description; }
  get _id() { return this._campaign._id; }
  get name() { return this._campaign.name; }
  get paramKeyToUse() { return this._campaign.paramKeyToUse; }

  public updateCampaign = async (data: ICampaignUpdate) => {
    if (this._busy) return;

    runInAction(() => {
      this._busy = true;
    });

    const result = await this.webServiceHelper.sendRequest<ICampaign>(
      {
        path: `/admin/campaign/${this._id}`,
        method: 'PUT',
        data,
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }

    return result.value;
  };
}

export class CampaignsModel extends BaseModel {
  private _busy = false;
  private _campaigns: CollectionModel<ICampaign, CampaignModel> = null;
  private _isAdmin = false;
  private _config: ICollectionConfig = {};

  constructor(config: ICollectionConfig = {}, isAdmin = false) {
    super({ basePath: '/admin/campaign' });
    makeObservable<CampaignsModel, PrivateFields>(this, {
      _busy: observable,
      _campaigns: observable,
      _isAdmin: observable,
      campaigns: computed,
      _init: action.bound,
    });

    this._config = config || {};
    this._isAdmin = isAdmin;
    this._init();
  }

  get busy () { return this._busy; }
  get campaigns () { return this._campaigns; }

  public createCampaign = async (data: ICampaignUpdate) => {
    if (this._busy || !this._isAdmin) return;

    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<ICampaign>(
      {
        path: '/admin/campaign',
        method: 'POST',
        data,
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._campaigns.push(new CampaignModel(result.value));
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }

    return result.value;
  };

  private _init = () => {
    const url = '/admin/campaign';
    this._campaigns = new CollectionModel<ICampaign, CampaignModel>(
      url,
      (campaignInfo: ICampaign) => new CampaignModel(campaignInfo),
      this._config,
    );
  };
}
