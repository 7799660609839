import styled from 'styled-components';
import { FlexRow } from '../../../../styles/styles';

export const PageSectionContainer = styled.section``;

export const AnalyticsItemHeader = styled.div`
  background: ${({ theme }) => theme.colors.dark};
  display: grid;
  font-size: 12px;
  font-weight: 600;
  grid-template-columns: 4fr 8fr 2fr 1fr;
  margin-top: 20px;
  padding: 10px 20px;

  * {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const AnalyticsItem = styled.div`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey2};
  border-top: none;
  display: grid;
  font-size: 12px;
  grid-template-columns: 4fr 8fr 2fr 1fr;
  padding: 10px 20px;

  .icon-container {
    ${FlexRow}
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
   
    svg {
      max-height: 15px;
      max-width: 15px;
    }

    svg:hover {
      cursor: pointer;
      stroke: ${({ theme }) => theme.colors.red};
    }
  }

  * {
    color: ${({ theme }) => theme.colors.dark};
  }
`;
