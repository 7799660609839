import styled from 'styled-components';
import { FlexCol, FlexHorizontalCenter, FlexRow, NoScrollBar, PrimaryFont } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';
import { Button } from '../../Button';

export const ArticleDataContainer = styled.div`
  ${ NoScrollBar }
  flex-grow: 1;
  overflow: scroll;
  padding-right: 10px;
  position: relative;
`;

export const ArticleModalContainer = styled(CustomModal)`
  ${ FlexCol }
  width: 75vw;
  height: 90vh;
  overflow: hidden;

  .info-text {
    margin-bottom: 20px;
  }

  .text-field {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .custom-modal-body {
    overflow: auto;
    overflow-x: hidden;
  }

  .modal-header {
    margin-bottom: 20px;
  }

  .preview-button {
    margin-top: 20px;
  }
`;

export const Row = styled.div`
  ${ FlexHorizontalCenter }
  align-items: flex-start;
  
  &:not(:last-child) {
    padding-bottom: 20px;
  }
`;

export const ArticleInfoContainer = styled.div`
  flex-grow: 1;

  .article-types-selection-container {
    flex-direction: column;
    gap: 60px;

    select {
      padding: 5px;
      border-radius: 0px;
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }

    @media (${({ theme }) => theme.breakpoints.xlMin}) {
      ${ FlexRow }
    }
  }

  span.company-id-label {
    ${ PrimaryFont }
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkGray1};
    margin-bottom: 10px;
    display: block;
  }

  .url-error-text {
    color: ${( { theme } ) => theme.colors.danger };
  }

  .error-location {
    color: red;
  }

  textarea {
    font-size: 1.25rem;
    resize: vertical;
    
    .body {
      min-height: 900px;
    }
  }

  .article-type-select {
    margin-bottom: 10px;
    
    select {
      margin-left: 10px;
    }
  }

  .header-type-select {
    margin-bottom: 20px;
    
    select {
      margin-left: 10px;
    }
  }
`;

export const InputContainer = styled.div`
  padding-bottom: 20px;
    
  .regular-label {
    font-weight: 600;
  }
`;

export const ArticleEventsContainer = styled(InputContainer)``;

export const ArticleTypeContainer = styled(InputContainer)`
  ${ FlexRow }
  align-items: center;
  justify-content: flex-start;

  .promo-type-dropdown {
    max-width: 300px;
    margin-left: 10px;
  }
`;

export const DateContainer = styled.div`
  margin-left: 10px;

  label {
    margin-right: 10px;
    font-size: 15px;
  }
`;

export const DatesSection = styled(InputContainer)`
  .dates-subtext {
    margin: 10px 0;
    font-size: 14px;
    font-style: italic;
  }
`;

export const DatesWrapper = styled.div`
  ${ FlexRow }
`;

export const ArticleLabel = styled(Button)`
  color: ${({ theme }) => theme.colors.darkGray1};
  font-family: AvenirNext;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  
  .chevron {
    margin-left: 20px;
  }
`;
