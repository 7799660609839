import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { CTAs } from '../../../../components/CTAs';
import { FlexCenter, FlexCol } from '../../../../styles/styles';

export interface IImageUploadProps {
  withBorder?: boolean;
}

export const EditButtonContainer = styled.div`
  ${ FlexCenter }
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.lightGray1};

  svg {
    stroke: ${({ theme }) => theme.colors.darkGray1};
  }
`;

export const ImageUploadContainer = styled.div<IImageUploadProps>`
  ${FlexCol}
  align-items: start;
  position: relative;

  & > *:not(.file-uploader) {
    display: block;
    width: 80%;
    max-height: 80%;

    &.default-group-logo {
      ${ FlexCenter }
     width: 100%;
     height: 100%;
    }
  }

  ${({ withBorder, theme }) => withBorder
    ? `
      border: 2px solid ${theme.colors.darkGray1};
      border-radius: 50%;
    `
    : null}
`;

export const GroupLogoSkeletonContainer = styled(Skeleton)<IImageUploadProps>``;

export const UploaderCTAs = styled(CTAs)`
  min-width: 200px;
`;
