import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { Button } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { TextField, TextFieldKind } from '../../../components/TextField';
import { UserListItem } from '../../../components/UserListItem';
import { UserListItemSkeleton } from '../../../components/UserListItem/skeleton';
import { IUserFilter, UsersFilter } from '../../../components/UsersFilter';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { useToaster } from '../../../contexts/toaster-store';
import { useUserSession } from '../../../contexts/user';
import { AdminUsersModel, MarqetaUserStatus, UserModel, UserRoles } from '../../../models/users';
import { H1 } from '../../../styles/components/header';
import { useAnalytics } from '../../../contexts/analytics-store';
import {
  MainContainer,
  SearchByContainer,
  SearchContainer,
  SummaryContainer,
  UsersContainer,
  UsersInfoContainer,
  UsersList,
  UsersListContainer,
} from './styles';
import { DeleteUserConfirmationModal } from '../../../components/modals/DeleteUserConfirmationModal';
import { Label } from '../../../styles/components/label';
import { RadioGroup, RadioGroupStack } from '../../../components/RadioGroup';
import { GPADepositModal } from '../../../components/modals/GPADepositModal';
import { replaceSpecialCharsWithDot } from '../../../lib/misc';

interface IProps {
  className?: string;
}

type SearchByOption = 'name' | 'email';

interface ISearchByButton {
  id: SearchByOption;
  label: string;
  checked: boolean;
}

const UsersBase: React.FC<IProps> = ({ className = '' }) => {
  const errorMessages = useErrorMessages();
  const adminUsersModel = useRef(new AdminUsersModel()).current;
  const analytics = useAnalytics();
  const filterEngaged = useRef(false);
  const [filter, setFilter] = useState({});
  const [successfulDelete, setSuccessfulDelete] = useState(false);
  const [successfulUnlock, setSuccessfulUnlock] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchThrottle, setSearchThrottle] = useState<number>(null);
  const [userToDelete, setUserToDelete] = useState<UserModel>(null);
  const [userToUnlock, setUserToUnlock] = useState<UserModel>(null);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [searchBy, setSearchBy] = useState<SearchByOption>('name');
  const [userToFund, setUserToFund] = useState<UserModel>(null);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const toaster = useToaster();
  const userSession = useUserSession();

  const loadMore = () => {
    adminUsersModel.users
      .loadMore({
        name: !!searchQuery ? `/${searchQuery}/gi` : null,
        ...filter,
      })
      .catch((err) => {
        errorMessages.push({
          title: 'Error getting users',
          message: err.message,
        });
      });
  };

  useEffect(() => {
    if (!adminUsersModel.users.firstPageLoaded) loadMore();
  }, []);

  useEffect(() => {
    adminUsersModel.users.refresh(filter);
  }, [filter]);

  useEffect(() => {
    window.clearTimeout(searchThrottle);

    if (adminUsersModel.users.firstPageLoaded) {
      setSearchThrottle(
        window.setTimeout(() => {
          const sanitizedSearchQuery = replaceSpecialCharsWithDot(searchQuery);
          adminUsersModel.users.refresh({
            [searchBy === 'email' ? 'emails.email' : 'name']: !!searchQuery ? `/${sanitizedSearchQuery}/${searchBy === 'email' ? 'i' : 'gi'}` : null,
          });
        }, 300),
      );
    }
  }, [searchQuery, successfulDelete, searchBy]);

  const onFilterChange = useCallback(
    (fltr: IUserFilter) => {
      filterEngaged.current = true;
      setFilter(fltr);
    },
    [filter],
  );

  const onSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
    },
    [searchQuery],
  );

  const onUserClick = useCallback(
    (user: UserModel) => () => {
      // eslint-disable-next-line no-console
      console.log('user clicked', user);
    },
    [],
  );

  const handleSearchByChange = (selected: { id: string }) => {
    setSearchBy(selected.id as SearchByOption);
  };

  const searchByOptions: ISearchByButton[] = [
    { id: 'name', label: 'Name', checked: searchBy === 'name' },
    { id: 'email', label: 'Email', checked: searchBy === 'email' },
  ];

  const searchByButtons = useMemo(() => (
    <SearchByContainer>
      <Label>Search By:</Label>
      <RadioGroup
        name='searchOption'
        radioButtons={ searchByOptions }
        stack={ RadioGroupStack.Horizontal }
        onSelectionChange={ handleSearchByChange }
      />
    </SearchByContainer>
  ), [searchBy, searchByOptions, handleSearchByChange]);

  const renderSummary = () => <div>summary</div>;

  const setSuccessfulUserDeleteState = (toasterMessage: string) => {
    setSuccessfulDelete(true);
    setUserToDelete(null);
    toaster.push({ message: toasterMessage });
    analytics.fireEvent('UserDeleteSuccess');
    setTimeout(() => {
      setSuccessfulDelete(false);
    }, 800);
  };

  const setUnsuccessfulUserDeleteState = async (err: Error) => {
    setSuccessfulDelete(false);
    errorMessages.push({
      title: 'Error deleting user',
      message: err.message,
    });
    analytics.fireEvent('UserDeleteError');
  };

  const onCancelDelete = useCallback(() => {
    setShowDeleteUserModal(false);
  }, []);

  const onCloseDepositModal = useCallback(() => {
    setShowDepositModal(false);
  }, []);
  
  const onDeleteConfirmation = useCallback(async () => {
    analytics.fireEvent('UserDeleteClick');
    try {
      if (!userToDelete) throw new Error('No user found');
      setShowDeleteUserModal(false);
      await adminUsersModel.delete(userToDelete._id);
      const email = userToDelete.primaryEmail;
      const message = `${!!email?.email ? email?.email : userToDelete._id.toString()} deleted successfully`;
      setSuccessfulUserDeleteState(message);
    } catch (err: any) {
      setUnsuccessfulUserDeleteState(err);
    }
  }, [userToDelete]);

  const onUserDeleteClick = useCallback(
    (user: UserModel) => async () => {
      setUserToDelete(user);
      setShowDeleteUserModal(true);
    },
    [],
  );
  
  const onGPADepositClick = useCallback(
    (user: UserModel) => async () => {
      await user.loadGroups();
      setUserToFund(user);
      setShowDepositModal(true);
    }, []);

  const setSuccessfulUserUnlockState = (toasterMessage: string) => {
    setSuccessfulUnlock(true);
    setUserToUnlock(null);
    toaster.push({ message: toasterMessage });
    analytics.fireEvent('UserDeleteSuccess');
    setTimeout(() => {
      setSuccessfulUnlock(false);
    }, 800);
  };

  const setUnsuccessfulUserUnlockState = async (err: Error) => {
    setSuccessfulUnlock(false);
    errorMessages.push({
      title: 'Error deleting user',
      message: err.message,
    });
    analytics.fireEvent('UserDeleteError');
  };

  const onUserUnlockClick = useCallback(
    (user: UserModel) => async () => {
      analytics.fireEvent('UserUnlockClick');
      setUserToUnlock(user);
      try {
        await adminUsersModel.unlockAccount(user._id);
        const email = user.primaryEmail;
        const message = `User account for: ${
          !!email?.email ? email?.email : user._id.toString()
        } unlocked successfully`;
        setSuccessfulUserUnlockState(message);
      } catch (err: any) {
        setUnsuccessfulUserUnlockState(err);
      }
    },
    [],
  );

  const renderUsers = () => {
    let users: JSX.Element[] = [];

    if (adminUsersModel.users.results.length > 0) {
      const displayDeleteUserButton = userSession.role === UserRoles.SuperAdmin;
      const displayDepositButton = userSession.role === UserRoles.Admin || userSession.role === UserRoles.SuperAdmin;
      const userHasActiveMarqetaAccount = (user: UserModel) => user.marqetaStatus === MarqetaUserStatus.ACTIVE || user.marqetaStatus === MarqetaUserStatus.LIMITED;

      const rightAccessoryContent = (user: UserModel) => (
        <div className='user-actions'>
          {
            displayDepositButton && userHasActiveMarqetaAccount(user) && (
              <Button
                onClick={ onGPADepositClick(user) }
                kind= { ButtonKind.Primary }
              >
                GPA Deposit
              </Button>
            )
          }
          {
            displayDeleteUserButton && (
              <>
                <Button
                  onClick={ onUserUnlockClick(user) }
                  disabled={ !user.accountIsLocked || (successfulUnlock && user._id === userToUnlock?._id) }
                  kind={ ButtonKind.Secondary }
                >
                  Unlock
                </Button>
                <Button
                  onClick={ onUserDeleteClick(user) }
                  disabled={ successfulDelete }
                  kind={ ButtonKind.Danger }
                >
                  Delete
                </Button>
              </>
            )
          }
        </div>
      );

      users = adminUsersModel.users.results.map((user) => (
        <UserListItem
          key={ user._id }
          className='user-item'
          user={ user }
          onClick={ onUserClick(user) }
          rightAccessory={ rightAccessoryContent(user) }
        />
      ));
    } else {
      users.push(
        <div key='no-users' className='no-users'>
          No users found
        </div>,
      );
    }

    if (adminUsersModel.users.busy) {
      if (adminUsersModel.users.firstPageLoaded) {
        users.push(<div key='loading-users-spinner'>loading more...</div>);
      } else {
        const skeletons: JSX.Element[] = [];

        for (let i = 0; i < 25; i++) {
          skeletons.push(<UserListItemSkeleton key={ `user-item-skele-${i}` } />);
        }

        users = [...users, ...skeletons];
      }
    }

    if (!adminUsersModel.users.allResultsFetched && !adminUsersModel.users.busy) {
      users.push(<Waypoint key='waypoint' onEnter={ loadMore } topOffset={ 200 } />);
      users.push(<div key='waypoint-support' className='waypoint-support' />);
    }

    return users;
  };

  return (
    <UsersContainer className={ className } title='Users'>
      <H1>Users</H1>
      <SummaryContainer>{ renderSummary() }</SummaryContainer>
      <MainContainer>
        <UsersFilter className='users-filter' onChange={ onFilterChange } />
        <UsersListContainer>
          { !!searchQuery && searchByButtons }
          <SearchContainer>
            <TextField
              fieldKind={ TextFieldKind.Pill }
              id='users-search-input'
              label='Search Users'
              labelHidden
              onChange={ onSearchChange }
              placeholder='Search Users'
              value={ searchQuery }
            />
          </SearchContainer>
          <UsersInfoContainer>{ adminUsersModel.users.total } Users</UsersInfoContainer>
          <UsersList>{ renderUsers() }</UsersList>
          <DeleteUserConfirmationModal
            user={ userToDelete }
            isOpen={ showDeleteUserModal }
            onDelete={ onDeleteConfirmation }
            onClose={ onCancelDelete }
          />
          <GPADepositModal
            user={ userToFund }
            isOpen={ showDepositModal }
            onClose={ onCloseDepositModal }
          />
        </UsersListContainer>
      </MainContainer>
    </UsersContainer>
  );
};

export const Users = observer(UsersBase);
