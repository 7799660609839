import React, { forwardRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { CompanyLogoSkeleton } from '../../../../components/CompanyLogo/skeleton';
import { CompanyItemSkeletonContainer } from './styles';

interface IProps {
  className?: string;
}

export const CompanyItemSkeleton = forwardRef<HTMLButtonElement, IProps>((
  {
    className = '',
  },
  ref,
) => (
  <CompanyItemSkeletonContainer ref={ ref } className={ className }>
    <div className='base-info-container'>
      <CompanyLogoSkeleton className='company-logo' />
      <div>
        <Skeleton className='company-name' />
        <Skeleton className='company-info' count={ 2 } />
      </div>
    </div>
    <div>
      <Skeleton circle className='company-rating' />
    </div>
  </CompanyItemSkeletonContainer>
));
