import React, { useCallback, useState } from 'react';
import { CommissionPayoutOverviewItemContainer, CommissionPayoutsOverviewsRowItem } from './styles';
import { Checkbox } from '../../../../components/Checkbox';
import dayjs from 'dayjs';
import { CommissionsModel, ICommissionPayoutOverview, KarmaCommissionPayoutOverviewStatus } from '../../../../models/commisions';
import { observer } from 'mobx-react';
import { useErrorMessages } from '../../../../contexts/error-messages-store';
import { useToaster } from '../../../../contexts/toaster-store';
import { Button } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';

interface IProps {
  className?: string;
  commissionPayoutOverviewItem: ICommissionPayoutOverview;
  commissionsModel: CommissionsModel;
}

const CommissionPayoutOverviewItemBase: React.FC<IProps> = ({
  className = '',
  commissionPayoutOverviewItem,
  commissionsModel,
}) => {
  const errorMessages = useErrorMessages();
  const toasterMessages = useToaster();
  const { payoutDate, amount, breakdown, status, disbursementBreakdown } = commissionPayoutOverviewItem;
  const { karma, wildfire, kard } = breakdown;

  const [isVerified, setIsVerified] = useState(commissionPayoutOverviewItem.status === 'verified');

  const payCommissionPayoutOverview = async () => {
    try {
      const response: string = await commissionsModel.payCommissionPayoutOverview(commissionPayoutOverviewItem._id);
      toasterMessages.push({
        message: response,
      });
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Paying Commission Payout Overview',
        message: err.message,
      });
    }
  };

  const onClick = useCallback(() => {
    if (!commissionPayoutOverviewItem._id) return;
    payCommissionPayoutOverview();
  }, [commissionPayoutOverviewItem]);

  const updateStatus = useCallback(async(verifiedStatus: boolean) => {
    try {
      let status;
      if (!!verifiedStatus) {
        status = KarmaCommissionPayoutOverviewStatus.Verified;
      } else {
        status = KarmaCommissionPayoutOverviewStatus.AwaitingVerification;
      }

      await commissionsModel.updateCommissionPayoutOverviewStatus(commissionPayoutOverviewItem._id, status, true);
      toasterMessages.push({
        message: 'Successfully updated the status of this commission payout overview',
      });
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Updating Status of Commission Payout Overview',
        message: err.message,
      });
    }
  }, [commissionsModel]);

  const onCheckboxChange = useCallback(() => {
    setIsVerified(!isVerified);
    updateStatus(!isVerified);
  }, [isVerified]);

  return (
    <CommissionPayoutOverviewItemContainer className={ className }>
      <CommissionPayoutsOverviewsRowItem className='date'>Payout Date: { dayjs(payoutDate).format('MM/DD/YYYY') }</CommissionPayoutsOverviewsRowItem>
      <CommissionPayoutsOverviewsRowItem className='total-cashback-owed'>Total Payout Amount: ${ amount.toFixed(2) }</CommissionPayoutsOverviewsRowItem>
      <CommissionPayoutsOverviewsRowItem className='karma-cashback-owed'>Karma Wallet Cashback Total: ${ karma.toFixed(2) }</CommissionPayoutsOverviewsRowItem>
      <CommissionPayoutsOverviewsRowItem className='wildfire-cashback-owed'>Wildfire Casback Total: ${ wildfire.toFixed(2) }</CommissionPayoutsOverviewsRowItem>
      <CommissionPayoutsOverviewsRowItem className='wildfire-cashback-owed'>Kard Casback Total: ${ kard?.toFixed(2) || '0.00' }</CommissionPayoutsOverviewsRowItem>
      <CommissionPayoutsOverviewsRowItem className='status'>Payout Status: { status }</CommissionPayoutsOverviewsRowItem>
      {
        !!disbursementBreakdown 
          ? (
            <div className='disbursement-breakdown'>
              <CommissionPayoutsOverviewsRowItem className='disbursement'>
                Disbursement Breakdown:
              </CommissionPayoutsOverviewsRowItem>
              <ul>
                <li className='marqeta-disbursement-amount'>
                  Total Marqeta: { disbursementBreakdown?.marqeta !== undefined ? '$' + disbursementBreakdown?.marqeta.toFixed(2) : 'undetermined' }
                </li>
                <li className='donate-disbursement-amount'>
                  Total Donate: { disbursementBreakdown?.donate !== undefined ? '$' + disbursementBreakdown?.donate.toFixed(2) : 'undetermined' }
                </li>
              </ul>
            </div>
          )
          : null
      }
      <Checkbox 
        className='verify-checkbox'
        label='Verified (select if payout amount has been verified and cash is in appropriate account)'
        checked={ isVerified }
        disabled={ status === KarmaCommissionPayoutOverviewStatus.Sent || status === KarmaCommissionPayoutOverviewStatus.Success }
        onChange={ onCheckboxChange }
      />
      <Button 
        className='pay-cta'
        onClick={ onClick }
        kind={ ButtonKind.Primary }
        disabled={ status !== KarmaCommissionPayoutOverviewStatus.Verified }
      >
        Pay Commission Payout Overview
      </Button>
    </CommissionPayoutOverviewItemContainer>
  );
};

export const CommissionPayoutOverviewItem = observer(CommissionPayoutOverviewItemBase);
