import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { ButtonKind } from '../../Button/styles';
import { Dropdown, IDropdownOption } from '../../Dropdown';
import { TextField } from '../../TextField';
import { GroupSelectionContainer, GPADepositModalContainer, DepositTypeContainer, FieldsContainer } from './styles';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { UserModel } from '../../../models/users';
import { IInitiateGPADepositsRequest, TransactionSubtypeEnum } from '../../../models/gpaDeposits/types';
import { createGPADeposit } from '../../../models/gpaDeposits/api';
import { DollarIcon } from '../../svgs/icons/DollarIcon';
import { useToaster } from '../../../contexts/toaster-store';
import { PencilIcon } from '../../svgs/icons/PencilIcon';
import { theme } from '../../../styles/themes';

interface IProps {
  isOpen: boolean;
  user: UserModel;
  onClose: () => void;
}

const transactionSubtypes: Record<string, TransactionSubtypeEnum> = {
  'Program Credit': TransactionSubtypeEnum.ProgramCredit,
  Employer: TransactionSubtypeEnum.Employer,
  Cashback: TransactionSubtypeEnum.Cashback,
  Refund: TransactionSubtypeEnum.Refund,
};

const GPADepositModalBase: React.FC<IProps> = ({
  isOpen,
  user,
  onClose,
}) => {
  const userGroups = user?.groups || [];
  const depositTypeOptions: IDropdownOption<TransactionSubtypeEnum>[] = [
    {
      id: 'select-option',
      text: 'Select Option',
      context: null,
      isPlaceholder: true,
    },
    ...Object.entries(transactionSubtypes).map(([key, value]) => ({
      id: `transaction-subtype-${value}`,
      text: key,
      context: value,
      disabled: value === TransactionSubtypeEnum.Cashback ||
                value === TransactionSubtypeEnum.Refund ||
                (value === TransactionSubtypeEnum.Employer && !userGroups.length),
    })),
  ];
  const toaster = useToaster();
  const errorMessages = useErrorMessages();
  const [memo, setMemo] = useState('');
  const [depositAmount, setDepositAmount] = useState<any>(0);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [depositType, setDepositType] = useState(depositTypeOptions[0]);
  const [loading, setLoading] = useState(false);

  const resetFields = () => {
    setMemo('');
    setDepositAmount(0);
    setSelectedGroup(null);
    setDepositType(depositTypeOptions[0]);
  };

  const closeModal = useCallback(() => {
    onClose();
    resetFields();
  }, [onClose, resetFields]);

  const onMemoChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMemo(e.target.value);
  }, []);

  const onGroupChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedGroup(e.target.value);
  }, []);

  const onKeyDownHandler = (event: React.KeyboardEvent) => {
    if (event.key === '.' || event.key === 'e') event.preventDefault();
  };

  const onDepositAmountChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/^0+(?=\d)/, '');

    if (inputValue === '') {
      setDepositAmount('');
      return;
    }
    
    const numericValue = parseFloat(inputValue);

    if (numericValue >= 0 && numericValue <= 2500 && !isNaN(numericValue)) {
      setDepositAmount(numericValue.toFixed());
    }
  }, []);

  const onInitiateClick = useCallback(async () => {
    setLoading(true);

    const reqData: IInitiateGPADepositsRequest = {
      type: depositType.context,
      memo,
      gpaDeposits: [
        {
          amount: Number(depositAmount),
          userId: user?._id,
        },
      ],
      ...(depositType.context === TransactionSubtypeEnum.Employer && { groupId: selectedGroup }),
    };

    try {
      const response = await createGPADeposit(reqData);
      if (response?.success) {
        toaster.push({ 
          message: 'Deposit initiated successfully.',
        });
        closeModal();
      } else {
        errorMessages.push({
          title: 'Error initiating deposit.',
          message: response?.error || 'Error',
        });
      }
    } catch (err) {
      errorMessages.push({
        title: 'Error initiating deposit.',
        message: err instanceof Error ? err.message : 'Error',
      });
      closeModal();
    } finally {
      setLoading(false);
    }
  }, [depositAmount, depositType.context, user, selectedGroup, memo]);

  const ctas = useMemo(() => {
    const isInitiateDisabled = (
      (depositType.context === TransactionSubtypeEnum.Employer && !selectedGroup) ||
      !parseFloat(depositAmount) ||
      depositType.context === null
    );

    return [
      {
        id: 'gpa-deposit-modal-close',
        text: 'Cancel',
        kind: ButtonKind.PrimaryGhost,
        onClick: closeModal,
      },
      {
        id: 'gpa-deposit-modal-initiate',
        text: 'Initiate Deposit',
        kind: ButtonKind.Primary,
        onClick: onInitiateClick,
        disabled: isInitiateDisabled,
      },
    ];
  }, [depositAmount, depositType.context, selectedGroup, closeModal, onInitiateClick]);
  
  const groupSelection = useMemo(() => (
    <GroupSelectionContainer>
      <p className='user-info bold'>Select Group:</p>
      {
        user?.groups.map(({ _id, group: { _id: groupId, name } }) => (
          <div key={ _id }>
            <input
              type='radio'
              id={ `group-${groupId}` }
              name='group'
              value={ groupId }
              checked={ selectedGroup === groupId }
              onChange={ onGroupChange }
              aria-labelledby={ `label-${groupId}` }
            />
            <label id={ `label-${groupId}` } htmlFor={ `group-${groupId}` }>
              { name }
            </label>
          </div>
        ))
      }
    </GroupSelectionContainer>
  ), [user, selectedGroup, onGroupChange]);
  
  return (
    <GPADepositModalContainer
      isOpen={ isOpen }
      onClose={ closeModal }
      title={ 'GPA Deposit' }
      ctas={ ctas }
      processing={ loading }
    >
      <p className='user-info'><span className='bold'>Name: </span>{ user?.name }</p>
      <p className='user-info'><span className='bold'>Email: </span>{ user?.primaryEmail?.email }</p>
      <p className='deposit bold'>Choose a deposit type:</p>
      <DepositTypeContainer id='deposit-type-dropdown'>
        <Dropdown<TransactionSubtypeEnum>
          options={ depositTypeOptions }
          selectedOption={ depositType }
          onOptionClick={ setDepositType }
        />
      </DepositTypeContainer>
      { (depositType.context === TransactionSubtypeEnum.Employer && !!userGroups) && groupSelection }
      <FieldsContainer>
        <TextField
          leftAccessory={ <DollarIcon fill={ theme.colors.primary } /> }
          id='deposit-amount-value'
          label='Amount in whole dollars'
          type='number'
          value={ depositAmount }
          onChange={ onDepositAmountChange }
          onKeyDown={ onKeyDownHandler }
        />
        <TextField
          leftAccessory={ <PencilIcon stroke={ theme.colors.primary } /> }
          id='memo-value'
          label='Memo (optional)'
          value={ memo }
          onChange={ onMemoChange }
          maxLength={ 99 }
          placeholder='Enter up to 99 characters.'
        />
      </FieldsContainer>
    </GPADepositModalContainer>
  );
};

export const GPADepositModal = observer(GPADepositModalBase);
