import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { BottomSection, ImageUploaderWrapper, UploadImageContainer } from './styles';
import { FileUploaderModel, ResourceTypes } from '../../../models/fileUploader';
import { TextField } from '../../../components/TextField';
import { ImageUploader } from '../../../components/ImageUploader';
import { Button } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { H1, H3 } from '../../../styles/components/header';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { useToaster } from '../../../contexts/toaster-store';

interface IProps {
  className?: string;
}

export const UploadImage: React.FC<IProps> = ({
  className = '',
}) => {
  const errorMessage = useErrorMessages();
  const toaster = useToaster();
  const fileUploader = useRef(new FileUploaderModel('karma')).current;
  const [imageUrl, setImageUrl] = useState('');
  const [fileToUpload, setFileToUpload] = useState<File>(null);
  const [previewImg, setPreviewImg] = useState('');
  const [newImage, setNewImage] = useState(false);
  const [s3Url, setS3Url] = useState('');
  const [imageName, setImageName] = useState('');

  // const renderImage = useCallback(() => {
  //   if (!!previewImg) return <img src={ previewImg } alt='preview' />;
  // }, []);

  const onImageUrlChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setImageUrl(e.target.value);
    setPreviewImg(e.target.value);
  }, []);

  const onSaveImageClick = async () => {
    const config = {
      resourceType: ResourceTypes.Karma,
      resourceId: nanoid(12),
    };

    if (!!fileToUpload) {
      try {
        const res: any = await fileUploader.uploadImage(fileToUpload, config);
        setS3Url(res.url);
        toaster.push({
          message: 'Image uploaded successfully',
        });
      } catch (err: any) {
        errorMessage.push({
          message: err,
          title: 'Error Uploading Image' ,
        });
      }
    }

    if (!!imageUrl) {
      try {
        const res: any = await fileUploader.uploadImageFromUrl(imageUrl, nanoid(12), imageName);
        setS3Url(res.url);
        toaster.push({
          message: 'Image uploaded successfully',
        });
        setImageName('');
      } catch (err: any) {
        errorMessage.push({
          message: err,
          title: 'Error Uploading Image' ,
        });
      }
    }
  };

  const onImageNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setImageName(e.target.value);
  }, []);

  return (
    <UploadImageContainer 
      className={ className }
      title='Upload Image'
    > 
      <H1 className='header'>Image Uploader</H1>
      { /* { renderImage() } */ }
      <p>The Image Uploader uploads images to our S3 storage bucket. When using images in emails or on our website please upload them here first. To get started use the image picker or add a url to an image in the Image Url field.</p>
      <ImageUploaderWrapper>
        <H3>Option 1:</H3>
        <p>Upload an image using the image picker.</p>
        <ImageUploader 
          className='image-uploader'
          allowEditing={ true }
          setNewImage={ setNewImage }
          setFileToUpload={ setFileToUpload }
          setPreviewImg={ setPreviewImg }
          previewImg={ previewImg }
          setImageUrl={ setImageUrl }
        />
      </ImageUploaderWrapper>
      <ImageUploaderWrapper>
        <H3>Option 2:</H3>
        <p>Upload an image from a url.</p>
        <TextField
          className='text-field'
          id='image-url-field'
          label='Image Url'
          onChange={ onImageUrlChange }
          placeholder='Enter an image url to preview'
          value={ imageUrl }
        />
        <p>Please make the image name without space: ex: <i>analytics_icon</i>.</p>
        <TextField 
          className='text-field'
          id='file-name'
          label='Image Name'
          placeholder='Enter a name for the image'
          value={ imageName }
          onChange={ onImageNameChange }
        />
      </ImageUploaderWrapper>
      <BottomSection>
        <Button 
          className='save-image'
          kind={ ButtonKind.Primary }
          onClick={ onSaveImageClick }
          disabled={ (!newImage && !imageUrl) || (!!imageUrl && !imageName) }
        >
          Save Image to S3
        </Button>
        {
          !!s3Url ? (
            <div className='success-text'>Success! Here is your image url: { s3Url }</div>
          )
            : null
        }
      </BottomSection>
    </UploadImageContainer>
  );
}; 
