import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { withTheme } from 'styled-components';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { IUnlinkedOrRemovedChartData } from '../../../models/chart';
import { ReportModel, UnlinkedOrRemovedAccountReportChart } from '../../../models/reports';
import { IThemeProps } from '../../../styles/themes';
import { LoadingSpinner } from '../../loading/LoadingSpinner';
import { AccountsUnlinkedOrRemovedReportContainer, ChartContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  report: ReportModel;
}

const AccountsUnlinkedOrRemovedReportBase: React.FC<IProps> = ({ className = '', report, theme }) => {
  const errorMessages = useErrorMessages();
  const [data, setData] = useState<IUnlinkedOrRemovedChartData[]>([]);

  useEffect(() => {
    report
      .load()
      .then(() => {
        setData((report.chart as UnlinkedOrRemovedAccountReportChart)?.data);
      })
      .catch((err) => {
        errorMessages.push({
          title: 'Error Loading Report',
          message: err.message,
        });
      });
  }, []);

  const renderChart = () => {
    if (report.busy || !data) {
      return <LoadingSpinner />;
    }

    return (
      <ResponsiveContainer width='100%' height={ 200 }>
        <ComposedChart
          width={ 500 }
          height={ 200 }
          data={ data }
          margin={ {
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          } }
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='label' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey='unlinked' stackId='a' barSize={ 20 } fill={ theme.colors.lightGreen1 } />
          <Bar dataKey='removed' stackId='a' barSize={ 20 } fill={ theme.colors.lightBlue1 } />
          <Line type='monotone' dataKey='total' fill={ theme.colors.lightDanger } stroke={ theme.colors.lightDanger } />
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  return (
    <AccountsUnlinkedOrRemovedReportContainer className={ className }>
      <ChartContainer>{ renderChart() }</ChartContainer>
      { !!report.lastUpdated && (
        <p className='last-updated'>last updated { report.lastUpdated.format('MMM DD, YYYY @ HH:MM A') }</p>
      ) }
    </AccountsUnlinkedOrRemovedReportContainer>
  );
};

const AccountsUnlinkedOrRemovedReportAsObserver = observer(AccountsUnlinkedOrRemovedReportBase);
export const AccountsUnlinkedOrRemovedReport = withTheme(AccountsUnlinkedOrRemovedReportAsObserver);
