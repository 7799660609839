import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { IDropdownOption } from '../../../components/Dropdown';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { SpinnerSize } from '../../../components/loading/LoadingSpinner/styles';
import { MainAdminContainer } from '../../../components/MainAdminContainer';
import { TextField, TextFieldKind } from '../../../components/TextField';
import { UserListItem } from '../../../components/UserListItem';
import { UserListItemSkeleton } from '../../../components/UserListItem/skeleton';
import { defaultUsersFilter, IUserFilter, UsersFilter } from '../../../components/UsersFilter';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { useToaster } from '../../../contexts/toaster-store';
import { useUserSession } from '../../../contexts/user';
import { AccessControlModel } from '../../../models/access-control';
import { UserModel, UserRoles } from '../../../models/users';
import { H1, H5 } from '../../../styles/components/header';
import { AccessControlContainer, AssignableRolesDropdown, MainContainer, SearchContainer, SummaryContainer, SummaryItem, UsersInfoContainer, UsersList, UsersListContainer } from './styles';

interface IRoleContext {
  role: UserRoles;
}

interface IProps {
  className?: string;
}

const AccessControlBase: React.FC<IProps> = ({
  className = '',
}) => {
  const userSession = useUserSession();
  const errorMessages = useErrorMessages();
  const toaster = useToaster();
  const accessControlModel = useRef(new AccessControlModel()).current;
  const filterEngaged = useRef(false);
  const [usersSearchQuery, setUsersSearchQuery] = useState('');
  const [searchThrottle, setSearchThrottle] = useState<number>(null);
  const [usersFilter, setUsersFilter] = useState(defaultUsersFilter);

  const loadMore = () => {
    accessControlModel.users.loadMore({
      name: !!usersSearchQuery ? `/${usersSearchQuery}/gi` : null,
      ...usersFilter,
    })
      .catch(err => {
        errorMessages.push({
          title: 'Error Getting Users for Access Control',
          message: err,
        });
      });
  };

  useEffect(() => {
    accessControlModel.getSummary()
      .catch(err => {
        errorMessages.push({
          title: 'Error Getting Access Control Summary',
          message: err,
        });
      });

    if (!accessControlModel.users.firstPageLoaded) {
      loadMore();
    }

    accessControlModel.getAssignableRoles()
      .catch(err => {
        errorMessages.push({
          title: 'Error Getting Assignable Roles',
          message: err,
        });
      });
  }, []);

  useEffect(() => {
    window.clearTimeout(searchThrottle);

    if (accessControlModel.users.firstPageLoaded) {
      setSearchThrottle(window.setTimeout(() => {
        accessControlModel.users.refresh({ name: !!usersSearchQuery ? `/${usersSearchQuery}/gi` : null });
      }, 300));
    }
  }, [usersSearchQuery]);

  useEffect(() => {
    if (filterEngaged.current) {
      accessControlModel.users.refresh({
        name: !!usersSearchQuery ? `/${usersSearchQuery}/gi` : null,
        ...usersFilter,
      });
    }
  }, [usersFilter]);

  const onFilterChange = (filter: IUserFilter) => {
    filterEngaged.current = true;
    setUsersFilter(filter);
  };

  const onRoleChange = (user: UserModel) => async (option: IDropdownOption<IRoleContext>) => {
    try {
      await accessControlModel.updateUserRole(user._id, option.context.role);

      toaster.push({
        message: 'User Role Updated Successfully',
      });
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Updating User Role',
        message: err,
      });
    }
  };

  const onUsersSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUsersSearchQuery(e.target.value);
  }, []);

  const renderAssignableRolesDropdown = (user: UserModel) => {
    if (user._id === userSession._id) return <div>{ user.role }</div>;

    if (userSession.role === UserRoles.Admin) {
      // admins are not allowed to change the roles of other admins or superadmins
      if (user.role === UserRoles.Admin || user.role === UserRoles.SuperAdmin) {
        return <div>{ user.role }</div>;
      }
    }

    const options: IDropdownOption<IRoleContext>[] = accessControlModel.assignableRoles.map(role => ({
      id: `assignable-role-${role}`,
      text: role,
      context: { role },
      disabled: user.role === role,
    }));

    return (
      <AssignableRolesDropdown
        options={ options }
        selectedOption={ options.find(o => o.context.role === user.role) ?? options[0] }
        onOptionClick={ onRoleChange(user) }
      />
    );
  };

  const renderSummary = () => {
    if (accessControlModel.gettingSummary) return <LoadingSpinner />;

    return [
      {
        header: 'Total Members',
        count: accessControlModel.summary.totalMembers,
      },
      {
        header: 'Total Admin',
        count: accessControlModel.summary.totalAdmin,
      },
      {
        header: 'Total Super Admin',
        count: accessControlModel.summary.totalSuperAdmin,
      },
    ].map(({ header, count }) => (
      <SummaryItem key={ header.split(' ').join('-') }>
        <H5>{ header }</H5>
        <div>{ count }</div>
      </SummaryItem>
    ));
  };

  const renderUsers = () => {
    let users: JSX.Element[] = [];

    if (accessControlModel.users.results.length > 0) {
      users = accessControlModel.users.results.map(user => (
        <UserListItem
          key={ user._id }
          rightAccessory={
            accessControlModel.gettingAssignableRoles || accessControlModel.updatingUserRole === user._id
              ? <LoadingSpinner size={ SpinnerSize.Small } />
              : renderAssignableRolesDropdown(user)
          }
          user={ user }
        />
      ));
    } else {
      users.push(<div key='no-users' className='no-users'>No users found</div>);
    }

    if (accessControlModel.users.busy) {
      if (accessControlModel.users.firstPageLoaded) {
        users.push((
          <div key='loading-users-spinner'>
            loading more...
          </div>
        ));
      } else {
        const skeletons: JSX.Element[] = [];

        for (let i = 0; i < 25; i++) {
          skeletons.push(<UserListItemSkeleton key={ `user-item-skele-${i}` } />);
        }

        users = [...users, ...skeletons];
      }
    }

    if (!accessControlModel.users.allResultsFetched && !accessControlModel.users.busy) {
      users.push(<Waypoint key='waypoint' onEnter={ loadMore } topOffset={ 200 } />);
    }

    return users;
  };

  return (
    <MainAdminContainer title='Access Control'>
      <AccessControlContainer className={ className }>
        <H1>Access Control</H1>
        <SummaryContainer>
          { renderSummary() }
        </SummaryContainer>
        <MainContainer>
          <UsersFilter
            className='users-filter'
            onChange={ onFilterChange }
          />
          <UsersListContainer>
            <SearchContainer>
              <TextField
                fieldKind={ TextFieldKind.Pill }
                id='users-search-input'
                label='Search Karma Users'
                labelHidden
                onChange={ onUsersSearchChange }
                placeholder='Search Karma Users'
                value={ usersSearchQuery }
              />
            </SearchContainer>
            <UsersInfoContainer>
              { accessControlModel.users.total } Users
            </UsersInfoContainer>
            <UsersList>
              { renderUsers() }
            </UsersList>
          </UsersListContainer>
        </MainContainer>
      </AccessControlContainer>
    </MainAdminContainer>
  );
};

export const AccessControl = observer(AccessControlBase);
