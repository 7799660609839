import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { SectorItemSkeletonContainer } from './styles';

interface IProps {
  className?: string;
}

export const SectorItemSkeleton: React.FC<IProps> = ({
  className = '',
}) => (
  <SectorItemSkeletonContainer className={ className }>
    <Skeleton className='sector-tier' />
    <Skeleton className='sector-name' />
    <Skeleton className='sector-carbon-multiplier' />
  </SectorItemSkeletonContainer>
);
