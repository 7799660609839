import styled from 'styled-components';
import { CustomModal } from '../../../../components/modals/CustomModal';
import { FlexCol, FlexRow } from '../../../../styles/styles';

export const CreateLegalTextModalContainer = styled(CustomModal)`
  ${ FlexCol }
  overflow: hidden;
  width: 75vw;

  .text-field {
    margin-top: 30px;

    label {
      color: ${({ theme }) => theme.colors.black};
      margin-bottom: 10px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .custom-modal-body {
    overflow: auto;
    overflow-x: hidden;
  }
`;

export const CTAContainer = styled.div`
  ${ FlexRow }
  gap: 20px;
  margin-top: 20px;
  width: 100%;
`;
