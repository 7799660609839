import styled from 'styled-components';
import { MainAdminContainer } from '../../../components/MainAdminContainer';
import { BasicContainer, FlexCenter, FlexCol, FlexHorizontalCenter, NoScrollBar } from '../../../styles/styles';

export const GroupsContainer = styled(MainAdminContainer)``;

export const GroupsInfoContainer = styled.div`
  ${ FlexHorizontalCenter }
  justify-content: space-between;
  padding: 0 20px 10px;
`;

export const GroupsList = styled.div`
  ${ NoScrollBar }
  flex-grow: 1;
  max-height: calc(100% - 100px);
  overflow: auto;

  .group-item {
    margin-bottom: 10px;
  }

  .no-groups {
    ${ FlexCenter }
    padding: 30px 0;
    color: ${({ theme }) => theme.colors.lightGray1};
  }
`;

export const GroupsListContainer = styled.div`
  ${ FlexCol }
  flex-grow: 1;
  min-height: 100%;
  max-height: 100%;
  margin-left: 20px;
  padding-bottom: 10px;
  overflow: hidden;
`;

export const MainContainer = styled.div`
  ${ FlexHorizontalCenter }
  flex-grow: 1;
  align-items: flex-start;
  height: calc(100% - 255px);
  padding-top: 20px;

  .groups-filter {
    min-height: 500px;
  }
`;

export const SearchContainer = styled.div`
  padding-bottom: 10px;
`;

export const SummaryContainer = styled.div`
  ${ BasicContainer };
  ${ FlexHorizontalCenter }
  justify-content: center;
  height: 200px;
  margin-top: 20px;
  text-align: center;
`;

export const SummaryItem = styled.div`
  margin: 20px 30px;

  div {
    font-size: 36px;
    text-align: center;
  }
`;
