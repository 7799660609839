import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { useToaster } from '../../../contexts/toaster-store';
import { IBaseSector, ISecterTiersFilter, SectorModel, SectorNameAvailability, SectorsModel } from '../../../models/sectors';
import { ButtonKind } from '../../Button/styles';
import { Dropdown, IDropdownOption } from '../../Dropdown';
import { LoadingSpinner } from '../../loading/LoadingSpinner';
import { TextField } from '../../TextField';
import { ErrorMessage, FieldContainer, SectorModalContainer, TierContainer } from './styles';

interface IProps {
  isOpen: boolean;
  sector: SectorModel;
  onClose: () => void;
  onSave(sector: SectorModel, isNew: boolean): void;
}

const buildTierDropdownOption = (tierOption: ISecterTiersFilter): IDropdownOption<ISecterTiersFilter> => {
  if (!tierOption) return;

  const str = `${tierOption.tier}`;

  return {
    id: str,
    text: str, 
    context: tierOption,
  };
};

const buildParentSectorDropdownOption = (sector: SectorModel): IDropdownOption<SectorModel> => {
  if (!sector) return;

  return {
    id: sector._id,
    text: sector.name, 
    context: sector,
  };
};

const SectorModalBase: React.FC<IProps> = ({
  isOpen,
  onClose,
  onSave,
  sector,
  ...restProps
}) => {
  const toaster = useToaster();
  const errorMessages = useErrorMessages();
  const sectorsModel = useRef(new SectorsModel()).current;
  const [nameThrottle, setNameThrottle] = useState<number>();
  const [name, setName] = useState(sector?.name ?? '');
  const [nameError, setNameError] = useState('');
  const [carbonMultiplier, setCarbonMultipler] = useState(sector?.carbonMultiplier ? `${sector.carbonMultiplier}` : '');
  const [carbonMultiplierError, setCarbonMultiplerError] = useState('');
  const [selectedTier, setSelectedTier] = useState<ISecterTiersFilter>(null);
  const [selectedParentSector, setSelectedParentSector] = useState<SectorModel>(null);
  const [nameAvailability, setNameAvailability] = useState<SectorNameAvailability>(null);

  const reset = () => {
    setNameThrottle(null);
    setName('');
    setNameError('');
    setCarbonMultipler('');
  };

  useEffect(() => {
    sectorsModel.loadFilterOptions()
      .then(() => setSelectedTier(sectorsModel.filterOptions.tiers[0]))
      .catch(err => {
        errorMessages.push({
          title: 'Error Loading Sector Filter Options',
          message: err.message,
        });
      });
  }, []);

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen]);

  useEffect(() => {
    setName(sector?.name ?? '');
    setNameAvailability(!!sector?.name ? SectorNameAvailability.Available : null);
    setCarbonMultipler(sector?.carbonMultiplier ? `${sector.carbonMultiplier}` : '');
  }, [sector]);

  useEffect(() => {
    if (selectedTier?.tier > 1) {
      sectorsModel.sectors.refresh({ tier: selectedTier.tier - 1, limit: 100 })
        .then(() => setSelectedParentSector(sectorsModel.sectors.results[0]))
        .catch(err => {
          errorMessages.push({
            title: 'Error Loading Parent Sectors',
            message: err.message,
          });
        });
    }
  }, [selectedTier]);

  const checkName = useCallback(async () => {
    try {
      const nameStatus = await sectorsModel.checkName(name);
      
      if (!nameStatus.isValid) {
        setNameError('Invalid sector name.');
      }

      if (nameStatus.available === SectorNameAvailability.Unavailable) {
        setNameError('This sector name is unavailable.');
      }

      setNameAvailability(nameStatus.available);
    } catch (err: any) {
      setNameError(err.message);
    }
  }, [name]);

  useEffect(() => {
    window.clearTimeout(nameThrottle);

    if (!!name && name !== sector?.name) {
      setNameThrottle(window.setTimeout(checkName, 300));
    }
  }, [name, sector]);

  const tierOptions = useMemo(() => {
    let options: ISecterTiersFilter[] = [];

    if (!!sectorsModel.filterOptions?.tiers) {
      options = [...sectorsModel.filterOptions.tiers];
      options.push({
        tier: options[options.length - 1].tier + 1,
        count: 0,
      });
    }

    return options.map(t => buildTierDropdownOption(t));
  }, [sectorsModel.filterOptions]);

  const shouldEnableSaveCta = useCallback(() => (
    !!name
      && !nameError
      && !sectorsModel.checkingName
      && nameAvailability === SectorNameAvailability.Available
      && (!sector?.name || sector?.name !== name)
      && !!carbonMultiplier
  )
    || (
      !!carbonMultiplier
      && (!sector?.carbonMultiplier || sector?.carbonMultiplier !== parseFloat(carbonMultiplier))
    ), [
    name,
    nameError,
    nameAvailability,
    carbonMultiplier,
    sector?.name,
    sector?.carbonMultiplier,
    sectorsModel.checkingName,
  ]);

  const isValidCarbonMultiplier = useCallback(() => {
    if (carbonMultiplier.trim() === '') {
      setCarbonMultiplerError('A carbon multiplier is required.');
      return false;
    }

    const num = parseFloat(carbonMultiplier);
    if (isNaN(num)) {
      setCarbonMultiplerError('Invalid carbon multiplier. Must be a number.');
      return false;
    }

    return true;
  }, [carbonMultiplier]);

  const onCancelClick = useCallback(() => {
    reset();
    onClose();
  }, [onClose]);

  const onCarbonMultiplierChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCarbonMultiplerError('');
    setCarbonMultipler(e.target.value.trim());
  }, [carbonMultiplier]);

  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNameError('');
    setName(e.target.value);
  }, []);

  const onParentSectorOptionClick = useCallback((option: IDropdownOption<SectorModel>) => {
    setSelectedParentSector(option.context);
  }, []);

  const onSaveClick = useCallback(async () => {
    if (!shouldEnableSaveCta() || !isValidCarbonMultiplier()) return;
    const _carbonMultiplier = parseFloat(carbonMultiplier);

    try {
      if (!!sector) {
        await sector.update({ name, carbonMultiplier: _carbonMultiplier });
  
        toaster.push({ message: 'Sector updated successfully.' });
        onSave(sector, false);
      } else {
        const data: IBaseSector = {
          name,
          carbonMultiplier: _carbonMultiplier,
          tier: selectedTier?.tier,
          icon: '',
        };

        if (!!selectedParentSector) {
          data.parentSector = selectedParentSector?._id;
        }

        const newSector = await sectorsModel.createSector(data);
        
        toaster.push({ message: 'Sector created successfully.' });
        onSave(newSector, true);
      }
    } catch (err: any) {
      errorMessages.push({
        title: `Error ${!!sector ? 'Updating' : 'Creating'} Sector`,
        message: err.message,
      });
    } 
  }, [
    sector, 
    name, 
    carbonMultiplier, 
    selectedTier, 
    selectedParentSector, 
    shouldEnableSaveCta, 
    isValidCarbonMultiplier,
  ]);

  const onTierOptionClick = useCallback((option: IDropdownOption<ISecterTiersFilter>) => {
    if (option.context.tier === 1) setSelectedParentSector(null);
    
    setSelectedTier(option.context);
  }, []);

  const renderTierOptions = () => {
    if (!selectedTier || !!sector) return null;

    if (sectorsModel.loadingFilterOptions) {
      return (
        <TierContainer>
          <LoadingSpinner />
        </TierContainer>
      );
    }

    let parentSectorsDropdown: JSX.Element;

    if (selectedTier?.tier > 1 && !!selectedParentSector) {
      const parentSectorsDropdownOptions = sectorsModel.sectors.results.map(buildParentSectorDropdownOption);

      parentSectorsDropdown = (
        <Dropdown<SectorModel>
          className='parent-sectors-dropdown'
          options={ parentSectorsDropdownOptions }
          selectedOption={ buildParentSectorDropdownOption(selectedParentSector) }
          onOptionClick={ onParentSectorOptionClick }
        />
      );
    }

    return (
      <TierContainer>
        <Dropdown<ISecterTiersFilter>
          className='tiers-dropdown'
          options={ tierOptions }
          selectedOption={ buildTierDropdownOption(selectedTier) }
          onOptionClick={ onTierOptionClick }
        />
        { parentSectorsDropdown }
      </TierContainer>
    );
  };

  const ctas = useMemo(() => ([
    {
      id: 'sector-modal-close',
      text: 'Cancel',
      kind: ButtonKind.PrimaryGhost,
      onClick: onCancelClick,
    },
    {
      id: 'sector-modal-save',
      disabled: !shouldEnableSaveCta(),
      text: !!sector ? 'Save' : 'Create',
      kind: ButtonKind.Primary,
      onClick: onSaveClick,
    },
  ]), [shouldEnableSaveCta, onSaveClick, onCancelClick, sector]);

  return (
    <SectorModalContainer
      isOpen={ isOpen }
      onClose={ onClose }
      { ...restProps }
      title={ `${!!sector ? 'Edit' : 'Create'} Sector` }
      ctas={ ctas }
    >
      <FieldContainer>
        <TextField
          id='sector-name'
          label='Sector Name'
          value={ name }
          onChange={ onNameChange }
        />
        { !!nameError && <ErrorMessage>{ nameError }</ErrorMessage> }
      </FieldContainer>
      <FieldContainer>
        <TextField
          id='sector-carbon-multiplier'
          label='Carbon Multiplier'
          value={ `${carbonMultiplier}` }
          onChange={ onCarbonMultiplierChange }
          onBlur={ isValidCarbonMultiplier }
        />
        { !!carbonMultiplierError && <ErrorMessage>{ carbonMultiplierError }</ErrorMessage> }
      </FieldContainer>
      { renderTierOptions() }
      { (sector?.busy || sectorsModel.creatingSector) && <LoadingSpinner /> }
    </SectorModalContainer>
  );
};

export const SectorModal = observer(SectorModalBase);
