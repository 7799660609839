import { computed, observable, makeObservable, action, runInAction } from 'mobx';
import { BaseModel } from './base';

type AnalyticsModelPrivateFields = '_busy' | '_analytics' | '_analyticsLocations';

export interface IWebAnalytic {
  _id?: string;
  name: string;
  location: string;
  subLocation: string;
  description: string;
}

export interface IWebAnalyticLocation {
  _id: string;
  key: string;
  value: string;
}

export class WebAnalyticsModel extends BaseModel {
  private _busy = false;
  private _analytics: IWebAnalytic[] = [];
  private _analyticsLocations: IWebAnalyticLocation[] = [];

  constructor() {
    super();
    makeObservable<WebAnalyticsModel, AnalyticsModelPrivateFields>(this, {
      _busy: observable,
      _analytics: observable,
      _analyticsLocations: observable,
      analytics: computed,
      loadWebAnalytics: action.bound,
      loadWebAnalyticsLocations: action.bound,
      delete: action.bound,
    });
  }

  get busy () { return this._busy; }
  get analytics () { return this._analytics; }
  get analyticsLocations () { return this._analyticsLocations; }

  public loadWebAnalytics = async (selected: string) => {
    runInAction(() => this._busy = true);

    const result = await this.webServiceHelper.sendRequest<any[]>({
      path: `/admin/web-analytics/${selected}`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._analytics = result.value;
        this._busy = false;
      });
    }
    
    runInAction(() => {
      this._busy = false;
    });
  };

  public loadWebAnalyticsLocations = async () => {
    runInAction(() => this._busy = true);

    const result = await this.webServiceHelper.sendRequest<any[]>({
      path: '/admin/web-analytics/locations',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._analyticsLocations = result.value;
        this._busy = false;
      });
    }
    
    runInAction(() => {
      this._busy = false;
    });
  };

  public create = async (data: IWebAnalytic, selectedPage: string) => {
    if (this._busy) return;

    const result = await this.webServiceHelper.sendRequest<any>({
      path: '/admin/web-analytics',
      method: 'POST',
      data: data,
    });

    if (result.success) {
      runInAction(() => {
        if (selectedPage === data.location) {
          this._analytics = this._analytics.concat(result.value);
        }
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public update = async (data: IWebAnalytic) => {
    if (this._busy) return;

    const result = await this.webServiceHelper.sendRequest<any>({
      path: `/admin/web-analytics/${data._id}`,
      method: 'PUT',
      data: data,
    });

    if (result.success) {
      runInAction(() => {
        this._analytics = this._analytics.map((item) => item._id === data._id ? data : item );
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  public delete = async (id: string) => {
    if (this._busy) return;

    const result = await this.webServiceHelper.sendRequest<any>({
      path: `/admin/web-analytics/${id}`,
      method: 'DELETE',
    });

    if (result.success) {
      runInAction(() => {
        this._analytics = this._analytics.filter((item) => item._id !== id);
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };
}
