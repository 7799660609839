import styled from 'styled-components';
import { BasicContainer, FlexCol } from '../../../../styles/styles';

export const CampaignItemContainer = styled.div`
  ${FlexCol}
  ${BasicContainer}
  background: ${({ theme }) => theme.colors.light};
  margin-bottom: 10px;

  button {
    display: flex;
    justify-content: flex-end;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  margin-bottom: 10px;

  .enabled-status {
    color: ${({ theme }) => theme.colors.red};
  }

  .enabled-status.active {
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const Label = styled.div`
  font-weight: bold;
  margin-right: 10px;
  width: 200px;
  min-width: 200px;
`;
