import styled from 'styled-components';
import { MainAdminContainer } from '../../../components/MainAdminContainer';

export const UploadImageContainer = styled(MainAdminContainer)`
  .header, p {
    margin-bottom: 20px;
  }
`;

export const BottomSection = styled.div`
  margin-bottom: 80px;

  .success-text {
    margin-top: 20px;
    color: ${({ theme }) => theme.colors.success};
  }
`;

export const ImageUploaderWrapper = styled.div`
  margin-bottom: 40px;

  h3 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }
`;
