import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { config, useSpring } from 'react-spring';
import { withTheme } from 'styled-components';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { useUserSession } from '../../contexts/user';
import { UserGroups } from '../../models/users';
import { IThemeProps } from '../../styles/themes';
import { Avatar } from '../Avatar';
import { AvatarSize } from '../Avatar/styles';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { ConditionalAccess } from '../ConditionalAccess';
import { KWLogo } from '../svgs/graphics/LogoWithText';
import { ChevronDirection, ChevronIcon } from '../svgs/icons/ChevronIcon';
import { LogoContainer, Nav, SideNavContainer, UserInfoButton, UserInfoContainer, UserInfoOptionsContainer } from './styles';

const paths = [
  {
    path: '/access-control',
    label: 'Access Control',
    roles: UserGroups.Admins,
  },
  // {
  //   path: '/articles',
  //   label: 'Articles',
  //   roles: UserGroups.SuperAdmins,
  // },
  {
    path: '/banners',
    label: 'Banners',
    roles: null,
  },
  {
    path: '/campaigns',
    label: 'Campaigns',
    roles: UserGroups.Admins,
  },
  {
    path: '/companies',
    label: 'Companies',
    roles: null,
  },
  {
    path: '/commissions',
    label: 'Commissions',
    roles: UserGroups.Admins,
  },
  {
    path: '/commission-payout-overviews',
    label: 'Commission Payout Overviews',
    roles: UserGroups.Admins,
  },
  {
    path: '/data',
    label: 'Data',
    roles: null,
  },
  {
    path: '/groups',
    label: 'Groups',
    roles: UserGroups.Admins,
  },
  {
    path: '/job-postings',
    label: 'Job Postings',
    roles: null,
  },
  {
    path: '/karma-card-legal',
    label: 'Karma Card Legal',
    roles: UserGroups.SuperAdmins,
  },
  {
    path: '/promos',
    label: 'Promos',
    roles: UserGroups.Admins,
  },
  {
    path: '/reports',
    label: 'Reports',
    roles: null,
  },
  {
    path: '/users',
    label: 'Users',
    roles: null,  
  },
  {
    path: '/upload-image',
    label: 'Image Uploader',
    roles: UserGroups.Admins,
  },
  {
    path: '/web-analytics',
    label: 'Web Analytics Library',
    roles: UserGroups.Admins,
  },
];

interface IProps extends IThemeProps {
  className?: string;
  side?: 'left' | 'right';
}

const userInfoOptionsFrom = {
  opacity: 0,
  transform: 'translate3d(0, 10px, 0)',
};

const userInfoOptionsTo = {
  opacity: 1,
  transform: 'translate3d(0, 0, 0)',
};

const SideNavBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userSession = useUserSession();
  const errorMessages = useErrorMessages();
  const [userInfoIsOpen, setUserInfoIsOpen] = useState(false);
  const userInfoOptionsSpring = useSpring({
    config: config.gentle,
    from: userInfoIsOpen ? userInfoOptionsFrom : userInfoOptionsTo,
    to: userInfoIsOpen ? userInfoOptionsTo : userInfoOptionsFrom,
  });

  const onSignOutClick = async () => {
    try {
      setUserInfoIsOpen(false);
      userSession.logout();
      navigate('/login');
    } catch {
      errorMessages.push({
        title: 'Error',
        message: 'Error signing out. Please try again.',
      });
    }
  };

  const onUserInfoClick = useCallback(() => setUserInfoIsOpen(!userInfoIsOpen), [userInfoIsOpen]);

  return (
    <SideNavContainer className={ className }>
      <div>
        <LogoContainer to='/'>
          <KWLogo />
        </LogoContainer>
        <Nav>
          { paths.map(({ path, label, roles }) => {
            const isActive = pathname === path;
            if (roles) {
              return (
                <ConditionalAccess key={ path } roles={ roles }>
                  <Link className={ `nav-item${isActive ? ' active' : ''}` } to={ path }>{ label }</Link>
                </ConditionalAccess>
              );
            } else {
              return (
                <Link key={ path } className={ `nav-item${isActive ? ' active' : ''}` } to={ path }>{ label }</Link>
              );
            }
          }) }
        </Nav>
      </div>

      <UserInfoContainer>
        <UserInfoButton onClick={ onUserInfoClick }>
          <div className='user-info-wrapper'>
            <Avatar size={ AvatarSize.Regular } url={ !!userSession?.avatar ? userSession.avatar : '' } />
            <div className='user-name'>{ userSession.name }</div>
          </div>
          <ChevronIcon
            direction={ userInfoIsOpen ? ChevronDirection.Down : ChevronDirection.Up }
            stroke={ theme.colors.light }
          />
        </UserInfoButton>
        {
          userInfoIsOpen && (
            <UserInfoOptionsContainer
              className={ userInfoIsOpen ? 'visible' : 'hidden' }
              style={ userInfoOptionsSpring }
              onClick={ e => e.stopPropagation() }
            >
              <Button
                className='user-option'
                kind={ ButtonKind.SecondaryWithIcon }
                onClick={ onSignOutClick }
              >
                Sign Out
              </Button>
            </UserInfoOptionsContainer>
          )
        }
      </UserInfoContainer>
    </SideNavContainer>
  );
};

const SideNavAsObserver = observer(SideNavBase);
export const SideNav = withTheme(SideNavAsObserver);
