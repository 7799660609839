import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { JobModal } from '../../../components/modals/JobModal';
import { JobPosting } from '../../../components/JobPosting';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { MainAdminContainer } from '../../../components/MainAdminContainer';
import { JobPostingModel, JobPostingsModel } from '../../../models/job-postings';
import { H1 } from '../../../styles/components/header';
import { JobPostingsContainer, JobPostingsList, NoJobs, OptionsContainer } from './styles';
import { useErrorMessages } from '../../../contexts/error-messages-store';

interface IProps {
  className?: string;
}

const JobPostingsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const errorMessages = useErrorMessages();
  const jobsModel = useRef(new JobPostingsModel()).current;
  const [jobPostingToEdit, setJobPostingToEdit] = useState<JobPostingModel>(null);

  const loadMore = () => {
    jobsModel.jobs.loadMore()
      .catch(err => {
        errorMessages.push({
          title: 'Error Loading More Jobs',
          message: err,
        });
      });
  };

  useEffect(() => {
    if (!jobsModel.jobs.firstPageLoaded) {
      loadMore();
    }
  }, []);

  const onCreateNewClick = useCallback(() => {
    setJobPostingToEdit(new JobPostingModel());
  }, []);

  const onJobPostingClick = useCallback((job: JobPostingModel) => () => {
    setJobPostingToEdit(job);
  }, []);

  const onJobSave = useCallback(async (job: JobPostingModel) => {
    if (!!job._id) {
      jobsModel.jobs.unshift(job);
    }

    setJobPostingToEdit(null);
  }, [jobPostingToEdit]);

  const onModalClose = useCallback(() => {
    setJobPostingToEdit(null);
  }, []);

  const renderJobPostings = () => {
    let jobs: JSX.Element[] = [];

    if (!!jobsModel.jobs.results.length) {
      jobs = jobsModel.jobs.results.map(job => (
        <JobPosting
          key={ job._id }
          className='job-posting'
          job={ job }
          onClick={ onJobPostingClick(job) }
        />
      ));
    } else if (!jobsModel.busy) {
      jobs.push((
        <NoJobs key='no-jobs'>No Jobs</NoJobs>
      ));
    }

    if (jobsModel.busy) jobs.push(<LoadingSpinner />);

    return (
      <JobPostingsList>
        { jobs }
      </JobPostingsList>
    );
  };

  return (
    <MainAdminContainer title='Job Postings'>
      <JobPostingsContainer className={ className }>
        <H1>Job Postings</H1>
        <OptionsContainer>
          <Button
            kind={ ButtonKind.Primary }
            onClick={ onCreateNewClick }
          >
          + Create Job Posting
          </Button>
        </OptionsContainer>
        { renderJobPostings() }
      </JobPostingsContainer>
      <JobModal
        isOpen={ !!jobPostingToEdit }
        onClose={ onModalClose }
        onSave={ onJobSave }
        job={ jobPostingToEdit }
      />
    </MainAdminContainer>
  );
};

export const JobPostings = observer(JobPostingsBase);
