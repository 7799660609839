import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { PromoModal } from '../../../components/modals/CreateUpdatePromoModal';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { PromoModel, PromosModel } from '../../../models/promos';
import { H1 } from '../../../styles/components/header';
import { PromoItem } from './PromoItem';
import { ButtonRow, PromosContainer } from './styles';

interface IProps {
  className?: string;
}

const PromosBase: React.FC<IProps> = ({
  className = '',
}) => {
  const promosModel = useRef(new PromosModel()).current;
  const errorMessages = useErrorMessages();
  const [createPromoModalOpen, setCreatePromoModalOpen] = useState(false);

  const getPromos = async () => {
    try {
      await promosModel.loadPromos(true);
      setCreatePromoModalOpen(false);
    } catch (err: any) {
      errorMessages.push({
        title: 'Error loading promos',
        message: err.message,
      });
    }
  };

  useEffect(() => {
    getPromos();
  }, []);

  const onModalClose = () => {
    getPromos();
  };
  
  const onCreateClick = () => setCreatePromoModalOpen(true);

  const currentPromos = useMemo (() => {
    if (!promosModel.promos.length) return null;

    return promosModel.promos.map((promo: PromoModel) => (
      <PromoItem 
        key={ promo._id }
        promo={ promo }
        promosModel={ promosModel }
      />
    ));
  }, [promosModel.promos]);

  return (
    <PromosContainer className={ className } title='Promos'>
      <H1>Promos</H1>
      <ButtonRow>
        <Button
          kind={ ButtonKind.Primary }
          onClick={ onCreateClick }
          className='create-promo-button'
        >
          Create Promo
        </Button>
      </ButtonRow>
      <PromoModal 
        isOpen={ createPromoModalOpen }
        onClose={ onModalClose }
        promosModel={ promosModel }
      />
      { currentPromos }
    </PromosContainer>
  );
};

export const Promos = observer(PromosBase);
