import React, { useCallback, useState } from 'react';
import { BannerItemContainer, Label, SectionContainer } from './styles';
import { Button } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';
import { PencilIcon } from '../../../../components/svgs/icons/PencilIcon';
import { BannersModel, IBanner } from '../../../../models/banners';
import { theme } from '../../../../styles/themes';
import { CreateUpdateBannerModal } from '../../../../components/modals/CreateUpdateBannerModal';
import { observer } from 'mobx-react';

interface IProps {
  className?: string;
  banner: IBanner;
  bannersModel: BannersModel;
  onClose: () => void;
}

const BannerItemBase: React.FC<IProps> = ({
  className = '',
  banner,
  onClose,
  bannersModel,
}) => {
  const { name, text, linkText, link, startDate, endDate, color, enabled } = banner;
  const [modalOpen, setModalOpen] = useState(false);
  
  const handleOpenModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const onModalClose = useCallback(() => {
    setModalOpen(false);
    onClose();
  }, []);

  return (
    <BannerItemContainer className={ className }>
      <SectionContainer>
        <Label>Name:</Label>
        <div>{ name }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Text:</Label>
        <div>{ text }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Link:</Label>
        <div>{ !!link ? link : 'No link' }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Link Text:</Label>
        <div>{ !!linkText ? linkText : 'No link text' }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Color:</Label>
        <div>{ color }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Start Date: </Label>
        <div className='start-date'>{ (!!startDate ? startDate: 'No set date') as React.ReactNode }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>End Date: </Label>
        <div className='end-date'>{ (!!endDate ? endDate : 'No set date') as React.ReactNode }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Promo Status: </Label>
        <div className={ enabled ? 'active enabled-status' : '' }>{ enabled ? 'Active' : 'Inactive' }</div>
      </SectionContainer>
      <Button 
        kind={ ButtonKind.Blank }
        onClick={ handleOpenModal }
      >
        <PencilIcon stroke={ theme.colors.darkGray1 } />
      </Button>
      <CreateUpdateBannerModal
        banner={ banner }
        isOpen={ modalOpen }
        onClose={ onModalClose }
        bannersModel={ bannersModel }
      />
    </BannerItemContainer>
  );
};

export const BannerItem = observer(BannerItemBase);
