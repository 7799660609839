import styled from 'styled-components';
import { MainAdminContainer } from '../../../components/MainAdminContainer';

export const BannersContainer = styled(MainAdminContainer)``;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`; 
