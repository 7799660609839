import { action, computed, observable, makeObservable, runInAction } from 'mobx';
import { BaseModel } from './base';
import { CollectionModel, ICollectionConfig } from './collection';
import { WebServiceHelper } from '../lib/webServiceHelper';

type PrivateFields = '_busy' |
'_manualMatches' |
'_isAdmin' |
'_init';

type PrivateManualMatchFields = '_busy' | '_manualMatch';

export enum MatchType {
  Name = 'name',
  MerchantName = 'merchant_name',
}

export interface IManualMatchPopulatedCompany {
  _id: string;
  companyName: string;
}

export interface IManualMatchUpdate {
  _id?: string;
  originalValue?: string;
  matchType?: MatchType;
  company?: string;
}

export interface IManualMatch {
  _id?: string;
  originalValue?: string;
  matchType?: MatchType;
  createdOn: Date;
  lastModified: Date;
  company: IManualMatchPopulatedCompany;
}

export class ManualMatchModel extends BaseModel {
  private _busy = false;
  private _manualMatch: IManualMatch = null;

  constructor(manualMatchInfo: IManualMatch) {
    super();
    makeObservable<ManualMatchModel, PrivateManualMatchFields>(this, {
      _busy: observable,
      _manualMatch: observable,
      _id: computed,
      busy: computed,
    });

    this._manualMatch = manualMatchInfo;
  }

  get busy() { return this._busy; }
  get _id() { return this._manualMatch._id; }
  get manualMatch() { return this._manualMatch; }

  public updateManualMatch = async (data: IManualMatchUpdate) => {
    if (this._busy) return;

    runInAction(() => {
      this._busy = true;
    });

    const result = await this.webServiceHelper.sendRequest<IManualMatch>(
      {
        path: `/admin/transaction/manual-matches/${this._id}`,
        method: 'PUT',
        data,
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }

    return result.value;
  };

  static createManualMatch = async (data: IManualMatchUpdate) => {
    const webServiceHelper = new WebServiceHelper();
    const result = await webServiceHelper.sendRequest<IManualMatch>(
      {
        path: '/admin/transaction/manual-matches',
        method: 'POST',
        data,
      },
      true,
    );

    if (result.success) {
      return new ManualMatchModel(result.value);
    } else {
      throw new Error(result.error);
    }
  };

  public deleteManualMatch = async () => {
    if (this._busy) return;

    runInAction(() => {
      this._busy = true;
    });

    const result = await this.webServiceHelper.sendRequest<IManualMatch>(
      {
        path: `/admin/transaction/manual-matches/${this._id}`,
        method: 'DELETE',
      },
      true,
    );

    if (result.success) {
      runInAction(() => {
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }

    return result.value;
  };

}

export class ManualMatchesModel extends BaseModel {
  private _busy = false;
  private _manualMatches: CollectionModel<IManualMatch, ManualMatchModel> = null;
  private _isAdmin = false;
  private _config: ICollectionConfig = {};

  constructor(config: ICollectionConfig = {}, isAdmin = false) {
    super({ basePath: '/admin/transaction/manual-matches' });
    makeObservable<ManualMatchesModel, PrivateFields>(this, {
      _busy: observable,
      _manualMatches: observable,
      _isAdmin: observable,
      manualMatches: computed,
      _init: action.bound,
    });

    this._config = config || {};
    this._isAdmin = isAdmin;
    this._init();
  }

  get busy() { return this._busy; }
  get manualMatches() { return this._manualMatches; }

  private _init = () => {
    const url = '/admin/transaction/manual-matches';
    this._manualMatches = new CollectionModel<IManualMatch, ManualMatchModel>(
      url,
      (manualMatchInfo: IManualMatch) => new ManualMatchModel(manualMatchInfo),
      this._config,
    );
  };
}
