
import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { 
  FalsePositiveItem,
  FalsePositivesHeader,
  FalsePositivesInfoContainer,
  FalsePositivesList,
  FalsePositivesListContainer,
  SearchContainer,
} from './styles';
import { FalsePositivesModel, FalsePositiveModel } from '../../../../models/falsePositive';
import { useErrorMessages } from '../../../../contexts/error-messages-store';
import { ButtonKind } from '../../../../components/Button/styles';
import { TextField, TextFieldKind } from '../../../../components/TextField';
import { Button } from '../../../../components/Button';
import { FalsePositivesModal } from '../../../../components/modals/FalsePositivesModal';
import { debounce } from '../../../../lib/misc';
import { Waypoint } from 'react-waypoint';
import { LoadingSpinner } from '../../../../components/loading/LoadingSpinner';

interface IProps {
  className?: string;
}

export const FalsePositivesBase: React.FC<IProps> = ({
  className = '',
}) => {
  const errorMessages = useErrorMessages();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryParam, setSearchQueryParam] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFalsePositive, setSelectedFalsePositive] = useState<FalsePositiveModel>(null);
  const falsePositivesModel = useRef(new FalsePositivesModel({ limit: 100 }, true)).current;

  const setSearchQueryDebounced = useCallback(debounce(setSearchQueryParam, 500), []);

  const loadFalsePositives = useCallback((refresh?: boolean) => async (params: string, queryGeneration: boolean) => {
    try {
      await falsePositivesModel.falsePositives[refresh ? 'refresh' : 'loadMore'](params, queryGeneration);
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Loading False Positives',
        message: err.message,
      });
    }
  }, []);

  const handleFalsePositiveClick = useCallback((falsePositive: FalsePositiveModel) => {
    setSelectedFalsePositive(falsePositive);
    setIsModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setSelectedFalsePositive(null);
  }, []);

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleModalSave = useCallback(() => {
    if (searchQueryParam) loadFalsePositives(true)(`search=${searchQueryParam}`, true);
    else loadFalsePositives(true)(null, false);
    setIsModalOpen(false);
    setSelectedFalsePositive(null);
  }, [falsePositivesModel, searchQueryParam]);

  useEffect(() => {
    loadFalsePositives(true)(null, false);
  }, []);

  useEffect(() => {
    if (searchQuery) setSearchQueryDebounced(searchQuery);
    else setSearchQueryDebounced('');
  }, [searchQuery]);

  useEffect(() => {
    if (searchQueryParam) loadFalsePositives(true)(`search=${searchQueryParam}`, true);
    else if (falsePositivesModel.falsePositives.firstPageLoaded) loadFalsePositives(true)(null, false);
  }, [searchQueryParam]);

  const falsePositivesInfo = useMemo(() => {
    const resultsText = `${falsePositivesModel.falsePositives.total} ${searchQueryParam ? 'Results' : 'False Positives'}`;
    const results = ((falsePositivesModel.falsePositives.busy && searchQueryParam) || !falsePositivesModel.falsePositives.firstPageLoaded) ? 
      <div className='false-positives-info-placeholder' /> :
      resultsText;
   
    return (
      <>
        { results }
        <div>
          <Button
            kind={ ButtonKind.PrimaryGhost }
            onClick={ handleModalOpen }
          >
            + Add False Positive
          </Button>
        </div>
      </>
    );
  }, [handleModalOpen, falsePositivesModel.falsePositives.busy, falsePositivesModel.falsePositives.firstPageLoaded, falsePositivesModel.falsePositives.total, searchQueryParam]);

  const falsePositives = falsePositivesModel.falsePositives.results;

  return (
    <FalsePositivesListContainer className={ className }>
      <SearchContainer>
        <TextField
          labelHidden
          fieldKind={ TextFieldKind.Pill }
          id='false-positives-search-input'
          label='search false positives'
          onChange={ (e) => setSearchQuery(e.target.value) }
          placeholder='Search False Positives'
          value={ searchQuery }
        />
      </SearchContainer>
      <FalsePositivesInfoContainer>
        { falsePositivesInfo }
      </FalsePositivesInfoContainer>
      <FalsePositivesHeader>
        <div className='false-positive-original-value'>Original Value</div>
        <div className='false-positive-match-type'>Match Type</div>
      </FalsePositivesHeader>
      <FalsePositivesList>
        { falsePositivesModel.falsePositives.busy && (
          <LoadingSpinner />
        ) }
        { (falsePositives.length > 0 && !falsePositivesModel.busy) && falsePositives.map((fpmodel) => {
          const falsePositive = fpmodel.falsePositive;
          return (
            <FalsePositiveItem
              key={ falsePositive._id }
              className='false-positive-item'
              kind={ ButtonKind.Blank }
              onClick={ () => handleFalsePositiveClick(fpmodel) }
            >
              <div className='false-positive-original-value'>{ falsePositive.originalValue }</div>
              <div className='false-positive-match-type'>{ falsePositive.matchType }</div>
            </FalsePositiveItem>
          );
        }) }
        { (!falsePositivesModel.falsePositives.allResultsFetched && !falsePositivesModel.falsePositives.busy) && (
          <Waypoint onEnter={ falsePositivesModel.falsePositives.loadMore } />
        ) }
      </FalsePositivesList>
      <FalsePositivesModal
        isOpen={ isModalOpen }
        falsePositiveModel={ selectedFalsePositive }
        onClose={ handleModalClose }
        onSave={ handleModalSave }
      />      
    </FalsePositivesListContainer>
  );
};

export const FalsePositives = observer(FalsePositivesBase);
