import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';
import { CollectionModel } from './collection';
import { IUser, UserModel, UserRoles } from './users';

type PrivateFields = '_assignableRoles' |
'_gettingAssignableRoles' |
'_gettingSummary' |
'_gettingUsers' |
'_init' |
'_summary' |
'_updatingUserRole' |
'_users';

interface ISummary {
  totalMembers: number;
  totalAdmin: number;
  totalSuperAdmin: number;
}

const defaultSummary = {
  totalMembers: 0,
  totalAdmin: 0,
  totalSuperAdmin: 0,
};

export class AccessControlModel extends BaseModel {
  private _gettingAssignableRoles = false;
  private _gettingUsers = false;
  private _gettingSummary = false;
  private _assignableRoles: UserRoles[] = [];
  private _summary: ISummary = null;
  private _updatingUserRole = ''; // will hold the id of the user whose role is being updated
  private _users: CollectionModel<IUser, UserModel> = null;

  constructor () {
    super({ basePath: '/admin/access-control' });
    makeObservable<AccessControlModel, PrivateFields>(this, {
      _assignableRoles: observable,
      _gettingAssignableRoles: observable,
      _gettingUsers: observable,
      _gettingSummary: observable,
      _summary: observable,
      _updatingUserRole: observable,
      _users: observable,
      assignableRoles: computed,
      busy: computed,
      gettingAssignableRoles: computed,
      gettingUsers: computed,
      gettingSummary: computed,
      summary: computed,
      updatingUserRole: computed,
      users: computed,
      _init: action.bound,
      getSummary: action.bound,
    });
    this._init();
  }

  get assignableRoles() { return this._assignableRoles; }
  get busy() { return this._gettingSummary || this._gettingUsers; }
  get gettingAssignableRoles() { return this._gettingAssignableRoles; }
  get gettingUsers() { return this._gettingUsers; }
  get gettingSummary() { return this._gettingSummary; }
  get summary() { return this._summary || defaultSummary; }
  /**
   * the id of the user whose role is being updated
   */
  get updatingUserRole() { return this._updatingUserRole; }
  get users() { return this._users; }

  getAssignableRoles = async () => {
    if (!this._gettingAssignableRoles) {
      this._gettingAssignableRoles = true;
      
      const result = await this.webServiceHelper.sendRequest<UserRoles[]>({
        path: '/assignable-roles',
        method: 'GET',
      });

      if (result.success) {
        runInAction(() => {
          this._assignableRoles = result.value;
          this._gettingAssignableRoles = false;
        });
      } else {
        runInAction(() => {
          this._gettingAssignableRoles = false;
        });

        throw new Error(result.error);
      }
    }
  };

  getSummary = async () => {
    if (!this._gettingSummary) {
      this._gettingSummary = true;

      const result = await this.webServiceHelper.sendRequest<ISummary>({
        path: '/summary',
        method: 'GET',
      });

      if (result.success) {
        runInAction(() => {
          this._summary = result.value;
          this._gettingSummary = false;
        });
      } else {
        runInAction(() => {
          this._gettingSummary = false;
        });

        throw new Error(result.error);
      }
    }
  };

  updateUserRole = async (userId: string, newRole: UserRoles) => {
    if (!this._updatingUserRole) {
      this._updatingUserRole = userId;

      const result = await this.webServiceHelper.sendRequest<IUser>({
        path: '/update-role',
        method: 'POST',
        data: { userId, role: newRole },
      });

      if (result.success) {
        runInAction(() => {
          this._users.results
            .find(user => user._id === result.value._id)
            ?.updateUserInfo({ role: result.value.role });
          this._updatingUserRole = '';
        });
      } else {
        runInAction(() => {
          this._updatingUserRole = '';
        });

        throw new Error(result.error);
      }
    }
  };

  private _init = () => {
    const url = `${this._basePath}/users`;
    this._users = new CollectionModel<IUser, UserModel>(
      url,
      (userInfo: IUser) => new UserModel(url, userInfo),
    );
  };
}
