import styled from 'styled-components';
import { AbsoluteCenter, FlexCenter, FlexCol, FlexHorizontalCenter, NoScrollBar } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';

export const CompanyDataContainer = styled.div`
  ${ NoScrollBar }
  flex-grow: 1;
  overflow: auto;
`;

export const CompanyModalContainer = styled(CustomModal)`
  ${ FlexCol }
  width: 75vw;
  height: 70vh;
  overflow: hidden;

  .text-field {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const Row = styled.div`
  ${ FlexHorizontalCenter }
  align-items: flex-start;
  
  &:not(:last-child) {
    padding-bottom: 20px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  min-width: 350px;
  max-width: 350px;
`;

export const BasicCompanyInfoContainer = styled.div`
  flex-grow: 1;
`;

export const LoadingSpinnerContainer = styled.div`
  ${ AbsoluteCenter };
  ${ FlexCenter }
  width: 100px;
  height: 100px;
  border: 2px solid ${({ theme }) => theme.colors.green};
  border-radius: 50%;
  background: #fff;
  z-index: 1;
`;
