import React, { forwardRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { GroupLogoSkeleton } from '../../../../components/GroupLogo/skeleton';
import { GroupItemSkeletonContainer } from './styles';

interface IProps {
  className?: string;
}

export const GroupItemSkeleton = forwardRef<HTMLDivElement, IProps>((
  {
    className = '',
  },
  ref,
) => (
  <GroupItemSkeletonContainer ref={ ref } className={ className }>
    <div className='base-info-container'>
      <div className='logo-container'>
        <GroupLogoSkeleton className='group-logo' />
      </div>
      <div>
        <Skeleton className='group-name' />
        <Skeleton className='group-info' count={ 2 } />
      </div>
    </div>
  </GroupItemSkeletonContainer>
));
