import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { BaseModel } from './base';
import { IChart, IPromosReport, IUnlinkedOrRemovedChartData } from './chart';

dayjs.extend(utc);

type PrivateReportFields = '_busy' | '_chart' | '_lastUpdated' | '_report';

type PrivateFields = '_busy' | '_loadingSummary' | '_reports' | '_summary';

export enum ReportType {
  AccountsAdded = 'accounts-added',
  AccountsAddedHistory = 'accounts-added-history',
  AccountsUnlinkedOrRemoved = 'accounts-unlinked-or-removed',
  AccountTypes = 'account-types',
  CarbonOffsets = 'carbon-offsets',
  CumulativeUserLoginsSevenDays = 'cumulative-user-logins-seven-days',
  CumulativeUserLoginsThirtyDays = 'cumulative-user-logins-thirty-days',
  PromoUsersByAccountStatus = 'promo-users-with-linked-accounts',
  PromoUsersByCampaign = 'promo-users-by-campaign',
  PromoUsersBySource = 'promo-users-by-source',
  TransactionMonitor = 'transaction-monitor',
  UserHistory = 'user-history',
  UserLoginsSevenDays = 'user-logins-seven-days',
  UserLoginsThirtyDays = 'user-logins-thirty-days',
  UserSignup = 'user-signups',
  User = 'user',
}

export interface IUsersReportSummary {
  total: number;
  withCard: number;
  withoutCard: number;
  withUnlinkedCard: number;
  withRemovedCard: number;
  loggedInLastSevenDays: number;
  loggedInLastThirtyDays: number;
}

interface ILinkedCardData {
  total: number;
  credit: number;
  depository: number;
}

export interface ICardsReportSummary {
  linked: ILinkedCardData;
  unlinked: { total: number };
  removed: { total: number };
}

export interface ILoginsReportSummary {
  sevenDayTotal: number;
  thirtyDayTotal: number;
}

export interface ITransactionsReportSummary {
  total: number;
  totalDollars: number;
  totalExcludingCategories: number;
  totalDollarsExcludingCategories: number;
  matched: number;
  matchedExcludingCategories: number;
  matchedDollars: number;
  matchedDollarsExcludingCategories: number;
  matchedRatio: number;
  matchedRatioExcludingCategories: number;
  matchedDollarsRatio: number;
  matchedDollarsRatioExcludingCategories: number;
}

export interface ITotalCommissionsEarned {
  total: number;
  dollars: number;
  totalKarmaWallet: number;
  totalKarmaWalletDollars: number;
  totalWildfire: number;
  totalWildfireDollars: number;
  totalKard: number;
  totalKardDollars: number;
}

export interface ITotalOffests {
  total: number;
  dollars: number;
  tons: number;
}

export interface IReportRef {
  reportId: ReportType;
  name: string;
  description: string;
  lastUpdated: dayjs.Dayjs;
}

export interface IPayoutSummary {
  pending: {
    total: number;
    marqeta: number;
    paypal: number;
    unknown: number;
  };
}

export interface IReportSummary {
  users: IUsersReportSummary;
  cards: ICardsReportSummary;
  logins: ILoginsReportSummary;
  transactions: ITransactionsReportSummary;
  offsets: ITotalOffests;
  commissions: ITotalCommissionsEarned;
  payouts: IPayoutSummary;
}

export type UnlinkedOrRemovedAccountReportChart = { data: IUnlinkedOrRemovedChartData[] };
export type PromoReportChart = { data: IPromosReport[] };
export class ReportModel<T = string> extends BaseModel {
  private _busy = false;
  private _report: IReportRef = null;
  private _chart: IChart<T> | PromoReportChart | UnlinkedOrRemovedAccountReportChart = null;
  /**
   * the timestamp this data was last updated.
   *
   * WARNING - this will be null if this data is
   * pulled at request time and is not stored in the
   * db.
   */
  private _lastUpdated: dayjs.Dayjs = null;

  constructor(reportInfo: IReportRef) {
    super({ basePath: '/admin/reports' });

    makeObservable<ReportModel<T>, PrivateReportFields>(this, {
      _busy: observable,
      _chart: observable,
      _lastUpdated: observable,
      _report: observable,
      busy: computed,
      chart: computed,
      reportId: computed,
      name: computed,
      description: computed,
      lastUpdated: computed,
      load: action.bound,
    });

    this._report = reportInfo;

    if (reportInfo.lastUpdated) this._lastUpdated = dayjs(reportInfo.lastUpdated).local();
  }

  get busy() { return this._busy; }
  get chart() { return this._chart; }
  get reportId() { return this._report.reportId; }
  get name() { return this._report.name; }
  get description() { return this._report.description; }
  get lastUpdated() { return this._lastUpdated; }

  load = async () => {
    if (this._busy || !!this._chart) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<
    IChart<T> | UnlinkedOrRemovedAccountReportChart | PromoReportChart
    >({
      path: `/${this.reportId}`,
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._chart = this.getChartData(result.value);
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  getChartData = (
    data: IChart<T> | UnlinkedOrRemovedAccountReportChart | PromoReportChart,
  ): IChart<T> | PromoReportChart | UnlinkedOrRemovedAccountReportChart => {
    let chartData: IChart<T> | PromoReportChart | UnlinkedOrRemovedAccountReportChart = null;
    // assert value and transform into a chart
    if (!!(data as PromoReportChart)?.data[0]?.promo) {
      const promoData = (data as PromoReportChart)?.data as IPromosReport[];
      chartData = { data: promoData };
    } else if (!!(data as UnlinkedOrRemovedAccountReportChart)?.data[0]?.removed) {
      const accountStatusData = data.data as IUnlinkedOrRemovedChartData[];
      chartData = {
        data: accountStatusData,
      };
    } else {
      chartData = data as unknown as IChart<T>;
    }
    return chartData;
  };
}

export class ReportsModel extends BaseModel {
  private _busy = false;
  private _loadingSummary = false;
  private _reports: ReportModel<any>[] = [];
  private _summary: IReportSummary = null;

  constructor() {
    super({ basePath: '/admin/reports' });
    makeObservable<ReportsModel, PrivateFields>(this, {
      _busy: observable,
      _loadingSummary: observable,
      _reports: observable,
      _summary: observable,
      busy: computed,
      loadingSummary: computed,
      reports: computed,
      summary: computed,
      loadReports: action.bound,
      loadSummary: action.bound,
    });
  }

  get busy() { return this._busy || this._loadingSummary; }
  get loadingSummary() { return this._loadingSummary; }
  get reports() { return this._reports; }
  get summary() { return this._summary; }

  loadReports = async () => {
    if (this._busy) return;
    this._busy = true;

    const result = await this.webServiceHelper.sendRequest<IReportRef[]>({
      path: '/',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._reports = result.value.map((r) => new ReportModel(r));
        this._busy = false;
      });
    } else {
      runInAction(() => {
        this._busy = false;
      });

      throw new Error(result.error);
    }
  };

  loadSummary = async () => {
    if (this._loadingSummary) return;
    this._loadingSummary = true;

    const result = await this.webServiceHelper.sendRequest<IReportSummary>({
      path: '/summary',
      method: 'GET',
    });

    if (result.success) {
      runInAction(() => {
        this._summary = result.value;
        this._loadingSummary = false;
      });
    } else {
      runInAction(() => {
        this._loadingSummary = false;
      });

      throw new Error(result.error);
    }
  };
}
