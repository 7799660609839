import React, { useCallback } from 'react';
import { ReportModel, ReportType } from '../../../models/reports';
import { AccountsAddedHistoryReport } from '../../reports/AccountsAddedHistoryReport';
import { AccountTypesReport } from '../../reports/AccountTypes';
import { CarbonOffsetsReport } from '../../reports/CarbonOffsetReport';
import { UserLoginReport } from '../../reports/UserLoginReport';
import { AccountsAddedReport } from '../../reports/AccountsAddedReport';
import { UserReport } from '../../reports/UserReport';
import { UserSignupReport } from '../../reports/UserSignupReport';
import { CustomModal } from '../CustomModal';
import { ReportModalContainer } from './styles';
import { AccountsUnlinkedOrRemovedReport } from '../../reports/AccountsUnlinkedOrRemovedReport';
import { PromosReport } from '../../reports/PromosReport';

interface IProps {
  className?: string;
  isOpen: boolean;
  onClose(): void;
  report: ReportModel;
}

export const ReportModal: React.FC<IProps> = ({
  className = '',
  isOpen,
  onClose,
  report,
}) => {
  const onReportModalClose = useCallback(() => {
    onClose();
  }, [report]);

  const renderReport = () => {
    switch (report.reportId) {
      case ReportType.AccountsAddedHistory: return <AccountsAddedHistoryReport report={ report } />;
      case ReportType.AccountsAdded: return <AccountsAddedReport report={ report } />;
      case ReportType.AccountsUnlinkedOrRemoved: return <AccountsUnlinkedOrRemovedReport report={ report } />;
      case ReportType.AccountTypes: return <AccountTypesReport report={ report } />;
      case ReportType.CarbonOffsets: return <CarbonOffsetsReport report={ report } />;
      case ReportType.CumulativeUserLoginsSevenDays: return <UserLoginReport report={ report } />;
      case ReportType.CumulativeUserLoginsThirtyDays: return <UserLoginReport report={ report } />;
      case ReportType.PromoUsersBySource: return <PromosReport report={ report } />;
      case ReportType.PromoUsersByCampaign: return <PromosReport report={ report } />;
      case ReportType.PromoUsersByAccountStatus: return <PromosReport report={ report } />;
      case ReportType.UserHistory: return <UserReport report={ report } />;
      case ReportType.UserLoginsSevenDays: return <UserLoginReport report={ report } />;
      case ReportType.UserLoginsThirtyDays: return <UserLoginReport report={ report } />;
      case ReportType.User: return <UserReport report={ report } />;
      case ReportType.UserSignup: return <UserSignupReport report={ report } />;
      default: return (
        <div>
          invalid report found
        </div>
      );
    }
  };

  if (!report) return null;

  return (
    <CustomModal
      title={ report.name }
      isOpen={ isOpen }
      onClose={ onReportModalClose }
    >
      <ReportModalContainer className={ className }>
        { renderReport() }
      </ReportModalContainer>
    </CustomModal>
  );
};
