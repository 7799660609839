import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { UserModel, UsersModel } from '../../../models/users';
import { IModalProps } from '../Modal';
import { UserPicker } from '../../UserPicker';
import { UserPickerModalContainer } from './styles';

interface IProps extends IModalProps {
  className?: string;
  onUserSelected(user: UserModel): void;
  usersModel: UsersModel;
}

const UserPickerModalBase: React.FC<IProps> = ({
  className = '',
  isOpen,
  onUserSelected,
  onClose,
  usersModel,
}) => {
  const onModalClose = useCallback(() => {
    onClose();
  }, []);

  return (
    <UserPickerModalContainer
      className={ className }
      title='Select a User'
      isOpen={ isOpen }
      onClose={ onModalClose }
    >
      <UserPicker
        className='user-picker' 
        onUserSelected={ onUserSelected }
        usersModel={ usersModel }
      />
    </UserPickerModalContainer>
  );
};

export const UserPickerModal = observer(UserPickerModalBase);
