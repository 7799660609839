import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonKind } from '../../../components/Button/styles';
import { CTAs, CtaType } from '../../../components/CTAs';
import { H1 } from '../../../styles/components/header';
import { ActionsListContainer, HomeContainer } from './styles';

interface IProps {
  className?: string;
}

export const Home: React.FC<IProps> = ({
  className = '',
}) => {
  const renderActionsList = () => {
    const actions: JSX.Element[] = [];

    /** add actions based on user's groups and role */
    actions.push(<Link key='action-1' to='/companies'>Do something awesome with companies</Link>);
    actions.push(<Link key='action-2' to='/reports'>Look at amazing reports</Link>);

    return actions;
  };

  return (
    <HomeContainer className={ className } title='Admin'>
      <H1>What would you like to do?</H1>
      <CTAs
        className='action-buttons'
        ctas={ [
          {
            id: 'request-new-feature',
            text: 'Request a New Feature',
            kind: ButtonKind.Primary,
            url: 'https://github.com/karmawallet/karmawallet/issues/new?assignees=&labels=feature-request&template=feature-request.md&title=%5BFeature+Request%5D%3A+',
            openNewTab: true,
            ctaType: CtaType.Link,
          },
          {
            id: 'request-design',
            text: 'Make a Design Request',
            kind: ButtonKind.Quaternary,
            url: 'https://github.com/karmawallet/karmawallet/issues/new?assignees=&labels=design-request&template=design-request.md&title=%5BDesign+Request%5D%3A+',
            openNewTab: true,
            ctaType: CtaType.Link,
          },
          {
            id: 'report-a-bug',
            text: 'Report a Bug',
            kind: ButtonKind.Danger,
            url: 'https://github.com/karmawallet/karmawallet/issues/new?assignees=iamthe-Wraith&labels=side-quest&template=bug-report.md&title=',
            openNewTab: true,
            ctaType: CtaType.Link,
          },
          {
            id: 'view-existing-feature-requests',
            text: 'View Existing Feature Requests',
            kind: ButtonKind.PrimaryGhost,
            url: 'https://github.com/karmawallet/karmawallet/projects/5',
            openNewTab: true,
            ctaType: CtaType.Link,
          },
        ] }
      />
      <ActionsListContainer>
        { renderActionsList() }
      </ActionsListContainer>
    </HomeContainer>
  );
};
