import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { CommissionsModel, CommissionType } from '../../../models/commisions';
import { H1 } from '../../../styles/components/header';
import { CommissionHeader, CommissionHeaderRowItem, CommissionRow, CommissionRowItem, CommissionsContainer, CommissionsHeaderContainer, CommissionsInnerWrapper, CommissionsList, CommissionsListContainer } from './styles';
import { Button } from '../../../components/Button';
import { ManageCommissionModal } from './ManageCommissionModal';
import { TextField, TextFieldKind } from '../../../components/TextField';
import { ButtonKind } from '../../../components/Button/styles';
import { PencilIcon } from '../../../components/svgs/icons/PencilIcon';
import { withTheme } from 'styled-components';
import { IThemeProps } from '../../../styles/themes';

interface IProps extends IThemeProps {
  className?: string;
}

const CommissionsBase: React.FC<IProps> = ({
  className = '',
  theme,
}) => {
  const commissionsModel = useRef(new CommissionsModel()).current;
  const errorMessages = useErrorMessages();
  const [manageCommissionModalOpen, setManageCommissionModalOpen] = useState(false);
  const [textField, setTextField] = useState('');

  const onClearResultsClick = () => {
    setTextField('');
    commissionsModel.clearSearchedCommission();
  };

  const handleFindCommissionInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextField(e.target.value);
  };
  
  const onFindCommissionClick = async () => {
    try {
      await commissionsModel.findCommissionById(textField, true);
    } catch (err: any) {
      errorMessages.push({
        title: 'Error loading commissions',
        message: err.message,
      });
    }
  };

  const onEditCommissionClick = () => {
    setManageCommissionModalOpen(true);
  };

  const onCloseManageCommissionModal = () => setManageCommissionModalOpen(false);

  const getCommissions = async () => {
    try {
      await commissionsModel.loadCommissions([CommissionType.Wildfire, CommissionType.Kard], true);
    } catch (err: any) {
      errorMessages.push({
        title: 'Error loading commissions',
        message: err.message,
      });
    }
  };

  useEffect(() => {
    getCommissions();
  }, []);

  const editCommissionButton = <Button
    onClick={ onEditCommissionClick }
    kind={ ButtonKind.Blank }
    className='edit-commission-button'
  >
    <PencilIcon stroke={ theme.colors.dark } />
  </Button>;

  const clearSearchResultsButton = <Button
    onClick={ onClearResultsClick }
    kind={ ButtonKind.Primary }
    className='clear-search-results-button'
  >
    Clear Search Results
  </Button>;

  const commissions = useMemo(() => {
    if (!!commissionsModel.busy) return <LoadingSpinner className='loading-spinner' />;
    if (!commissionsModel.commissions && commissionsModel.errorLoading) return <div className='no-commissions'>Error loading commissions data</div>;

    let commissionItems;

    if (commissionsModel.searchedCommission) {
      const commission = commissionsModel.searchedCommission;
      commissionItems = <CommissionRow key={ commission?._id }>
        <CommissionRowItem className='date'>{ commission?.createdOn }</CommissionRowItem>
        <CommissionRowItem className='amount-to-user'>${ commission?.userAmount?.toFixed(2) }</CommissionRowItem>
        <CommissionRowItem className='company'>{ commission?.company?.companyName }</CommissionRowItem>
        <CommissionRowItem className='status'>{ commission?.status }{ editCommissionButton }</CommissionRowItem>
        <CommissionRowItem className='source'>{ commission?.source }</CommissionRowItem>
      </CommissionRow>;
    } else {
      commissionItems = commissionsModel.commissions?.map((commission) => (
        <CommissionRow key={ commission?._id }>
          <CommissionRowItem className='date'>{ commission?.createdOn }</CommissionRowItem>
          <CommissionRowItem className='amount-to-user'>${ commission?.userAmount?.toFixed(2) }</CommissionRowItem>
          <CommissionRowItem className='company'>{ commission?.company?.companyName }</CommissionRowItem>
          <CommissionRowItem className='status'>{ commission?.status }</CommissionRowItem>
          <CommissionRowItem className='source'>{ commission?.source }</CommissionRowItem>
        </CommissionRow>
      ));
    }

    return (
      <CommissionsList>
        { commissionItems }
      </CommissionsList>
    );
  }, [commissionsModel.commissions, commissionsModel.busy, commissionsModel.errorLoading, commissionsModel.searchedCommission]);

  const commissionList = useMemo(() => (
    <CommissionsListContainer>
      <CommissionHeader>
        <CommissionHeaderRowItem className='date'>Date</CommissionHeaderRowItem>
        <CommissionHeaderRowItem className='amount-to-user'>To User</CommissionHeaderRowItem>
        <CommissionHeaderRowItem className='company'>Company</CommissionHeaderRowItem>
        <CommissionHeaderRowItem className='status'>Status</CommissionHeaderRowItem>
        <CommissionHeaderRowItem className='source'>Source</CommissionHeaderRowItem>
      </CommissionHeader>
      { commissions }
      { commissionsModel.searchedCommission && clearSearchResultsButton }
    </CommissionsListContainer>
  ), [commissionsModel.commissions, commissionsModel.busy, commissionsModel.errorLoading, commissionsModel.searchedCommission]);

  return (
    <CommissionsContainer className={ className } title='Commissions'>
      <CommissionsInnerWrapper>
        <CommissionsHeaderContainer>
          <H1>Commissions</H1>
          <div>
            <TextField
              fieldKind={ TextFieldKind.Pill }
              id='find-commission'
              labelHidden
              label='Find Commission'
              onChange={ handleFindCommissionInput }
              placeholder='Find Commission by ID'
              value={ textField }
            />
            <Button
              className='find-commission-button'
              disabled={ textField.length < 24 }
              kind={ ButtonKind.Primary }
              label='Find Commission'
              onClick={ onFindCommissionClick }
            >
              Find Commission
            </Button>
          </div>
        </CommissionsHeaderContainer>
        { commissionList }
      </CommissionsInnerWrapper>
      <ManageCommissionModal
        isOpen={ manageCommissionModalOpen }
        onClose={ onCloseManageCommissionModal }
        commissionsModel={ commissionsModel }
      />
    </CommissionsContainer>
  );
};

const CommissionsObserver = observer(CommissionsBase);
export const Commissions = withTheme(CommissionsObserver);
