import React, { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { animated, config, useSpring } from 'react-spring';
import { TextField, TextFieldKind } from '../../../components/TextField';
import { useUserSession } from '../../../contexts/user';
import { LoginContainer } from './styles';
import { CTAs } from '../../../components/CTAs';
import { UserRoles } from '../../../models/users';
import { KWTealLogo } from '../../../components/svgs/graphics/LogoWithText';
import { useErrorMessages } from '../../../contexts/error-messages-store';

declare const __KARMA_ENV__: string;

interface IProps {
  className?: string;
}

const from = {
  opacity: 0,
  transform: 'translate3d(0, -100px, 0)',
};

const to = {
  opacity: 1,
  transform: 'translate3d(0, 0, 0)',
};

const LoginBase: React.FC<IProps> = ({
  className = '',
}) => {
  const navigate = useNavigate();
  const errorMessages = useErrorMessages();
  const userSession = useUserSession();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const formSpring = useSpring({
    config: config.gentle,
    from,
    to,
    delay: 400,
  });

  useEffect(() => {
    if (!!userSession && userSession.isLoggedIn && (userSession.role === UserRoles.Member || userSession.role === UserRoles.Admin || userSession.role === UserRoles.SuperAdmin)) {
      navigate('/');
    }
  }, [userSession.isLoggedIn, userSession.role]);

  const onChange = (field: 'email' | 'password') => (e: ChangeEvent<HTMLInputElement>) => {
    if (field === 'email') {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const onSignInClick = async () => {
    try {
      await userSession.login(email, password);
      navigate('/');
    } catch (err) {
      errorMessages.push({
        title: 'Error Logging In',
        message: (err as Error).message,
      });
    }
  };

  return (
    <LoginContainer className={ className } title='Login'>
      <animated.div className='form-container' style={ formSpring }>
        <div className='logo-container'>
          <KWTealLogo />
        </div>
        {
          userSession.isLoggedIn && (
            <div className='not-permitted'>
              The account you are currently logged into does not have permission to view this area. Please sign in to an account with permission or return to <a href={ __KARMA_ENV__ === 'development' ? 'http://localhost:3000' : 'https:/karmawallet.io' }>Karma Wallet</a>
            </div>
          )
        }
        <TextField
          fieldKind={ TextFieldKind.Pill }
          id='email-field'
          className='text-field'
          label='Email'
          labelHidden
          placeholder='email'
          onChange={ onChange('email') }
          type='email'
          value={ email }
        />
        <TextField
          fieldKind={ TextFieldKind.Pill }
          id='password-field'
          className='text-field'
          label='Password'
          labelHidden
          placeholder='password'
          onChange={ onChange('password') }
          type='password'
          value={ password }
        />
        <CTAs
          ctas={ [
            {
              disabled: !email || !password,
              id: 'sign-in-cta',
              text: userSession.authenticating ? 'Signing In...' : 'Sign In',
              onClick: userSession.authenticating ? null : onSignInClick,
            },
          ] }
        />
      </animated.div>
    </LoginContainer>
  );
};

export const Login = observer(LoginBase);
