import styled from 'styled-components';
import { FlexCenter, FlexHorizontalCenter } from '../../styles/styles';
import { Button } from '../Button';

export const Tab = styled(Button)`
  margin: 1px 0 -1px;
  padding: 5px 10px;
  border: 1px solid ${({ theme }) => theme.colors.darkGray3};
  border-bottom: 1px solid transparent;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  opacity: 0.5;

  &:first-child {
    margin-left: 10px;
  }

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:last-child {
    margin-right: 10px;
  }

  &.selected {
    border-bottom: 1px solid #fff;
    background: #fff;
    opacity: 1;
  }
`;

export const TabsContainer = styled.div`
  ${FlexHorizontalCenter}
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkGray3};
`;

export const TabsSkeletonContainer = styled.div`
  ${FlexCenter}

  .tab-skeleton {
    width: 50px;
    height: 50px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
`;
