import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Button } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';
import { CreateUpdateCampaignModal } from '../../../../components/modals/CreateUpdateCampaignModal';
import { PencilIcon } from '../../../../components/svgs/icons/PencilIcon';
import { CampaignModel, CampaignsModel } from '../../../../models/campaigns';
import { IThemeProps } from '../../../../styles/themes';
import { CampaignItemContainer, Label, SectionContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  campaign: CampaignModel;
  campaignsModel: CampaignsModel;
  onClose(): void;
}

const CampaignItemBase: React.FC<IProps> = ({
  className = '',
  campaign,
  theme,
  campaignsModel,
  onClose,
}) => {
  const { name, description, paramKeyToUse } = campaign;
  const [updateCampaignModalOpen, setUpdateCampaignModalOpen] = useState(false);

  const openModal = () => setUpdateCampaignModalOpen(true);

  const onModalClose = () => {
    setUpdateCampaignModalOpen(false);
    onClose();
  };

  return (
    <CampaignItemContainer className={ className }>
      <SectionContainer>
        <Label>Name:</Label>
        <div>{ name }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Description:</Label>
        <div>{ description }</div>
      </SectionContainer>
      <SectionContainer>
        <Label>Utm Param Key to Check:</Label>
        <div>{ paramKeyToUse }</div>
      </SectionContainer>
      <Button 
        kind={ ButtonKind.Blank }
        onClick={ openModal }
      >
        <PencilIcon stroke={ theme.colors.darkGray1 } />
      </Button>
      <CreateUpdateCampaignModal
        campaign={ campaign }
        isOpen={ updateCampaignModalOpen }
        onClose={ onModalClose }
        campaignsModel={ campaignsModel }
      />
    </CampaignItemContainer>
  );
};

const CampaignItemObserver = observer(CampaignItemBase);
export const CampaignItem = withTheme(CampaignItemObserver);
