import styled from 'styled-components';
import { FlexHorizontalCenter } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';

export const JobModalFooter = styled.div`
  ${FlexHorizontalCenter};
  justify-content: space-between;
  min-width: 100%;
`;

export const JobModalContainer = styled(CustomModal)`
  min-width: 600px;
  width: 60vw;
  min-height: 500px;
  height: calc(80vh - 100px);
  max-height: 800px;
`;

export const JobModalInner = styled.div`
  height: 100%;
  overflow: auto;

  .job-input {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .job-description {
    flex-grow: 1;
  }

  .job-preview {
    flex-grow: 1;
  }
`;
