import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkInlineLinks from 'remark-inline-links';
import { JobPostingModel } from '../../../models/job-postings';
import { ButtonKind } from '../../Button/styles';
import { ICta } from '../../CTAs';
import { Markdown } from '../../Markdown';
import { TextArea } from '../../TextArea';
import { TextField } from '../../TextField';
import { JobModalContainer, JobModalFooter, JobModalInner } from './styles';
import { observer } from 'mobx-react';
import { LoadingSpinner } from '../../loading/LoadingSpinner';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { Checkbox } from '../../Checkbox';

interface IProps {
  isOpen: boolean;
  job: JobPostingModel;
  onClose(): void;
  onSave(job: JobPostingModel): void;
}

const JobModalBase: React.FC<IProps> = ({ job, isOpen, onClose, onSave }) => {
  const errorMessages = useErrorMessages();
  const [_job, setJob] = useState(job ?? new JobPostingModel());
  const [title, setTitle] = useState(job?.title || '');
  const [instructions, setInstructions] = useState(job?.instructions || '');
  const [description, setDescription] = useState(job?.description || '');
  const [department, setDepartment] = useState(job?.department || '');
  const [jobLocation, setJobLocation] = useState(job?.jobLocation || 'Remote');
  const [applicationUrl, setApplicationUrl] = useState(job?.applicationUrl || '');
  const [published, setPublished] = useState(job?.published || false);
  const [inPreviewMode, setInPreviewMode] = useState(false);
  
  const reset = () => {
    setInPreviewMode(false);
    setTitle(job?.title || '');
    setInstructions(job?.instructions || '');
    setDescription(job?.description || '');
    setDepartment(job?.department || '');
    setJobLocation(job?.jobLocation || 'Remote');
    setApplicationUrl(job?.applicationUrl || '');
    setPublished(!!job?.published);
  };

  useEffect(() => {
    if (job) {
      reset();
      job.load()
        .catch(err => {
          errorMessages.push({
            title: 'Error Loading Job Description',
            message: err.message,
          });
        });
      setJob(job);
    } else {
      setJob(new JobPostingModel());
    }
  }, [job]);

  useEffect(() => {
    setInstructions(job?.instructions || '');
    setDescription(job?.description || '');
  }, [job?.description, job?.instructions]);

  const onCancelClick = useCallback(() => {
    // TODO: check for changes...if found. confirm user wants to discard them
    reset();
    onClose();
  }, [title, instructions, description, department, location]);

  const onDepartmentChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDepartment(e.target.value);
  }, []);

  const onDescriptionChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  }, []);

  const onExitPreviewClick = useCallback(() => {
    setInPreviewMode(false);
  }, []);

  const onInstructionsChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInstructions(e.target.value);
  }, []);

  const onLocationChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setJobLocation(e.target.value);
  }, []);

  const onApplicationUrlChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setApplicationUrl(e.target.value);
  }, []);

  const onPublishedChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPublished(e.target.checked);
  }, []);

  const onPreviewClick = useCallback(() => {
    setInPreviewMode(true);
  }, []);

  const onSaveClick = useCallback(async () => {  
    try {
      await _job.save({
        title,
        instructions,
        description,
        department,
        jobLocation,
        applicationUrl,
        published: !!published,
      });
      reset();
      onSave(_job);
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Saving Job Description',
        message: err,
      });
    }
  }, [title, instructions, description, department, location, applicationUrl, published]);

  const onTitleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }, []);

  const ctas = useMemo(() => {
    const ctas: ICta[] = [
      {
        disabled: !title || !description || !department || !location,
        id: 'job-save-btn',
        text: !!job?._id ? 'Save' : 'Create',
        kind: ButtonKind.Primary,
        onClick: onSaveClick,
      },
    ];

    if (inPreviewMode) {
      ctas.push({
        id: 'job-exit-preview-btn',
        text: 'Exit Preview',
        kind: ButtonKind.Tertiary,
        onClick: onExitPreviewClick,
      });
    } else {
      ctas.push({
        disabled: !description,
        id: 'job-preview-btn',
        text: 'Preview',
        kind: ButtonKind.PrimaryGhost,
        onClick: onPreviewClick,
      });
    }

    ctas.push({
      id: 'job-cancel-btn',
      text: 'Cancel',
      kind: ButtonKind.Blank,
      onClick: onCancelClick,
    });

    return ctas;
  }, [title, instructions, description, department, location, inPreviewMode, applicationUrl, published]);

  const renderEditor = () => {
    if (job?.busy) return <LoadingSpinner />;

    return (
      <>
        <TextField
          className='job-input'
          label='Title'
          id='job-title-input'
          value={ title }
          placeholder='Enter a job title'
          onChange={ onTitleChange }
        />
        <TextField
          className='job-input'
          label='Apply Instructions'
          id='job-instructions-input'
          value={ instructions }
          placeholder='Enter some instructions for how to apply (optional)'
          onChange={ onInstructionsChange }
        />
        <TextArea
          className='job-input job-description'
          label='Description (In markdown format)'
          // ariaDescription='Enter a job description'
          id='job-description-input'
          value={ description }
          placeholder='Enter a job description'
          onChange={ onDescriptionChange }
        />
        <TextField
          className='job-input'
          label='Department'
          id='job-department-input'
          value={ department }
          placeholder='Enter a department (ie. Engineering, Design, etc.)'
          onChange={ onDepartmentChange }
        />
        <TextField
          className='job-input'
          label='Location'
          id='job-location-input'
          value={ jobLocation }
          onChange={ onLocationChange }
        />
        <TextField
          className='job-input'
          label='Application Url'
          id='job-application-url-input'
          value={ applicationUrl }
          onChange={ onApplicationUrlChange }
        />
      </>
    );
  };

  const renderPreview = () => (
    <Markdown
      className='job-preview'
      content={ description }
      rehypePlugins={ [rehypeRaw] }
      remarkPlugins={ [remarkGfm, remarkInlineLinks] }
    />
  );

  return (
    <JobModalContainer
      title={ job?.title || 'New Job Posting' }
      ctas={ ctas }
      isOpen={ isOpen }
      onClose={ onClose }
    >
      <JobModalInner>
        {
          inPreviewMode
            ? renderPreview()
            : renderEditor()
        }
        <JobModalFooter>
          <Checkbox
            id='publish-job-posting'
            checked={ published }
            onChange={ onPublishedChange }
            label='Publish Job Posting'
          />
        </JobModalFooter>
      </JobModalInner>
    </JobModalContainer>
  );
};

export const JobModal = observer(JobModalBase);
