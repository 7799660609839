import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonKind } from '../../Button/styles';
import { Dropdown, IDropdownOption } from '../../Dropdown';
import { TextField } from '../../TextField';
import { FalsePositiveModalContainer, FieldContainer, FieldLabel, MatchTypeContainer } from './styles';
import { MatchType, FalsePositiveModel, IFalsePositiveUpdate } from '../../../models/falsePositive';
import { useErrorMessages } from '../../../contexts/error-messages-store';

const matchTypeOptions: IDropdownOption<MatchType>[] = [
  {
    id: 'match-type-merchant-name',
    text: 'Merchant Name',
    context: MatchType.MerchantName,
  },
  {
    id: 'match-type-name',
    text: 'Name',
    context: MatchType.Name,
  },
];

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  falsePositiveModel: FalsePositiveModel;
  onSave: () => void;
}

const FalsePositivesModalBase: React.FC<IProps> = ({
  isOpen,
  onClose,
  falsePositiveModel,
  onSave,
}) => {
  const errorMessages = useErrorMessages();

  const [originalValue, setOriginalValue] = useState('');
  const [matchType, setMatchType] = useState(matchTypeOptions[0]);
  
  const reset = useCallback(() => {
    setOriginalValue('');
    setMatchType(matchTypeOptions[0]);
  }, []);

  const onCancelClick = useCallback(() => {
    reset();
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen]);

  useEffect(() => {
    if (!falsePositiveModel) return () => null;
    setOriginalValue(falsePositiveModel.falsePositive.originalValue);
    setMatchType(matchTypeOptions.find((option) => option.context === falsePositiveModel.falsePositive.matchType));
  }, [falsePositiveModel]);

  const onSaveClick = useCallback(async () => {
    if (!!falsePositiveModel) {
      const update: IFalsePositiveUpdate = {};
      if (originalValue !== falsePositiveModel.falsePositive.originalValue) update.originalValue = originalValue;
      if (matchType.context !== falsePositiveModel.falsePositive.matchType) update.matchType = matchType.context;
      try {
        await falsePositiveModel.updateFalsePositive(update);
        onSave();
      }
      catch (err: any) {
        errorMessages.push({
          title: 'Error Updating False Positive',
          message: err.message,
        });
      }
    } else {
      try {
        await FalsePositiveModel.createFalsePositive({
          originalValue,
          matchType: matchType.context,
        });
        onSave();
      } catch (err: any) {
        errorMessages.push({
          title: 'Error Creating False Positive',
          message: err.message,
        });
      }
    }
  }, [originalValue, matchType, falsePositiveModel]);

  const onDeleteClick = useCallback(async () => {
    if (!!falsePositiveModel) {
      try {
        await falsePositiveModel.deleteFalsePositive();
        onSave();
      } catch (err: any) {
        errorMessages.push({
          title: 'Error Deleting False Positive',
          message: err.message,
        });
      }
    }
  }, [falsePositiveModel]);

  const onOriginalValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setOriginalValue(e.target.value);
  }, []);

  const shouldEnableSaveCta = useMemo(() => {
    if (!falsePositiveModel) return !!originalValue && !!matchType;
    return originalValue !== falsePositiveModel.falsePositive.originalValue || matchType.context !== falsePositiveModel.falsePositive.matchType;
  }, [falsePositiveModel, originalValue, matchType]);

  const ctas = useMemo(() => {
    const _ctas = [
      {
        id: 'false-positive-modal-close',
        text: 'Cancel',
        kind: ButtonKind.PrimaryGhost,
        onClick: onCancelClick,
        disabled: false,
      },
      {
        id: 'false-positive-modal-save',
        disabled: !shouldEnableSaveCta,
        text: !!falsePositiveModel ? 'Save' : 'Create',
        kind: ButtonKind.Primary,
        onClick: onSaveClick,
      },
    ];

    if (falsePositiveModel) _ctas.unshift({
      id: 'false-positive-modal-delete',
      text: 'Delete',
      kind: ButtonKind.DangerGhost,
      onClick: onDeleteClick,
      disabled: !falsePositiveModel,
    });
    return _ctas;
  }, [shouldEnableSaveCta, onSaveClick, onCancelClick, onDeleteClick, falsePositiveModel]);

  return (
    <FalsePositiveModalContainer
      isOpen={ isOpen }
      onClose={ onClose }
      title={ `${!!falsePositiveModel ? 'Edit' : 'Create'} False Positive` }
      ctas={ ctas }
    >
      <FieldContainer>
        <TextField 
          id='false-positive-original-value'
          label='Original Value'
          value={ originalValue }
          onChange={ onOriginalValueChange }
        />
      </FieldContainer>
      <MatchTypeContainer>
        <FieldLabel>Match Type</FieldLabel>
        <Dropdown<MatchType>
          className='false-positive-match-type-dropdown'
          options={ matchTypeOptions }
          selectedOption={ matchType }
          onOptionClick={ setMatchType }
        />
      </MatchTypeContainer>
    </FalsePositiveModalContainer>
  );
};

export const FalsePositivesModal = observer(FalsePositivesModalBase);
