import styled from 'styled-components';
import { FlexCenter, FlexHorizontalCenter } from '../../../../styles/styles';

export const ConfirmDelete = styled.div`
  span {
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const DeleteOption = styled.span`
  color: ${({ theme }) => theme.colors.red};
`;

export const GroupItemContainer = styled.div`
  ${ FlexHorizontalCenter };
  justify-content: space-between;
  min-width: calc(100% - 4px);
  margin-right: 2px;
  margin-left: 2px;
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray1};
  border-radius: 20px;
  background: none;
  outline: none;
  text-align: left;
  transition: border .25s ease-in-out;

  &:first-child {
    margin-top: 2px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.darkGray1};
  }

  &:focus-visible {
    outline: 1px dashed ${({ theme }) => theme.colors.darkGray1};
    outline-offset: 1px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .logo-container {
    ${ FlexCenter }
    position: relative;
    width: 100px;
    height: 100px;
    margin-right: 20px;

    & > * {
      min-width: 100%;
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
    }
  }

  .base-info-container {
    ${ FlexHorizontalCenter }
    min-width: 40%;
    max-width: 40%;

    .group-name {
      margin-bottom: 2px;
      text-align: left;
    }

    p:last-child {
      color: ${({ theme }) => theme.colors.lightGray1};
      font-style: italic;
    }
  }
  
  .group-code-container {
    flex-grow: 1;
    padding: 0 10px;
    
    h5 {
      color: ${({ theme }) => theme.colors.green};
    }
  }

  .group-members-container {
    min-width: 150px;
  }
`;

export const GroupItemSkeletonContainer = styled(GroupItemContainer)`
  .logo-container {
    & * {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .group-name {
    width: 300px;
  }

  .group-info {
    width: 125px;
  }
`;

export const StatusContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  svg {
    width: 30px;
    height: 30px;
  }

  .locked {
    fill: ${({ theme }) => theme.colors.red};
  }
`;
