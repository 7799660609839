import styled from 'styled-components';
import { Dropdown } from '../../../components/Dropdown';
import { BasicContainer, FlexCol, FlexHorizontalCenter, NoScrollBar } from '../../../styles/styles';

export const AccessControlContainer = styled.div`
  ${ FlexCol }
  height: 100%;
  max-height: 100%;
`;

export const AssignableRolesDropdown = styled(Dropdown)`
  .dropdown-options-container {
    left: auto;
    right: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 20px;
  }
`;

export const SummaryContainer = styled.div`
  ${ BasicContainer };
  ${ FlexHorizontalCenter }
  position: relative;
  justify-content: center;
  margin-top: 20px;
  height: 130px;
`;

export const SummaryItem = styled.div`
  margin: 20px 30px;

  div {
    font-size: 36px;
    text-align: center;
  }
`;

export const MainContainer = styled.div`
  ${ FlexHorizontalCenter }
  flex-grow: 1;
  align-items: flex-start;
  height: calc(100% - 183px);
  padding-top: 20px;

  .users-filter {
    min-height: 500px;
  }
`;

export const SearchContainer = styled.div`
  padding-bottom: 20px;
`;

export const UsersInfoContainer = styled.div`
  padding: 0 20px 10px;
`;

export const UsersList = styled.div`
  ${ NoScrollBar }
  flex-grow: 1;
  max-height: calc(100% - 98px);
  overflow: auto;
`;

export const UsersListContainer = styled.div`
  ${ FlexCol }
  flex-grow: 1;
  min-height: 100%;
  max-height: 100%;
  margin-left: 20px;
  padding-bottom: 10px;
  overflow: hidden;
`;
