import styled from 'styled-components';
import { NoScrollBar } from '../../styles/styles';

export const SearchContainer = styled.div``;

export const UserPickerContainer = styled.div``;

export const UsersListContainer = styled.div`
  height: calc(100% - 30px);
  padding-top: 10px;
  overflow: hidden;
`;

export const UsersList = styled.div`
  ${ NoScrollBar }
  width: 100%;
  height: 100%;
  overflow: auto;

  .waypoint-support {
    border: 1px solid transparent;
  }
`;
