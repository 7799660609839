import styled from 'styled-components';
import { MainAdminContainer } from '../../../components/MainAdminContainer';
import { BasicContainer, FlexCol, FlexHorizontalCenter, FlexRow } from '../../../styles/styles';

export const MainContainer = styled.div`
  ${FlexHorizontalCenter}
  flex-grow: 1;
  align-items: flex-start;
  height: calc(100% - 255px);
  padding-top: 20px;

  .users-filter {
    min-height: 500px;
  }
`;

export const SearchContainer = styled.div``;

export const SummaryContainer = styled.div`
  ${BasicContainer};
  ${FlexHorizontalCenter};
  justify-content: center;
  height: 200px;
  margin-top: 20px;
`;

export const UsersContainer = styled(MainAdminContainer)``;

export const UsersInfoContainer = styled.div`
  padding: 0 20px 10px;
`;

export const UsersList = styled.div`
  .waypoint-support {
    border: 1px solid transparent;
  }

  .user-actions {
    ${FlexRow}
    gap: 10px;
  }
`;

export const UsersListContainer = styled.div`
  ${FlexCol}
  flex-grow: 1;
  min-height: 100%;
  max-height: 100%;
  margin-left: 20px;
  padding-bottom: 10px;
  overflow: hidden;
`;

export const SearchByContainer = styled.div`
  ${ FlexHorizontalCenter }
  gap: 5px;
  margin: 0 0 20px 5px;
`;
