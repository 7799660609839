
import React, { useRef, useEffect, useCallback, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { 
  ManualMatchItem,
  ManualMatchesHeader,
  ManualMatchesInfoContainer,
  ManualMatchesList,
  ManualMatchesListContainer,
  SearchContainer,
} from './styles';
import { ManualMatchesModel, ManualMatchModel } from '../../../../models/manualMatch';
import { useErrorMessages } from '../../../../contexts/error-messages-store';
import { ButtonKind } from '../../../../components/Button/styles';
import { TextField, TextFieldKind } from '../../../../components/TextField';
import { Button } from '../../../../components/Button';
import { debounce } from '../../../../lib/misc';
import { Waypoint } from 'react-waypoint';
import { LoadingSpinner } from '../../../../components/loading/LoadingSpinner';
import { ManualMatchModal } from '../../../../components/modals/ManualMatchesModal';

interface IProps {
  className?: string;
}

export const ManualMatchesBase: React.FC<IProps> = ({
  className = '',
}) => {
  const errorMessages = useErrorMessages();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryParam, setSearchQueryParam] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seletectedManualMatch, setSelectedManualMatch] = useState<ManualMatchModel>(null);
  const manualMatchesModel = useRef(new ManualMatchesModel({ limit: 100 }, true)).current;
  const setSearchQueryDebounced = useCallback(debounce(setSearchQueryParam, 500), []);

  const loadManualMatches = useCallback((refresh?: boolean) => async (params: string, queryGeneration: boolean) => {
    try {
      await manualMatchesModel.manualMatches[refresh ? 'refresh' : 'loadMore'](params, queryGeneration);
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Loading Manual Matches',
        message: err.message,
      });
    }
  }, []);

  const handleManualMatchClick = useCallback((manualMatch: ManualMatchModel) => {
    setSelectedManualMatch(manualMatch);
    setIsModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setSelectedManualMatch(null);
  }, []);

  const handleModalOpen = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleModalSave = useCallback(() => {
    if (searchQueryParam) loadManualMatches(true)(`search=${searchQueryParam}`, true);
    else loadManualMatches(true)(null, false);
    setIsModalOpen(false);
    setSelectedManualMatch(null);
  }, [manualMatchesModel, searchQueryParam]);

  useEffect(() => {
    loadManualMatches(true)(null, false);
  }, []);

  useEffect(() => {
    if (searchQuery) setSearchQueryDebounced(searchQuery);
    else setSearchQueryDebounced('');
  }, [searchQuery]);

  useEffect(() => {
    if (searchQueryParam) loadManualMatches(true)(`search=${searchQueryParam}`, true);
    else if (manualMatchesModel.manualMatches.firstPageLoaded) loadManualMatches(true)(null, false);
  }, [searchQueryParam, manualMatchesModel]);

  const manualMatchesInfo = useMemo(() => {
    const resultsText = `${manualMatchesModel.manualMatches.total} ${searchQueryParam ? 'Results' : 'Manual Matches'}`;
    const results = ((manualMatchesModel.manualMatches.busy && searchQueryParam) || !manualMatchesModel.manualMatches.firstPageLoaded) ? 
      <div className='manual-matches-info-placeholder' /> :
      resultsText;
   
    return (
      <>
        { results }
        <div>
          <Button
            kind={ ButtonKind.PrimaryGhost }
            onClick={ handleModalOpen }
          >
            + Add Manual Match
          </Button>
        </div>
      </>
    );
  }, [handleModalOpen, manualMatchesModel.manualMatches.busy, manualMatchesModel.manualMatches.firstPageLoaded, manualMatchesModel.manualMatches.total, searchQueryParam]);

  const manualMatches = manualMatchesModel.manualMatches.results;

  return (
    <ManualMatchesListContainer className={ className }>
      <SearchContainer>
        <TextField
          labelHidden
          fieldKind={ TextFieldKind.Pill }
          id='manual-matches-search-input'
          label='search manual matches'
          onChange={ (e) => setSearchQuery(e.target.value) }
          placeholder='Search Manual Matches'
          value={ searchQuery }
        />
      </SearchContainer>
      <ManualMatchesInfoContainer>
        { manualMatchesInfo }
      </ManualMatchesInfoContainer>
      <ManualMatchesHeader>
        <div className='manual-match-original-value'>Original Value</div>
        <div className='manual-match-company'>Company</div>
        <div className='manual-match-match-type'>Match Type</div>
      </ManualMatchesHeader>
      <ManualMatchesList>
        { manualMatchesModel.manualMatches.busy && (
          <LoadingSpinner />
        ) }
        { (manualMatches.length > 0 && !manualMatchesModel.busy) && manualMatches.map((mmmodel) => {
          const manualMatch = mmmodel.manualMatch;
          return (
            <ManualMatchItem
              key={ manualMatch._id }
              className='manual-match-item'
              kind={ ButtonKind.Blank }
              onClick={ () => handleManualMatchClick(mmmodel) }
            >
              <div className='manual-match-original-value'>{ manualMatch.originalValue }</div>
              <div className='manual-match-company'>{ manualMatch.company.companyName }</div>
              <div className='manual-match-match-type'>{ manualMatch.matchType }</div>
            </ManualMatchItem>
          );
        }) }
        { (!manualMatchesModel.manualMatches.allResultsFetched && !manualMatchesModel.manualMatches.busy) && (
          <Waypoint onEnter={ manualMatchesModel.manualMatches.loadMore } />
        ) }
      </ManualMatchesList>
      { <ManualMatchModal
        isOpen={ isModalOpen }
        manualMatchModel={ seletectedManualMatch }
        onClose={ handleModalClose }
        onSave={ handleModalSave }
      /> }
    </ManualMatchesListContainer>
  );
};

export const ManualMatches = observer(ManualMatchesBase);
