import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonKind } from '../../Button/styles';
import { Dropdown, IDropdownOption } from '../../Dropdown';
import { TextField } from '../../TextField';
import { 
  ManualMatchModalContainer,
  FieldContainer,
  MatchTypeContainer,
  SelectedCompany,
  CompanyFieldContainer,
  CompanyNameTextField,
  CompanySelectContainer,
  CompanySelectOption,
  FieldLabel,
  CloseButton,
  CompanySearchResultsHeader,
  CompanyResultsList,
} from './styles';
import { MatchType, ManualMatchModel, IManualMatchUpdate, IManualMatchPopulatedCompany } from '../../../models/manualMatch';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { debounce } from '../../../lib/misc';
import { WebServiceHelper } from '../../../lib/webServiceHelper';
import { ICollectionResponse } from '../../../models/collection';
import { XIcon } from '../../svgs/icons/XIcon';
import { theme } from '../../../styles/themes';
interface ICompanySearchResult {
  companyName: string;
  _id: string;
}

const matchTypeOptions: IDropdownOption<MatchType>[] = [
  {
    id: 'match-type-merchant-name',
    text: 'Merchant Name',
    context: MatchType.MerchantName,
  },
  {
    id: 'match-type-name',
    text: 'Name',
    context: MatchType.Name,
  },
];

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  manualMatchModel: ManualMatchModel;
  onSave: () => void;
}

const ManualMatchModalBase: React.FC<IProps> = ({
  isOpen,
  onClose,
  manualMatchModel,
  onSave,
}) => {
  const errorMessages = useErrorMessages();

  const [originalValue, setOriginalValue] = useState('');
  const [company, setCompany] = useState<IManualMatchPopulatedCompany>(null);
  const [matchType, setMatchType] = useState(matchTypeOptions[0]);
  const [companyName, setCompanyName] = useState('');
  const [companyNameQuery, setCompanyNameQuery] = useState('');
  const [companySelectOpen, setCompanySelectOpen] = useState(false);
  const [companySearchResults, setCompanySearchResults] = useState<ICompanySearchResult[]>([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  const setCompanyNamyQueryDebounced = useMemo(() => debounce(setCompanyNameQuery, 500), []);

  const getCompanies = useCallback(async (query: string) => {
    setLoadingCompanies(true);
    const client = new WebServiceHelper();
    const res = await client.sendRequest<ICollectionResponse<ICompanySearchResult>>({
      method: 'GET',
      path: '/company',
      queryParams: {
        companyName: query,
        limit: 10,
      },
    });
    if (res.success) {
      setLoadingCompanies(false);
      return setCompanySearchResults(res.value.docs.map(({ companyName, _id }) => ({ companyName, _id })));
    }
    setLoadingCompanies(false);
  }, []);

  const reset = useCallback(() => {
    setOriginalValue('');
    setMatchType(matchTypeOptions[0]);
    setCompany(null);
    setCompanyName('');
  }, []);

  const onCancelClick = useCallback(() => {
    reset();
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen]);

  useEffect(() => {
    if (!!manualMatchModel) {
      setOriginalValue(manualMatchModel.manualMatch.originalValue);
      setMatchType(matchTypeOptions.find((option) => option.context === manualMatchModel.manualMatch.matchType));
      setCompany(manualMatchModel.manualMatch.company);
      setCompanyName(manualMatchModel.manualMatch.company?.companyName);
    }
  }, [manualMatchModel]);

  useEffect(() => {
    if (!companyName) {
      setCompanyNameQuery(null);
      setCompanySearchResults([]);
      return;
    }
    setCompanyNamyQueryDebounced(companyName);
  }, [companyName]);

  useEffect(() => {
    if (company || !isOpen) return;
    if (companyNameQuery) {
      getCompanies(companyNameQuery);
      setCompanySelectOpen(true);
    }
    if (!companyNameQuery) {
      setCompanySearchResults([]);
      setCompanySelectOpen(false);
    }
  }, [companyNameQuery, company, isOpen]);

  const onSaveClick = useCallback(async () => {
    if (!!manualMatchModel) {
      const update: IManualMatchUpdate = {};
      if (originalValue !== manualMatchModel.manualMatch.originalValue) update.originalValue = originalValue;
      if (matchType.context !== manualMatchModel.manualMatch.matchType) update.matchType = matchType.context;
      if (company?._id !== manualMatchModel.manualMatch.company._id) update.company = company._id;
      try {
        await manualMatchModel.updateManualMatch(update);
        onSave();
      }
      catch (err: any) {
        errorMessages.push({
          title: 'Error Updating Manual Match',
          message: err.message,
        });
      }
    } else {
      try {
        await ManualMatchModel.createManualMatch({
          originalValue,
          matchType: matchType.context,
          company: company._id,
        });
        onSave();
      } catch (err: any) {
        errorMessages.push({
          title: 'Error Creating Manual Match',
          message: err.message,
        });
      }
    }
  }, [originalValue, matchType, manualMatchModel, company]);

  const onDeleteClick = useCallback(async () => {
    if (!!manualMatchModel) {
      try {
        await manualMatchModel.deleteManualMatch();
        onSave();
      } catch (err: any) {
        errorMessages.push({
          title: 'Error Deleting Manual Match',
          message: err.message,
        });
      }
    }
  }, [manualMatchModel]);

  const handleRemoveSelectedCompany = useCallback(() => {
    setCompanyNameQuery('');
    setCompanySelectOpen(false);
    setCompany(null);
    setCompanyName('');
    setCompanySearchResults([]);
  }, []);

  const onOriginalValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setOriginalValue(e.target.value);
  }, []);

  const onCompanyNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCompanyName(e.target.value);
  }, []);

  const shouldEnableSaveCta = useMemo(() => {
    if (!manualMatchModel) return !!originalValue && !!matchType && !!company;
    if (!company) return false;
    return originalValue !== manualMatchModel.manualMatch.originalValue || matchType.context !== manualMatchModel.manualMatch.matchType || company._id !== manualMatchModel.manualMatch.company._id;
  }, [manualMatchModel, originalValue, matchType, company]);

  const ctas = useMemo(() => {
    const _ctas = [
      {
        id: 'manual-match-modal-close',
        text: 'Cancel',
        kind: ButtonKind.PrimaryGhost,
        onClick: onCancelClick,
        disabled: false,
      },
      {
        id: 'manual-match-modal-save',
        disabled: !shouldEnableSaveCta,
        text: !!manualMatchModel ? 'Save' : 'Create',
        kind: ButtonKind.Primary,
        onClick: onSaveClick,
      },
    ];

    if (manualMatchModel) _ctas.unshift({
      id: 'manual-match-modal-delete',
      text: 'Delete',
      kind: ButtonKind.DangerGhost,
      onClick: onDeleteClick,
      disabled: !manualMatchModel,
    });
    return _ctas;
  }, [shouldEnableSaveCta, matchType, onSaveClick, onCancelClick, onDeleteClick, manualMatchModel]);

  const companySearchResultsSection = useMemo(() => {
    if (!companySelectOpen || loadingCompanies || companySearchResults.length === 0) return null;
    if (companySearchResults.length > 0) return (
      <CompanyResultsList>
        { companySearchResults.map((company) => {
          const { companyName, _id } = company;
          const handleClick = () => {
            setCompany(company);
            setCompanySelectOpen(false);
            setCompanyName('');
          };
          return (
            <CompanySelectOption
              key={ _id }
              className='company-select-option' 
              onClick={ handleClick } 
              kind={ ButtonKind.Blank }
            >
              { companyName }
            </CompanySelectOption>
          );
        }) }
      </CompanyResultsList>);
  }, [companySearchResults, loadingCompanies, companySelectOpen, handleRemoveSelectedCompany]);

  const companySelectResultsHeader = useMemo(() => (
    <CompanySearchResultsHeader>
      { !loadingCompanies && companySearchResults.length > 0 && (<span>{ `${companySearchResults.length} companies found` }</span>) }
      { loadingCompanies && <span>Loading...</span> }
      { (!companySearchResults.length && !loadingCompanies) && <span>No Results</span> }
      { !loadingCompanies && (
        <CloseButton
          label='Close Company Select'
          className='close-company-select'
          kind={ ButtonKind.Blank }
          onClick={ loadingCompanies ? null : handleRemoveSelectedCompany }
        >
          <XIcon stroke={ theme.colors.darkGray1 } />
        </CloseButton>
      ) }
    </CompanySearchResultsHeader>
  ), [companySearchResults, loadingCompanies, companySelectOpen, handleRemoveSelectedCompany]);

  return (
    <ManualMatchModalContainer
      isOpen={ isOpen }
      onClose={ onClose }
      title={ `${!!manualMatchModel ? 'Edit' : 'Create'} Manual Match` }
      ctas={ ctas }
    >
      <FieldContainer>
        <TextField 
          id='manual-match-original-value'
          label='Original Value'
          value={ originalValue }
          onChange={ onOriginalValueChange }
        />
      </FieldContainer>
      <CompanyFieldContainer>
        { !company?.companyName && <CompanyNameTextField 
          id='manual-match-selected-company'
          label='Selected Company'
          value={ companyName }
          onChange={ onCompanyNameChange }
          placeholder='Search for Companies'
        /> }
        { company?.companyName && (
          <>
            <FieldLabel className='selected-company'>Selected Company</FieldLabel>
            <SelectedCompany
              kind={ ButtonKind.Primary }
              onClick={ handleRemoveSelectedCompany }
            >
              { company?.companyName }
            </SelectedCompany>
          </>
        ) }
        { companySelectOpen && (
          <CompanySelectContainer>
            { companySelectResultsHeader }
            { companySearchResultsSection }
          </CompanySelectContainer>
        ) }
      </CompanyFieldContainer>
      <FieldLabel className='dropdown-label'>Match Type</FieldLabel>
      <MatchTypeContainer id='match-type-dropdown'>
        <Dropdown<MatchType>
          className='manual-match-match-type-dropdown'
          options={ matchTypeOptions }
          selectedOption={ matchType }
          onOptionClick={ setMatchType }
        />
      </MatchTypeContainer>
    </ManualMatchModalContainer>
  );
};

export const ManualMatchModal = observer(ManualMatchModalBase);
