import styled from 'styled-components';
import { BasicContainer } from '../../styles/styles';

export const OptionsContainer = styled.div``;

export const UsersFilterContainer = styled.div`
  ${ BasicContainer }
  min-width: 250px;
  max-width: 250px;
  padding: 20px;

  .role-checkbox {
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
`;
