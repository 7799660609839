import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { BannersModel, IBanner, IBannerColors, IBannerUpdate, ILoggedInState } from '../../../models/banners';
import { observer } from 'mobx-react';
import { BannerColorSection, BannerDataContainer, BannerInfoContainer, BannerLoggedInStateSection, CreateUpdateBannerModalContainer, Row } from './styles';
import { Dropdown } from '../../Dropdown';
import { TextField, TextFieldSize } from '../../TextField';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { useToaster } from '../../../contexts/toaster-store';
import { Checkbox } from '../../Checkbox';
import { DatesSection, DatesWrapper, DateContainer } from './styles';
import { ButtonKind } from '../../Button/styles';
import { isDaylightSavingsTime } from '../../../lib/dates';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  className?: string;
  onClose: () => void;
  isOpen: boolean;
  banner?: IBanner;
  bannersModel: BannersModel;
}

const bannerColorOptions = [
  {
    id: 'dark',
    text: 'Dark',
    context: IBannerColors.Dark,
  },
  {
    id: 'light',
    text: 'Light',
    context: IBannerColors.Light,
  },
  {
    id: 'warning',
    text: 'Warning',
    context: IBannerColors.Warning,
  },
];

const bannerLoggedInStateOptions = [
  {
    id: 'logged-in',
    text: 'Logged In',
    context: ILoggedInState.LoggedIn,
  },
  {
    id: 'logged-out',
    text: 'Logged Out',
    context: ILoggedInState.LoggedOut,
  },
  {
    id: 'both',
    text: 'Both',
    context: ILoggedInState.Both,
  },
];

const CreateUpdateBannerModalBase: React.FC<IProps> = ({
  className = '',
  banner,
  isOpen,
  onClose,
  bannersModel,
}) => {
  const toaster = useToaster();
  const errorMessages = useErrorMessages();
  const [text, setText] = useState(banner?.text || '');
  const [name, setName] = useState(banner?.name || '');
  const [link, setLink] = useState(banner?.link || '');
  const [linkText, setLinkText] = useState(banner?.linkText || '');
  const [color, setColor] = useState<IBannerColors>(banner?.color || IBannerColors.Dark);
  const [endDate, setEndDate] = useState(!!banner?.endDate ? dayjs(banner?.endDate).utc().format() : null);
  const [startDate, setStartDate] = useState(!!banner?.startDate ? dayjs(banner?.startDate).utc().format() : null);
  const [enabled, setEnabled] = useState(banner?.enabled || false);
  const [loggedInState, setLoggedInState] = useState<ILoggedInState>(banner?.loggedInState || ILoggedInState.Both);
  const estOffsetTime = useMemo(() => {
    if (isDaylightSavingsTime()) return 4;
    return 5;
  }, []);

  const resetModal = () => {
    setName('');
    setText('');
    setLink('');
    setLinkText('');
    setColor(null);
    setEndDate(null);
    setStartDate(null);
    setEnabled(false);
    setLoggedInState(null);
  };

  const changesFound = () => {
    let changes = false;
    // update banner
    if (!!banner) {
      if (name !== banner.name
          || link !== banner.link
          || enabled !== banner.enabled
          || text !== banner.text
          || linkText !== banner.linkText
          || color !== banner.color
          || loggedInState !== banner.loggedInState
          || startDate !== dayjs(banner?.startDate).utc().format()
          || endDate !== dayjs(banner?.endDate).utc().format()
      ) {
        changes = true;
      }
    }

    if (!banner) {
      if (!!name && !!text && !!color && !!loggedInState) changes = true;
    }

    return changes;
  };

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const onTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  }, []);

  const onLinkChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  }, []);

  const onLinkTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkText(e.target.value);
  }, []);

  const onLoggedInStateChange = useCallback((option: any) => {
    setLoggedInState(option.context);
  }, []);

  const onColorChange = useCallback((option: any) => {
    setColor(option.context);
  }, []);
  
  const onStartDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setStartDate(dayjs(e.target.valueAsDate).utc().add(estOffsetTime, 'hour').format());
  }, [estOffsetTime]);

  const onEndDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEndDate(dayjs(e.target.valueAsDate).utc().add(estOffsetTime, 'hour').format());
  }, [estOffsetTime]);

  const onEnabledChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEnabled(e.target.checked);
  }, []);

  const onSaveClick = () => {
    if (changesFound()) {
      if (!!banner) updateBanner();
      else createBanner();
    }
  };

  const ctas = useMemo(() => ([
    {
      id: 'cancel-banner-mod',
      text: 'Cancel',
      kind: ButtonKind.SecondaryWithIcon,
      onClick: onClose,
    },
    {
      disabled: !changesFound(),
      id: 'save-banner-mod',
      text: 'Save',
      kind: ButtonKind.Primary,
      onClick: onSaveClick,
    },
  ]), [changesFound]);

  const updateBanner = async () => {
    try {
      const bannerData: IBannerUpdate = {
        _id: banner._id,
        name: !name ? banner.name : name,
        text: !text ? banner.text : text,
        link: !link ? banner.link : link,
        linkText: !linkText ? banner.linkText : linkText,
        color: !color ? banner.color : color,
        loggedInState: !loggedInState ? banner.loggedInState : loggedInState,
        enabled: enabled === undefined ? banner.enabled : enabled,
        startDate: !startDate ? null : dayjs(startDate).utc().format(),
        endDate: !endDate ? null : dayjs(endDate).utc().format(),
      };

      await bannersModel.updateBanner(bannerData);
      onClose();
      toaster.push({ 
        message: 'Promo updated successfully',
      });
    } catch (err: any) {
      errorMessages.push({
        title: 'Error updating promo',
        message: err.message,
      });
    }
  };

  const createBanner = async () => {
    try {
      const bannerInfo: IBannerUpdate = {
        name,
        text,
        link,
        linkText,
        color,
        loggedInState,
        enabled,
        startDate,
        endDate,
      };

      await bannersModel.createBanner(bannerInfo);
      onClose();
      resetModal();
      toaster.push({ 
        message: 'Banner created successfully',
      });
    } catch (err: any) {
      errorMessages.push({
        title: 'Error creating banner',
        message: err.message,
      });
    }
  };

  return (
    <CreateUpdateBannerModalContainer
      className={ className }
      title={ `${!!banner ? 'Edit' : 'New'} Banner` }
      ctas={ ctas }
      isOpen={ isOpen }
      onClose={ onClose }
    >
      <BannerDataContainer>
        <Row>
          <BannerInfoContainer>
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Small }
              id='Banner-name-field'
              label='Banner Name*'
              onChange={ onNameChange }
              placeholder='Enter a Banner name'
              value={ name }
            />
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Small }
              id='Banner-text-field'
              label='Banner Text*'
              onChange={ onTextChange }
              placeholder='Enter banner text'
              value={ text }
            />
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Small }
              id='Banner-link-field'
              label='Link (link to go to when clicking on banner) optional'
              onChange={ onLinkChange }
              placeholder='Enter link/url'
              value={ link }
            />
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Small }
              id='Banner-link-text-field'
              label='Banner Link Text (text to show for the link)'
              onChange={ onLinkTextChange }
              placeholder='Enter link text'
              value={ linkText }
            />
            <BannerColorSection>
              <label className='regular-label'>Banner Color</label>
              <Dropdown
                className='banner-color-dropdown'
                options={ bannerColorOptions }
                selectedOption={ bannerColorOptions.find((option) => option.context === color) || bannerColorOptions[0] }
                onOptionClick={ onColorChange }
              />
            </BannerColorSection>
            <BannerLoggedInStateSection>
              <label className='regular-label'>Logged In State</label>
              <Dropdown
                className='banner-loggedin-state-dropdown'
                options={ bannerLoggedInStateOptions }
                selectedOption={ bannerLoggedInStateOptions.find((option) => option.context === loggedInState) || bannerLoggedInStateOptions[0] }
                onOptionClick={ onLoggedInStateChange }
              />
            </BannerLoggedInStateSection>
            <DatesSection>
              <label className='regular-label'>Banner Dates (optional)</label>
              <div className='dates-subtext'>Input a start and end date to trigger the Banner to be active from these time periods. If you do not select a start or end date, a Banner will be shown as long as it is marked as "Active".</div>
              <DatesWrapper>
                <DateContainer>
                  <label className='regular-label'>Start Date</label>
                  <input
                    type='date'
                    onChange={ onStartDateChange }
                  />
                </DateContainer>
                <DateContainer>
                  <label className='regular-label'>End Date</label>
                  <input
                    type='date'
                    onChange={ onEndDateChange }
                  />
                </DateContainer>
              </DatesWrapper>
            </DatesSection>
            <Checkbox 
              className='input-enabled'
              label='Active? (check to activate Banner, uncheck to deactivate)'
              onChange={ onEnabledChange }
              checked={ enabled }
            />
          </BannerInfoContainer>
        </Row>
      </BannerDataContainer>
    </CreateUpdateBannerModalContainer>
  );
};

export const CreateUpdateBannerModal = observer(CreateUpdateBannerModalBase);
