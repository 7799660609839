import React, { useCallback, useState } from 'react';
import { ITab } from '../../../components/Tabs';
import { H1 } from '../../../styles/components/header';
import { DataSectors } from './DataSectors';
import { DataContainer, DataTabs, MainContainer } from './styles';
import { FalsePositives } from './FalsePositives';
import { ManualMatches } from './ManualMatches';
import { MatchedCompanies } from './MatchedCompanies';

enum DataTab {
  Sectors = 'sectors',
  FalsePositives = 'false-positives',
  ManualMatches = 'manual-matches',
  MatchedCompanies = 'matched-companies',
}

interface IProps {
  className?: string;
}

export const Data: React.FC<IProps> = ({
  className = '',
}) => {
  const [selectedTab, setSelectedTab] = useState(DataTab.ManualMatches);

  const getTabs = useCallback(() => [
    {
      text: 'Sectors',
      context: DataTab.Sectors,
      selected: selectedTab === DataTab.Sectors,
    },
    {
      text: 'False Positives',
      context: DataTab.FalsePositives,
      selected: selectedTab === DataTab.FalsePositives,
    },
    {
      text: 'Manual Matches',
      context: DataTab.ManualMatches,
      selected: selectedTab === DataTab.ManualMatches,
    },
    {
      text: 'Matched Companies',
      context: DataTab.MatchedCompanies,
      selected: selectedTab === DataTab.MatchedCompanies,
    },
  ], [selectedTab]);

  const onTabClick = useCallback((tab: ITab<DataTab>) => {
    setSelectedTab(tab.context);
  }, []);

  const renderContent = () => {
    switch (selectedTab) {
      case DataTab.Sectors: return <DataSectors />;
      case DataTab.FalsePositives: return <FalsePositives />;
      case DataTab.ManualMatches: return <ManualMatches />;
      case DataTab.MatchedCompanies: return <MatchedCompanies />;
      default: <div>Invalid Section Found</div>;
    }
  };

  return (
    <DataContainer className={ className } title='Data'>
      <H1>Data</H1>
      <DataTabs
        className='data-tabs'
        tabs={ getTabs() }
        onTabClick={ onTabClick }
      />
      <MainContainer>
        { renderContent() }
      </MainContainer>
    </DataContainer>
  );
};
