import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { Button } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { GroupModal } from '../../../components/modals/GroupModal';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { TextField, TextFieldKind } from '../../../components/TextField';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { GroupModel, GroupsModel } from '../../../models/group';
import { H1, H5 } from '../../../styles/components/header';
import { GroupItem } from './GroupItem';
import { GroupItemSkeleton } from './GroupItem/skeleton';
import { GroupsContainer, GroupsInfoContainer, GroupsList, GroupsListContainer, MainContainer, SearchContainer, SummaryContainer, SummaryItem } from './styles';

interface IProps {
  className?: string;
}

const GroupsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const errorMessages = useErrorMessages();
  const groupsModel = useRef(new GroupsModel('admin')).current;
  const [groupsSearchQuery, setGroupsSearchQuery] = useState('');
  const [searchThrottle, setSearchThrottle] = useState<number>(null);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<GroupModel>(null);

  const loadMore = () => {
    groupsModel.groups.loadMore({ name: !!groupsSearchQuery ? `/${groupsSearchQuery}/gi` : null })
      .catch(err => {
        errorMessages.push({
          title: 'Error Getting Groups',
          message: err,
        });
      });
  };

  useEffect(() => {
    loadMore();

    groupsModel.loadSummary()
      .catch(err => {
        errorMessages.push({
          title: 'Error Loading Groups Summary',
          message: err.message,
        });
      });
  }, []);

  useEffect(() => {
    if (!!selectedGroup) setShowGroupModal(true);
  }, [selectedGroup]);

  useEffect(() => {
    window.clearTimeout(searchThrottle);

    if (groupsModel.groups.firstPageLoaded) {
      setSearchThrottle(window.setTimeout(() => {
        groupsModel.groups.refresh({ name: !!groupsSearchQuery ? `/${groupsSearchQuery}/gi` : null });
      }, 400));
    }
  }, [groupsSearchQuery]);

  const onCreateGroupClick = useCallback(() => {
    setShowGroupModal(true);
  }, []);

  const onGroupClick = useCallback((group: GroupModel) => () => {
    setSelectedGroup(group);
  }, []);

  const onGroupDelete = useCallback(async () => {
    try {
      await groupsModel.groups.refresh({ name: !!groupsSearchQuery ? `/${groupsSearchQuery}/gi` : null });
      groupsModel.loadSummary();
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Deleting Group',
        message: err.message,
      });
    }
  }, []);

  const onGroupModalClose = useCallback(() => {
    setShowGroupModal(false);
    setSelectedGroup(null);
    groupsModel.loadSummary();
  }, []);

  const onGroupsSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setGroupsSearchQuery(e.target.value);
  }, []);

  const renderGroups = () => {
    let groups: JSX.Element[] = [];

    if (groupsModel.groups.results.length > 0) {
      groups = groupsModel.groups.results.map(group => (
        <GroupItem
          key={ group._id }
          className='group-item'
          group={ group }
          onAction={ groupsModel.loadSummary }
          onClick={ onGroupClick(group) }
          onDelete={ onGroupDelete }
        />
      ));
    } else {
      groups.push(<div key='no-groups' className='no-groups'>No groups found</div>);
    }

    if (groupsModel.groups.busy) {
      if (groupsModel.groups.firstPageLoaded) {
        groups.push((
          <div key='loading-groups-spinner'>
            loading more...
          </div>
        ));
      } else {
        const skeletons: JSX.Element[] = [];

        for (let i = 0; i < 25; i++) {
          skeletons.push(<GroupItemSkeleton key={ `company-item-skele-${i}` } />);
        }

        groups = [...groups, ...skeletons];
      }
    }

    if (!groupsModel.groups.allResultsFetched && !groupsModel.groups.busy) {
      groups.push(<Waypoint key='waypoint' onEnter={ loadMore } topOffset={ 200 } />);
    }

    return groups;
  };

  const renderSummary = () => {
    if (groupsModel.loadingSummary) return <LoadingSpinner />;

    return [
      {
        header: 'Total Groups',
        count: groupsModel.summary?.total,
      },
      {
        header: 'Locked Groups',
        count: groupsModel.summary?.locked,
      },
      {
        header: 'Private Groups',
        count: groupsModel.summary?.private,
      },
      {
        header: 'Protected Groups',
        count: groupsModel.summary?.protected,
      },
      {
        header: 'Public Groups',
        count: groupsModel.summary?.public,
      },
    ].map(({ header, count }) => (
      <SummaryItem key={ header.split(' ').join('-') }>
        <H5>{ header }</H5>
        <div>{ count }</div>
      </SummaryItem>
    ));
  };

  return (
    <GroupsContainer className={ className } title='Groups'>
      <H1>Groups</H1>
      <SummaryContainer>
        { renderSummary() }
      </SummaryContainer>
      <MainContainer>
        <GroupsListContainer>
          <SearchContainer>
            <TextField
              fieldKind={ TextFieldKind.Pill }
              id='groups-search-input'
              label='Search Groups'
              labelHidden
              onChange={ onGroupsSearchChange }
              placeholder='Search Groups'
              value={ groupsSearchQuery }
            />
          </SearchContainer>
          <GroupsInfoContainer>
            <span>{ `${ groupsModel?.summary?.total } Groups` }</span>
            <div>
              <Button
                kind={ ButtonKind.PrimaryGhost }
                onClick={ onCreateGroupClick }
              >
                + Create Group
              </Button>
            </div>
          </GroupsInfoContainer>
          <GroupsList>
            { renderGroups() }
          </GroupsList>
        </GroupsListContainer>
      </MainContainer>
      {
        showGroupModal && (
          <GroupModal
            allowSettingOwner
            isOpen={ showGroupModal }
            group={ selectedGroup }
            groupsModel={ groupsModel }
            onClose={ onGroupModalClose }
          />
        )
      }
    </GroupsContainer>
  );
};

export const Groups = observer(GroupsBase);
