import React, { ChangeEvent, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { Label } from '../../styles/components/label';
import { TextAreaContainer } from './styles';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';

interface IProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  // use to provide additional context
  // use in place of ariaDescribedBy when there isn't an element on the page with the needed description text
  // ariaDescription?: string;
  // use to provide additional context
  // use when an element currently exist with the description text, pass thru that element's id
  ariaDescribedBy?: string;
  labelClassName?: string;
  id: string;
  label?: string;
  labelHidden?: boolean;
  placeholder?: string;
  value: string;
  // adding to make required
  onChange(e: ChangeEvent<HTMLTextAreaElement>): void;
}

export const HTMLTextArea: React.FC<IProps> = ({
  className = '',
  ariaDescribedBy,
  // ariaDescription,
  labelClassName = '',
  id,
  label,
  labelHidden,
  placeholder,
  value,
  onChange,
}) => {
  const textAreaRef = useRef(null);
  const [size, setSize] = useState(500);

  useEffect(() => {
    Prism.highlightAll();
    setSize(() => {
      if (textAreaRef.current.scrollHeight < 500) {
        return 500;
      } else {
        return textAreaRef.current.scrollHeight + 20;
      }
    });
  }, [value, textAreaRef]);

  return (
    <TextAreaContainer className={ className } sizeOfArea={ size }>
      <Label
        className={ `textarea-label ${labelClassName} ${labelHidden ? 'sr-only' : ''}` }
        htmlFor={ id }
      >
        { !labelHidden && label }
      </Label>
      <textarea
        spellCheck='false'
        id={ id }
        // aria-description={ ariaDescription }
        aria-describedby={ ariaDescribedBy }
        placeholder={ placeholder }
        value={ value }
        onChange={ onChange }
        ref={ textAreaRef }
      />
      <pre className='highlighting' aria-hidden='true'>
        <code className='language-html'>
          { value }
        </code>
      </pre>
    </TextAreaContainer>
  );
};
