import React, { useCallback, useRef, useState } from 'react';
import { ButtonKind } from '../../../../components/Button/styles';
import { CompanyLogo } from '../../../../components/CompanyLogo';
import { FileUploader } from '../../../../components/FileUploader';
import { IGroupLogoProps } from '../../../../components/GroupLogo/styles';
import { LoadingSpinner } from '../../../../components/loading/LoadingSpinner';
import { PencilIcon } from '../../../../components/svgs/icons/PencilIcon';
import { CompanyModel } from '../../../../models/companies';
import { FileUploaderModel } from '../../../../models/fileUploader';
import { EditButtonContainer, ImageUploadContainer, UploaderCTAs } from './styles';

interface IProps extends IGroupLogoProps {
  allowEditing?: boolean;
  className?: string;
  company: CompanyModel;
  setNewLogo: React.Dispatch<React.SetStateAction<any>>;
  setFileToUpload: React.Dispatch<React.SetStateAction<any>>;
  setPreviewImg: React.Dispatch<React.SetStateAction<any>>;
  setLogoUrl: React.Dispatch<React.SetStateAction<any>>;
  previewImg: string;
}

const EditButton: React.FC = () => (
  <EditButtonContainer>
    <PencilIcon />
  </EditButtonContainer>
); 

export const ImageUpload: React.FC<IProps> = ({
  allowEditing,
  className = '',
  company,
  withBorder,
  setNewLogo,
  setLogoUrl,
  setFileToUpload,
  previewImg, 
  setPreviewImg,
}) => {
  const fileUploader = useRef(new FileUploaderModel('karma')).current;
  const [loadingPreview, setLoadingPreview] = useState(false);

  const onLogoChange = useCallback((file: File) => {
    if (!!file) {
      setFileToUpload(file);
      setLoadingPreview(true);
      const reader = new FileReader();

      reader.onload = function (e: ProgressEvent<FileReader>) {
        setPreviewImg(e.target.result.toString());
        setLoadingPreview(false);
        setLogoUrl('');
      };

      reader.readAsDataURL(file);
      setNewLogo(true);
    }
  }, []);

  const onCancelUploadClick = useCallback(() => {
    setFileToUpload(null);
    setPreviewImg('');
    setLogoUrl('');
  }, []);

  const renderEditButton = () => {
    if (!allowEditing) return null;

    if (!previewImg) {
      return (
        <FileUploader
          className='file-uploader'
          label='Edit Company Logo'
          anchor={ <EditButton /> }
          onChange={ onLogoChange }
        />
      );
    }

    return (
      <UploaderCTAs
        className='file-uploader'
        ctas={ [
          {
            id: 'cancel-company-logo-upload',
            text: 'Cancel',
            kind: ButtonKind.PrimaryGhost,
            onClick: onCancelUploadClick,
          },
        ] }
      />
    );
  };

  const renderImage = () => {
    if (loadingPreview) return <LoadingSpinner />;
    if (!!previewImg) return <img src={ previewImg } alt='preview of newly uploaded logo' />;
   
    return <CompanyLogo company={ company } />;
  };

  return (
    <ImageUploadContainer
      className={ className }
      withBorder={ withBorder }
    >
      { !!fileUploader.uploadingImage && <LoadingSpinner /> }
      { renderImage() }
      { renderEditButton() }
    </ImageUploadContainer>
  );
};
