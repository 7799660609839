import React from 'react';
import { GroupModel } from '../../../models/group';
import { ButtonKind } from '../../Button/styles';
import { ConfirmationModal } from '../ConfirmationModal';

interface IProps {
  className?: string;
  isOpen: boolean;
  group: GroupModel;
  onDelete(): void;
  onClose(): void;
}

export const DeleteGroupConfirmationModal: React.FC<IProps> = ({
  group,
  onClose,
  onDelete,
  ...restProps
}) => (
  <ConfirmationModal
    { ...restProps }
    title='Are you sure?'
    body={ <>Are you sure you want to delete <span>{ group.name }</span>?</> }
    ctas={ [
      {
        id: 'confirm-delete',
        text: 'Delete',
        kind: ButtonKind.Primary,
        onClick: onDelete,
      },
      {
        id: 'cancel-delete',
        text: 'Cancel',
        kind: ButtonKind.SecondaryWithIcon,
        onClick: onClose,
      },
    ] }
    onClose={ onClose }
  />
);
