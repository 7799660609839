import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { withTheme } from 'styled-components';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { IChart, ISingleValueChartData } from '../../../models/chart';
import { ReportModel } from '../../../models/reports';
import { IThemeProps } from '../../../styles/themes';
import { LoadingSpinner } from '../../loading/LoadingSpinner';
import { AccountTypesReportContainer, ChartContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  report: ReportModel;
}

const mapData = ({ data }: IChart<string>): ISingleValueChartData[] =>
  data.map((d) => ({
    name: d.label,
    value: d.values[0].value,
  }));

const AccountTypesReportBase: React.FC<IProps> = ({ className = '', report, theme }) => {
  const errorMessages = useErrorMessages();
  const [data, setData] = useState<ISingleValueChartData[]>([]);

  useEffect(() => {
    report
      .load()
      .then(() => {
        setData(mapData(report.chart as IChart<string>));
      })
      .catch((err) => {
        errorMessages.push({
          title: 'Error Loading Report',
          message: err.message,
        });
      });
  }, []);

  const renderChart = () => {
    if (report.busy || !data.length) {
      return <LoadingSpinner />;
    }

    return (
      <ResponsiveContainer width='100%' height={ 200 }>
        <BarChart
          data={ data }
          margin={ {
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          } }
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey='value' fill={ theme.colors.primary } name='accounts' />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  return (
    <AccountTypesReportContainer className={ className }>
      <ChartContainer>{ renderChart() }</ChartContainer>
      { !!report.lastUpdated && (
        <p className='last-updated'>last updated { report.lastUpdated.format('MMM DD, YYYY @ HH:MM A') }</p>
      ) }
    </AccountTypesReportContainer>
  );
};

const AccountTypesReportAsObserver = observer(AccountTypesReportBase);
export const AccountTypesReport = withTheme(AccountTypesReportAsObserver);
