export enum TransactionSubtypeEnum {
  ProgramCredit = 'programCredit',
  Employer = 'employer',
  Cashback = 'cashback',
  Refund = 'refund',
}

export interface IGPADeposit {
  amount: number;
  userId: string;
  tags?: string[];
}

export interface IInitiateGPADepositsRequest {
  groupId?: string;
  type: TransactionSubtypeEnum;
  gpaDeposits: IGPADeposit[];
  memo?: string;
}
