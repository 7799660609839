import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { useToaster } from '../../../contexts/toaster-store';
import { CampaignModel, CampaignsModel, ICampaignParamKeys } from '../../../models/campaigns';
import { ButtonKind } from '../../Button/styles';
import { TextField, TextFieldSize } from '../../TextField';
import { CampaignContainer, CampaignDataContainer, CampaignInfoContainer, CampaignModalContainer, Row } from './styles';
import { Dropdown } from '../../Dropdown';

interface IProps {
  className?: string;
  isOpen: boolean;
  onClose(): void;
  campaign?: CampaignModel;
  campaignsModel: CampaignsModel;
}

const utmParamKeyOptions = [
  {
    id: 'utm_medium',
    text: 'utm_medium',
    context: ICampaignParamKeys.UTM_MEDIUM,
  },
  {
    id: 'utm_campaign',
    text: 'utm_campaign',
    context: ICampaignParamKeys.UTM_CAMPAIGN,
  },
  {
    id: 'utm_source',
    text: 'utm_source',
    context: ICampaignParamKeys.UTM_SOURCE,
  },
];

const CreateUpdateCampaignModalBase: React.FC<IProps> = ({
  className = '',
  isOpen,
  onClose,
  campaign,
  campaignsModel,
}) => {
  const toaster = useToaster();
  const errorMessages = useErrorMessages();
  const [name, setName] = useState(campaign?.name || '');
  const [description, setDescription] = useState(campaign?.description || '');
  const [paramKey, setParamKey] = useState(campaign?.paramKeyToUse || ICampaignParamKeys.UTM_CAMPAIGN);

  const resetModal = () => {
    setName('');
    setDescription('');
    setParamKey(ICampaignParamKeys.UTM_CAMPAIGN);
  };

  const changesFound = () => {
    let changes = false;
    // update campaign
    if (!!campaign) {
      if (name !== campaign.name || description !== campaign.description || paramKey !== campaign.paramKeyToUse) changes = true;
    } else {
      if (!!name || !!description) changes = true;
    }

    return changes;
  };

  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const onDescriptionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  }, []);

  const onParamKeyClick = useCallback((option: any) => {
    setParamKey(option.context);
  }, []);

  const updateCampaign = async () => {
    try {
      const campaignData = {
        name: !name ? campaign.name : name,
        description: !description ? campaign.description : description,
        paramKeyToUse: !paramKey ? campaign.paramKeyToUse : paramKey,
      };

      await campaign.updateCampaign(campaignData);
      onClose();
      toaster.push({ 
        message: 'Campaign updated successfully',
      });
    } catch (err: any) {
      errorMessages.push({
        title: 'Error updating campaign',
        message: err.message,
      });
    }
  };

  const createCampaign = async () => {
    try {
      await campaignsModel.createCampaign({ name, description, paramKeyToUse: paramKey });
      resetModal();
      onClose();
      toaster.push({ 
        message: 'Campaign created successfully',
      });
    } catch (err: any) {
      errorMessages.push({
        title: 'Error creating campaign',
        message: err.message,
      });
    }
  };

  const onSaveClick = () => {
    if (changesFound()) {
      if (!!campaign) updateCampaign();
      else createCampaign();
    }
  };

  const ctas = useMemo(() => ([
    {
      id: 'cancel-campaign-mod',
      text: 'Cancel',
      kind: ButtonKind.SecondaryWithIcon,
      onClick: onClose,
    },
    {
      disabled: !changesFound(),
      id: 'save-campaign-mod',
      text: 'Save',
      kind: ButtonKind.Primary,
      onClick: onSaveClick,
    },
  ]), [changesFound]);
  
  return (
    <CampaignModalContainer
      className={ className }
      title={ `${!!campaign ? 'Edit' : 'New'} Campaign` }
      ctas={ ctas }
      isOpen={ isOpen }
      onClose={ onClose }
    >
      <CampaignDataContainer>
        <Row>
          <CampaignInfoContainer>
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Large }
              id='campaign-name-field'
              label='Campaign Name'
              onChange={ onNameChange }
              placeholder='Enter a campaign name'
              value={ name }
            />
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Large }
              id='campaign-text-field'
              label='Campaign Description'
              onChange={ onDescriptionChange }
              placeholder='Enter a description about the campaign (optional)'
              value={ description }
            />
            <CampaignContainer>
              <label className='regular-label'>UTM Param to Use*</label>
              <Dropdown
                className='campaign-dropdown'
                options={ utmParamKeyOptions }
                selectedOption={ utmParamKeyOptions.find((option) => option.context === paramKey) }
                onOptionClick={ onParamKeyClick }
              />
            </CampaignContainer>
          </CampaignInfoContainer>
        </Row>
      </CampaignDataContainer>
    </CampaignModalContainer>
  );
};

export const CreateUpdateCampaignModal = observer(CreateUpdateCampaignModalBase);
