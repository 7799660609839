import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { withTheme } from 'styled-components';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { IChart, ISingleValueChartData } from '../../../models/chart';
import { ReportModel } from '../../../models/reports';
import { IThemeProps } from '../../../styles/themes';
import { LoadingSpinner } from '../../loading/LoadingSpinner';
import { ChartContainer, UserSignupReportContainer } from './styles';

interface IProps extends IThemeProps {
  className?: string;
  report: ReportModel;
}

const mapData = ({ data }: IChart<string>): ISingleValueChartData[] =>
  data.map((d) => ({
    name: d.label,
    value: d.values[0].value,
  }));

const UserSignupReportBase: React.FC<IProps> = ({ className = '', report, theme }) => {
  const errorMessages = useErrorMessages();
  const [data, setData] = useState<ISingleValueChartData[]>([]);

  useEffect(() => {
    report
      .load()
      .then(() => {
        setData(mapData(report.chart as IChart<string>));
      })
      .catch((err) => {
        errorMessages.push({
          title: 'Error Loading Report',
          message: err.message,
        });
      });
  }, []);

  const renderChart = () => {
    if (report.busy || !data) {
      return <LoadingSpinner />;
    }

    return (
      <ResponsiveContainer width='100%' height={ 200 }>
        <LineChart
          width={ 500 }
          height={ 200 }
          data={ data }
          margin={ {
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          } }
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Line
            connectNulls
            type='monotone'
            dataKey='value'
            stroke={ theme.colors.green }
            fill={ theme.colors.green }
            name='signups'
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <UserSignupReportContainer className={ className }>
      <ChartContainer>{ renderChart() }</ChartContainer>
      { !!report.lastUpdated && (
        <p className='last-updated'>last updated { report.lastUpdated.format('MMM DD, YYYY @ HH:MM A') }</p>
      ) }
    </UserSignupReportContainer>
  );
};

const UserSignupReportAsObserver = observer(UserSignupReportBase);
export const UserSignupReport = withTheme(UserSignupReportAsObserver);
