import React, { FC } from 'react';
import { SvgIcon } from '../../SvgIcon';

interface IProps {
  className?: string;
  fill?: string;
  id?: string;
}

export const UserCircleIcon: FC<IProps> = ({ className = '', fill = '', id = '' }) => (
  <SvgIcon
    className={ `${ className ? className + ' ' : '' }user-circle-icon` }
    id={ id }
    width={ 30 }
    height={ 30 }
    fill={ fill }
  >
    <path fillRule='evenodd' clipRule='evenodd' d='M15 27.25C8.23451 27.25 2.75 21.7655 2.75 15C2.75 8.23451 8.23451 2.75 15 2.75C21.7655 2.75 27.25 8.23451 27.25 15C27.25 21.7655 21.7655 27.25 15 27.25ZM9.1482 23.4165C10.8074 24.5723 12.8246 25.25 15 25.25C17.1754 25.25 19.1926 24.5723 20.8518 23.4165C19.7998 21.2481 17.5798 19.7549 15.0126 19.75C15.0084 19.75 15.0042 19.75 15 19.75C14.9958 19.75 14.9916 19.75 14.9874 19.75C12.4202 19.7549 10.2003 21.2481 9.1482 23.4165ZM7.59036 22.0824C5.83079 20.242 4.75 17.7472 4.75 15C4.75 9.33908 9.33908 4.75 15 4.75C20.6609 4.75 25.25 9.33908 25.25 15C25.25 17.7472 24.1692 20.242 22.4096 22.0824C21.5426 20.5441 20.2099 19.3022 18.6022 18.5488C20.0583 17.4541 21 15.712 21 13.75C21 10.4363 18.3137 7.75003 15 7.75003C11.6863 7.75003 9.00001 10.4363 9.00001 13.75C9.00001 15.712 9.94174 17.4541 11.3978 18.5488C9.79014 19.3022 8.45741 20.5441 7.59036 22.0824ZM15.0118 17.75C17.2155 17.7437 19 15.9553 19 13.75C19 11.5409 17.2091 9.75003 15 9.75003C12.7909 9.75003 11 11.5409 11 13.75C11 15.9553 12.7845 17.7437 14.9883 17.75C14.9922 17.75 14.9961 17.75 15 17.75C15.0039 17.75 15.0078 17.75 15.0118 17.75Z' />
  </SvgIcon>
);
