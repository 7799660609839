import { observer } from 'mobx-react';
import { customAlphabet } from 'nanoid';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { useToaster } from '../../../contexts/toaster-store';
import { defaultGroupSettings, GroupCodeAvailability, GroupModel, GroupPrivacyStatus, GroupsModel, MAX_CODE_LENGTH } from '../../../models/group';
import { AdminUsersModel, UserModel, UsersModel } from '../../../models/users';
import { Label } from '../../../styles/components/label';
import { Button } from '../../Button';
import { ButtonKind } from '../../Button/styles';
import { Checkbox } from '../../Checkbox';
import { CTAs } from '../../CTAs';
import { GroupLogo } from '../../GroupLogo';
import { GroupMembersList } from '../../GroupMembersList';
import { IModalProps } from '../Modal';
import { IRadioButtonProps } from '../../RadioButton';
import { RadioGroup, RadioGroupStack } from '../../RadioGroup';
import { XIcon } from '../../svgs/icons/XIcon';
import { TextField, TextFieldSize } from '../../TextField';
import { UserPickerModal } from '../UserPickerModal';
import { Accessory, BaseInfoContainer, GroupCodeSubFieldSection, GroupModalWrapper, MatchSetting, MatchTextFieldContainer, OwnerContainer, OwnerPill, PrivacyStatusContainer, Setting, SettingsContainer } from './styles';
import { CustomModal } from '../CustomModal';

interface IProps extends IModalProps {
  allowSettingOwner?: boolean;
  className?: string;
  group?: GroupModel;
  groupsModel: GroupsModel;
}

interface IPrivacyRadioButton extends IRadioButtonProps {
  context: GroupPrivacyStatus;
  description: string;
}

const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz0123456789', MAX_CODE_LENGTH);

const privacyRadioButtons: IPrivacyRadioButton[] = [
  {
    id: 'public-group',
    context: GroupPrivacyStatus.Public,
    label: 'Public (Coming Soon)',
    disabled: true, // disabling for now until we support public groups.
    description: 'Public groups can be viewed and joined by anyone.',
  },
  {
    id: 'protected-group',
    context: GroupPrivacyStatus.Protected,
    label: 'Protected (Coming Soon)',
    disabled: true, // disabling for now until we support public groups.
    description: 'Protected groups can be viewed by anyone, but have rules in order to join (like requiring a specific email domain). They will show up in search results, and will be displayed on member\'s public profiles.',
  },
  {
    id: 'private-group',
    context: GroupPrivacyStatus.Private,
    label: 'Private',
    description: 'Private groups cannot be viewed by anyone other than it\'s members. They will also not show up in search results, or be displayed on member\'s public profiles.',
  },
];

const defaultMatchPercent = 100;
const defaultMatchMax = 150;

/**
 * the group settings modal found on the admin/groups
 * page. could not reuse the one from group account page
 * due to the need for supporting creating a new group
 * and access to the groupsModel
 */
const GroupModalBase: React.FC<IProps> = ({
  allowSettingOwner,
  className = '',
  group,
  groupsModel,
  isOpen,
  onClose,
}) => {
  const toaster = useToaster();
  const errorMessages = useErrorMessages();
  const usersModel = useRef<UsersModel>(null);
  const [groupName, setGroupName] = useState(group?.name || '');
  const [groupCodeTimeout, setGroupCodeTimeout] = useState<number>(null);
  const groupCodeEngaged = useRef(false);
  const [groupCode, setGroupCode] = useState(group?.code || '');
  const [groupLogoUrl, setGroupLogoUrl] = useState(group?.logo || '');
  const [groupCodeAvailability, setGroupCodeAvailability] = useState<GroupCodeAvailability>(null);
  const [groupCodeError, setGroupCodeError] = useState('');
  const [owner, setOwner] = useState(group?.owner);
  const [showOwnerPicker, setShowOwnerPicker] = useState(false);
  const [groupPrivacyStatus] = useState(group?.settings?.privacyStatus || GroupPrivacyStatus.Private);
  const [allowInvites] = useState(!!group?.settings?.allowInvite);
  const [allowDomainRestriction, setAllowDomainRestriction] = useState(!!group?.settings?.allowDomainRestriction);
  const [restrictedDomains, setRestrictedDomains] = useState(group?.domains?.join(', ') || '');
  const [restrictedDomainError, setRestrictedDomainError] = useState('');
  const [allowSubgroups] = useState(!!group?.settings?.allowSubgroups);
  const [requireApproval] = useState(!!group?.settings?.approvalRequired);
  const [offsetMatching, setOffsetMatching] = useState(!!group?.settings?.matching?.enabled);
  const [offsetMatchPercent, setOffsetMatchPercent] = useState(group?.settings?.matching?.matchPercentage || defaultMatchPercent);
  const [offsetMatchPercentError, setOffsetMatchPercentError] = useState('');
  const [offsetMatchMax, setOffsetMatchMax] = useState(group?.settings?.matching?.maxDollarAmount || defaultMatchMax);

  useEffect(() => {
    if (offsetMatching) {
      setOffsetMatchPercent(group?.settings?.matching?.matchPercentage || defaultMatchPercent);
      setOffsetMatchMax(group?.settings?.matching?.maxDollarAmount || defaultMatchMax);
    }
  }, [offsetMatching]);

  useEffect(() => {
    if (!usersModel.current) {
      usersModel.current = allowSettingOwner
        ? new AdminUsersModel()
        : new UsersModel();
    }
  }, [showOwnerPicker]);

  const checkCode = useCallback(async () => {
    if (!!groupCodeAvailability || !!groupCodeError) return;

    if (!groupCode || groupCode === group?.code) {
      setGroupCodeAvailability(null);
      return;
    }

    try {
      const codeStatus = await groupsModel.checkCode(groupCode);

      if (!codeStatus) return;

      if (!codeStatus.isValid) {
        setGroupCodeError('Invalid code. Only letters, numbers, and hyphens (-) allowed.');
        return;
      }

      setGroupCodeAvailability(codeStatus.available);
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Checking Group Code Status',
        message: err.message,
      });
    } 
  }, [groupCode]);

  useEffect(() => {
    window.clearTimeout(groupCodeTimeout);
    setGroupCodeTimeout(window.setTimeout(checkCode, 300));
  }, [groupCode]);

  const generateRandomGroupCode = useCallback(async () => {
    try {
      const randCode = nanoid();
      const codeStatus = await groupsModel.checkCode(randCode);
      if (codeStatus.available === GroupCodeAvailability.Available && codeStatus.isValid) {
        setGroupCode(randCode);
        setGroupCodeAvailability(codeStatus.available);
        setGroupCodeError('');
      } else {
        generateRandomGroupCode();
      }
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Generating Random Code',
        message: err.message,
      });
    }
  }, []);

  const getPrivacyRadioButtons = useCallback(() => {
    const _privacyRadioButtons = privacyRadioButtons.map(r => ({
      ...r,
      checked: r.context === groupPrivacyStatus,
    }));

    return _privacyRadioButtons;
  }, [groupPrivacyStatus]);

  const onAddOwnerClick = useCallback(() => {
    setShowOwnerPicker(true);
  }, []); 

  const onAllowDomainRestrictionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAllowDomainRestriction(e.target.checked);
  }, []);

  const onCreateClick = useCallback(async () => {
    if (!verifyRequirementsMet()) return null;
    
    const allDomains = restrictedDomains
      .split(',')
      .map(d => d.trim())
      .filter(d => !!d);

    // remove any duplicate domains
    const domains = new Set(allDomains);

    try {
      await groupsModel.createGroup({
        owner: owner?._id,
        name: groupName,
        code: groupCode,
        logo: groupLogoUrl,
        settings: {
          ...defaultGroupSettings,
          privacyStatus: groupPrivacyStatus,
          allowDomainRestriction,
          matching: {
            enabled: offsetMatching,
            matchPercentage: offsetMatching ? offsetMatchPercent : null,
            maxDollarAmount: offsetMatching ? offsetMatchMax : null,
          },
        },
        domains: Array.from(domains),
      });

      toaster.push({ message: 'Group Created Successfully.' });
  
      onModalClose();
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Creating Group',
        message: err.message,
      });
    }
  }, [
    owner,
    groupName,
    groupCode,
    groupLogoUrl,
    restrictedDomains,
    offsetMatching, 
    offsetMatchPercent, 
    offsetMatchMax,
  ]);

  const onGroupCodeBlur = useCallback(() => {
    if (!groupCode) setGroupCodeError('A group code is required.');
  }, [groupCode]);

  const onGroupCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    groupCodeEngaged.current = true;
    setGroupCodeAvailability(null);
    setGroupCodeError('');
    // remove any invalid chars
    let val = e.target.value.split(/[^a-zA-Z0-9-]/gm).join('');
    if (val.length > MAX_CODE_LENGTH) val = val.slice(0, MAX_CODE_LENGTH);

    setGroupCode(val);
  }, []);

  // const onGroupCodeFocus = useCallback(() => {
  //   groupCodeEngaged.current = true;
  // }, [groupCode]);

  const onGroupDomainsChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRestrictedDomains(e.target.value);
    setRestrictedDomainError('');
  }, []);

  const onGroupNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  }, []);

  const onGroupLogoUrlChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setGroupLogoUrl(e.target.value);
  }, []);

  const onMatchAmountChange = useCallback((field: 'percent' | 'max') => (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim() === '') {
      (field === 'percent' ? setOffsetMatchPercent : setOffsetMatchMax)(null);
      return;
    }

    let val = parseFloat(e.target.value.split(/\D+/g).join(''));

    if (field === 'percent') {
      if (val > 100) val = 100;
      setOffsetMatchPercentError('');
    }

    (field === 'percent' ? setOffsetMatchPercent : setOffsetMatchMax)(val);
  }, []);

  const onModalClose = useCallback(() => {
    onClose();
  }, []);

  const onOfferOffsetMatchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setOffsetMatching(e.target.checked);
  }, []);

  const onOwnerPickerModalClose = useCallback(() => {
    setOwner(null);
    setShowOwnerPicker(false);
  }, []);

  const onOwnerSelected = useCallback((owner: UserModel) => {
    setOwner(owner);
    setShowOwnerPicker(false);
  }, []);

  const onRemoveOwnerClick = useCallback(() => {
    setOwner(null);
  }, []);

  const onUpdateClick = useCallback(async () => {
    if (!verifyRequirementsMet()) return null;
    
    const allDomains = restrictedDomains
      .split(',')
      .map(d => d.trim())
      .filter(d => !!d);

    // remove any duplicate domains
    const domains = new Set(allDomains);

    try {
      await group?.update({
        owner: owner?._id,
        name: groupName,
        code: groupCode,
        logo: groupLogoUrl,
        settings: {
          ...defaultGroupSettings,
          privacyStatus: groupPrivacyStatus,
          allowDomainRestriction,
          matching: {
            enabled: offsetMatching,
            matchPercentage: offsetMatching ? offsetMatchPercent : null,
            maxDollarAmount: offsetMatching ? offsetMatchMax : null,
          },
        },
        domains: Array.from(domains),
      });

      toaster.push({ message: 'Group Updated Successfully. ' });
      onModalClose();
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Updating Group',
        message: err.message,
      });
    }
  }, [
    owner,
    groupName,
    groupCode,
    groupLogoUrl,
    restrictedDomains,
    offsetMatching, 
    offsetMatchPercent, 
    offsetMatchMax,
  ]);

  const renderDomainRestrictionField = () => {
    if (!allowDomainRestriction) return null;
    
    return (
      <Setting className='indent'>
        <TextField
          className='text-field setting-text-field'
          ariaDescribedBy='group-email-domain-restrictions-description'
          id='group-email-domain-restrictions-field'
          label='Allowed Email Domains'
          labelHidden
          onBlur={ validateRestrictedDomains }
          onChange={ onGroupDomainsChange }
          placeholder='Enter the allowed email domains'
          value={ restrictedDomains }
        />
        {
          !!restrictedDomainError
            ? <p className='input-field-error'>{ restrictedDomainError }</p>
            : <p className='input-field-support-text' id='group-email-domain-restrictions-description'>If there are multiple, separate each with a comma. (example: theimpactkarma.com, protonmail.com)</p>
        }
      </Setting>
    );
  };

  const renderGroupCodeSubFieldSection = () => {
    let subText: JSX.Element;
    if (!!groupCodeError) {
      subText = <p className='code-availability input-field-error'>{ groupCodeError }</p>;
    }

    if (!subText && !!groupCodeAvailability && groupCodeEngaged.current) {
      subText = groupCodeAvailability === GroupCodeAvailability.Available
        ? <p className='code-availability input-field-success'>This code is available.</p>
        : <p className='code-availability input-field-error'>This code is not available.</p>;
    }

    return (
      <GroupCodeSubFieldSection>
        { subText || <div /> }
        <Button
          className='random-code-cta'
          disabled={ groupsModel.checkingCode }
          kind={ ButtonKind.PrimaryWithIcon }
          onClick={ generateRandomGroupCode }
        >
          Get Random Code
        </Button>
      </GroupCodeSubFieldSection>
    );
  };

  const renderMatchFields = () => {
    if (!offsetMatching) return null;

    return (
      <MatchSetting className='indent'>
        <MatchTextFieldContainer>
          <TextField
            className='text-field'
            id='group-match-percent-field'
            label='Percentage'
            rightAccessory={ <Accessory>%</Accessory> }
            onBlur={ validateMatchPercent }
            onChange={ onMatchAmountChange('percent') }
            value={ `${offsetMatchPercent >= 0 ? offsetMatchPercent : ''}` }
          />
          { !!offsetMatchPercentError && <p className='input-field-error'>{ offsetMatchPercentError }</p> }
        </MatchTextFieldContainer>
        <MatchTextFieldContainer>
          <TextField
            className='text-field'
            id='group-match-max-field'
            label='Max Amount (Per Member)'
            ariaDescribedBy='max-amount-description'
            leftAccessory={ <Accessory>$</Accessory> }
            onChange={ onMatchAmountChange('max') }
            value={ `${offsetMatchMax >= 0 ? offsetMatchMax : ''}` }
          />
          <p className='input-field-support-text' id='max-amount-description'>If left empty, or set to 0, no max will be set on this pledge.</p>
        </MatchTextFieldContainer>
      </MatchSetting>
    );
  };

  const renderOwner = () => {
    if (!allowSettingOwner) return null;

    if (!owner) {
      return (
        <Button
          kind={ ButtonKind.PrimaryGhost }
          onClick={ onAddOwnerClick }
        >
          + Add Owner
        </Button>
      );
    }

    return (
      <OwnerContainer>
        <Label>Owner</Label>
        <OwnerPill>
          { owner.name }
          <Button
            className='remove-owner-button'
            kind={ ButtonKind.Blank }
            label='Remove Owner'
            onClick={ onRemoveOwnerClick }
          >
            <XIcon />
          </Button>
        </OwnerPill>
      </OwnerContainer>
    );
  };

  const validateMatchPercent = useCallback(() => {
    if (!offsetMatchPercent || offsetMatchPercent < 1) setOffsetMatchPercentError('A match percentage is required.');
  }, [offsetMatchPercent]);

  const validateRestrictedDomains = useCallback(() => {
    if (allowDomainRestriction && restrictedDomains.length === 0) {
      setRestrictedDomainError('At least one domain is required in order to allow restricted domains.');
      return;
    }

    const allInvalidDomains = restrictedDomains
      .split(',')
      .map(d => d.trim())
      .filter(d => !!d)
      .filter(d => !d.includes('.'));

    // remove any duplicates
    const invalidDomains = new Set(allInvalidDomains);

    if (!!invalidDomains.size) {
      const isPlural = invalidDomains.size > 0;
      setRestrictedDomainError(`The following domain${isPlural ? 's' : ''} ${isPlural ? 'are' : 'is'} invalid: ${Array.from(invalidDomains).join(', ')}. Ensure you entered a valid top-level domain (like .com or .io)`);
    }
  }, [restrictedDomains]);

  const verifyRequirementsMet = useCallback(() => {
    if (
      !groupName
      || !groupCode
      || !!restrictedDomainError
      || (allowDomainRestriction && (!restrictedDomains || !restrictedDomains.length))
      || !!offsetMatchPercentError
    ) return false;

    return true;
  }, [
    groupName,
    groupCode, 
    allowDomainRestriction, 
    restrictedDomains, 
    restrictedDomainError,
    offsetMatchPercentError,
  ]);

  if (!isOpen) return null;

  return (
    <CustomModal
      className={ className }
      title={ `${!!group ? 'Edit' : 'Create' } Group` }
      isOpen={ isOpen }
      onClose={ onModalClose }
    >
      <GroupModalWrapper>
        <BaseInfoContainer>
          <div className='logo-container'>
            <GroupLogo group={ group } />
          </div>
          <div>
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Large }
              id='group-name-field'
              label='Group Name'
              onChange={ onGroupNameChange }
              placeholder='Enter a group name'
              value={ groupName }
            />
            <TextField
              className={ 'text-field group-logo-field-input' }
              id='group-logo-field'
              label='Group Logo URL'
              onChange={ onGroupLogoUrlChange }
              placeholder='Enter url for group logo'
              value={ groupLogoUrl }
            />
            <TextField
              className={ 'text-field group-code-field-input' }
              id='group-code-field'
              label='Group Code'
              onBlur={ onGroupCodeBlur }
              onChange={ onGroupCodeChange }
              placeholder='Enter a group code'
              value={ groupCode }
            />
            { renderGroupCodeSubFieldSection() }
            { renderOwner() }
          </div>
        </BaseInfoContainer>
        <SettingsContainer>
          <PrivacyStatusContainer>
            <Label>Group Privacy Status:</Label>
            <RadioGroup
              name='group-privacy-level'
              radioButtons={ getPrivacyRadioButtons() }
              stack={ RadioGroupStack.Horizontal }
            />
          </PrivacyStatusContainer>
          <Setting>
            <Checkbox
              label='Allow Invites (Coming Soon)'
              id='allow-invites'
              ariaDescribedBy='invite-email-description'
              checked={ allowInvites }
              disabled={ true } // disabling by default until invites are supported
            />
            <p id='invite-email-description' className='input-field-support-text'>Allows invite emails to be sent to individuals. This invite will override any other group access requirements that have been set.</p>
          </Setting>
          <Setting>
            <Checkbox
              label='Allowed Email Domain Restrictions'
              id='allow-email-restrictions'
              ariaDescribedBy='allow-email-restrictions-description'
              checked={ allowDomainRestriction }
              onChange={ onAllowDomainRestrictionChange }
            />
            <p id='allow-email-restrictions-description' className='input-field-support-text'>Restrict who can join this group by requiring members to have emails from specific domains.</p>
            { renderDomainRestrictionField() }
          </Setting>
          <Setting>
            <Checkbox
              label='Allow Sub-Groups (Coming Soon)'
              ariaDescribedBy='allow-subgroups-description'
              id='allow-sub-groups'
              checked={ allowSubgroups }
              disabled={ true } // disabling by default until invites are supported
            />
            <p id='allow-subgroups-description' className='input-field-support-text'>Allows smaller teams within this group.</p>
          </Setting>
          <Setting>
            <Checkbox
              label='Member Approval Required (Coming Soon)'
              id='member-approval-required'
              ariaDescribedBy='member-approval-description'
              checked={ requireApproval }
              disabled={ true } // disabling by default until invites are supported
            />
            <p id='member-approval-description' className='input-field-support-text'>When a user tries to join the group, an admin (or higher) within this group must first approve the request before the member is allowed to join.</p>
          </Setting>
          <Setting>
            <Checkbox
              label='Select Offer Carbon Offset Matching'
              id='offer-carbon-matching'
              ariaDescribedBy='offer-matching-description'
              checked={ offsetMatching }
              onChange={ onOfferOffsetMatchChange }
            />
            <p id='offer-matching-description' className='input-field-support-text'>Pledge to match any carbon offsets purchased by the members of this group.</p>
            { renderMatchFields() }
          </Setting>
        </SettingsContainer>
        {
          !!group && (
            <>
              <div className='header'>Members</div>
              <GroupMembersList group={ group } />
            </>
          )
        }
        <CTAs
          className='primary-ctas-container'
          ctas={ [
            {
              id: 'cancel-group-cta',
              text: 'Cancel',
              kind: ButtonKind.SecondaryWithIcon,
              onClick: onModalClose,
            },
            {
              disabled: !verifyRequirementsMet(),
              id: 'save-group-cta',
              text: `${!!group ? 'Update' : 'Create'} Group`,
              kind: ButtonKind.Primary,
              onClick: !!group ? onUpdateClick : onCreateClick,
            },
          ] }
        />
      </GroupModalWrapper>
      {
        showOwnerPicker && (
          <UserPickerModal
            isOpen={ showOwnerPicker }
            onUserSelected={ onOwnerSelected }
            onClose={ onOwnerPickerModalClose }
            usersModel={ usersModel.current }
          />
        )
      }
    </CustomModal>
  );
};

export const GroupModal = observer(GroupModalBase);
