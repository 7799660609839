import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { H1 } from '../../../styles/components/header';
import { MainContainer, PageControlContainer, SelectField, WebAnalyticsContainer } from './styles';
import { Button } from '../../../components/Button';
import { WebAnalyticsModel } from '../../../models/web-analytics';
import { observer } from 'mobx-react';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { Page } from './Page';
import { useToaster } from '../../../contexts/toaster-store';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { CreateUpdateWebAnalyticsModal } from '../../../components/modals/CreateUpdateWebAnalyticsModal';

interface IProps {
  className?: string;
}

const WebAnalyticsBase: React.FC<IProps> = ({
  className = '',
}) => {
  const [selectedPage, setSelectedPage] = useState({ key: 'home_page', value: 'Home Page' });
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const analyticsModel = useRef(new WebAnalyticsModel()).current;
  const toaster = useToaster();
  const errorMessages = useErrorMessages();
  
  useEffect(() => {
    loadWebAnalyticsLocations();
    loadWebAnalytics(selectedPage.key);
  }, []);

  const loadWebAnalytics = useCallback(async (selected: string) => {
    try {
      await analyticsModel.loadWebAnalytics(selected);
    } catch (err: any) {
      errorMessages.push({
        title: 'Something went wrong',
        message: 'Something went wrong while trying to load analytics, please try again',
      });
    }
  }, []);

  const loadWebAnalyticsLocations = useCallback(async () => {
    try {
      await analyticsModel.loadWebAnalyticsLocations();
    } catch (err: any) {
      errorMessages.push({
        title: 'Something went wrong',
        message: 'Something went wrong while trying to load analytics, please try again',
      });
    }
  }, []);

  const deleteWebAnalytic = useCallback(async (id: string) => {
    try {
      await analyticsModel.delete(id);
      toaster.push({ message: 'Analytic removed successfully.' });
    } catch (err: any) {
      errorMessages.push({
        title: 'Something went wrong',
        message: 'Something went wrong while trying to delete analytic, please try again',
      });
    }
  }, []);

  const onPageSelect = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPage({ key: event.target.value, value: event.target.options[event.target.selectedIndex].text });
    loadWebAnalytics(event.target.value);
  }, []);

  const onCloseCreateUpdateModal = useCallback(() => {
    setCreateModalOpen(false);
  }, []);

  const onCreateAnalyticClick = useCallback(() => {
    setCreateModalOpen(true);
  }, []);

  const renderSelectInput = useMemo(() => (
    <SelectField onChange={ onPageSelect }>
      {
        analyticsModel.analyticsLocations.map((item) => (
          <option
            key={ item._id }
            value={ item.key }
          >
            { item.value }
          </option>
        ))
      }
    </SelectField>
  ), [analyticsModel.analyticsLocations]);

  const renderAnalyticsContent = useMemo(() => {
    if (!!analyticsModel.busy) return <LoadingSpinner className='loading-spinner' />;

    return (
      <MainContainer>
        <Page
          page={ selectedPage.value }
          analytics={ analyticsModel.analytics }
          deleteWebAnalyticClick={ deleteWebAnalytic }
          analyticsModel={ analyticsModel }
        />
      </MainContainer>
    );
  }, [selectedPage, analyticsModel.analytics, analyticsModel.busy]);

  return (
    <WebAnalyticsContainer className={ className } title='Web Analytics'>
      <H1>Web Analytics</H1>
      <PageControlContainer>
        { renderSelectInput }
        <Button
          onClick={ onCreateAnalyticClick }
        >
          Add New Analytic Event
        </Button>
      </PageControlContainer>
      { renderAnalyticsContent }
      <CreateUpdateWebAnalyticsModal
        onClose={ onCloseCreateUpdateModal }
        isOpen={ createModalOpen }
        analyticsModel={ analyticsModel }
        selectedPage={ selectedPage }
      />
    </WebAnalyticsContainer>
  );
};

export const WebAnalytics = observer(WebAnalyticsBase);
