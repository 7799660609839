import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '../../../components/Button';
import { ButtonKind } from '../../../components/Button/styles';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { H1 } from '../../../styles/components/header';
import { ArticlesContainer, ButtonRow } from './styles';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { ArticleItem } from './ArticleItem';
import { CreateUpdateArticleModal } from '../../../components/modals/CreateUpdateArticleModal';
import { ArticleModel, ArticlesModel } from '../../../models/article';

interface IProps {
  className?: string;
}

const ArticleBase: React.FC<IProps> = ({
  className = '',
}) => {
  const articlesModel = useRef(new ArticlesModel()).current;
  const errorMessages = useErrorMessages();
  const [createArticleModalOpen, setCreateArticleModalOpen] = useState(false);

  const getArticles = async () => {
    try {
      await articlesModel.loadArticles();
    } catch (err: any) {
      errorMessages.push({
        title: 'Error loading articles',
        message: err.message,
      });
    }
  };

  useEffect(() => {
    getArticles();
  }, []);

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = async () => {
    try {
      await articlesModel.loadTemplates();
      await articlesModel.loadHeaderTemplates();
    } catch (err: any) {
      errorMessages.push({
        title: 'Error loading article templates',
        message: err.message,
      });
    }
  };

  const onModalClose = () => {
    setCreateArticleModalOpen(false);
    getArticles();
  };

  const onCreateClick = () => {
    setCreateArticleModalOpen(true);
    getArticles();
  };
  
  const currentArticles = useMemo (() => {
    if (!!articlesModel.busy) return <LoadingSpinner />;
    if (!articlesModel.articles.length) return null;

    return articlesModel.articles.map((article: ArticleModel) => (
      <ArticleItem key={ article._id } article={ article } articlesModel={ articlesModel } onClose={ onModalClose } />
    ));
  }, [articlesModel.articles]);

  return (
    <ArticlesContainer className={ className } title='Articles'>
      <H1>Articles</H1>
      <ButtonRow>
        <a href='https://docs.google.com/presentation/d/11wr6Dgdu4qkNkgDDxn3w_N9I_SbEyHq1RUfGfxNMbFw/edit?usp=sharing' target='_blank' rel='noreferrer'>
          Guide to Articles
        </a>
        <Button
          kind={ ButtonKind.Primary }
          onClick={ onCreateClick }
          className='create-article-button'
        >
          Create Article
        </Button>
        <CreateUpdateArticleModal
          isOpen={ createArticleModalOpen }
          onClose={ onModalClose }
          articlesModel={ articlesModel }
        />
      </ButtonRow>
      { currentArticles }
    </ArticlesContainer>
  );
};

export const Articles = observer(ArticleBase);
