import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { Checkbox } from '../../../components/Checkbox';
import { CompanyModal } from '../../../components/modals/CompanyModal';
import { TextField, TextFieldKind } from '../../../components/TextField';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { CompaniesModel, CompanyModel } from '../../../models/companies';
import { H1 } from '../../../styles/components/header';
import { CompanyItem } from './CompanyItem';
import { CompanyItemSkeleton } from './CompanyItem/skeleton';
import { CompaniesContainer, CompaniesInfoContainer, CompaniesList, CompaniesListContainer, MainContainer, SearchContainer } from './styles';

interface IProps {
  className?: string;
}

interface IParamsObject {
  companyName?: string;
  limit?: number;
  logo?: string;
}

const CompaniesBase: React.FC<IProps> = ({
  className = '',
}) => {
  const errorMessages = useErrorMessages();
  const companiesModel = useRef(new CompaniesModel(null, true)).current;
  const [companiesSearchQuery, setCompaniesSearchQuery] = useState('');
  const [searchThrottle, setSearchThrottle] = useState<number>(null);
  const [selectedCompany, setSelectedCompany] = useState<CompanyModel>(null); 
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showNoLogoOnly, setShowNoLogoOnly] = useState(false);

  const loadCompanies = useCallback((refresh?: boolean) => async () => {
    const paramsObject: IParamsObject = showNoLogoOnly ? { logo: 'null' } : { };
    if (companiesSearchQuery) paramsObject.companyName = companiesSearchQuery;

    try {
      await companiesModel.companies[refresh ? 'refresh' : 'loadMore'](paramsObject);
    } catch (err: any) {
      errorMessages.push({
        title: 'Error Getting Companies',
        message: err,
      });
    }
  }, [showNoLogoOnly, companiesSearchQuery]);

  useEffect(() => {
    loadCompanies(true)();
  }, []);

  useEffect(() => {
    window.clearTimeout(searchThrottle);

    if (companiesModel.companies.firstPageLoaded) {
      setSearchThrottle(window.setTimeout(() => {
        companiesModel.companies.refresh({ companyName: !!companiesSearchQuery ? companiesSearchQuery : null });
      }, 400));
    }
  }, [companiesSearchQuery]);

  useEffect(() => {
    setShowCompanyModal(!!selectedCompany);
  }, [selectedCompany]);

  useEffect(() => {
    loadCompanies(true)();
  }, [showNoLogoOnly]);

  const onCompaniesSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCompaniesSearchQuery(e.target.value);
  }, []);

  const onCompanyClick = useCallback((company: CompanyModel) => () => {
    setSelectedCompany(company);
  }, []);
  
  const onCompanyModalClose = useCallback(() => {
    setSelectedCompany(null);
    setShowCompanyModal(false);
  }, []);

  const onNoLogosCheckboxChange = useCallback(() => {
    setShowNoLogoOnly(!showNoLogoOnly);
  }, [showNoLogoOnly]);

  const renderCompanies = () => {
    let companies: JSX.Element[] = [];

    if (companiesModel.companies.results.length > 0) {
      companies = companiesModel.companies.results.map(company => (
        <CompanyItem
          key={ company._id }
          className='company-item'
          company={ company }
          onClick={ onCompanyClick(company) }
        />
      ));
    } else {
      companies.push(<div key='no-companies' className='no-companies'>No companies found</div>);
    }

    if (companiesModel.companies.busy) {
      if (companiesModel.companies.firstPageLoaded) {
        companies.push((
          <div key='loading-companies-spinner'>
            loading more...
          </div>
        ));
      } else {
        const skeletons: JSX.Element[] = [];

        for (let i = 0; i < 25; i++) {
          skeletons.push(<CompanyItemSkeleton key={ `company-item-skele-${i}` } />);
        }

        companies = [...companies, ...skeletons];
      }
    }

    if (!companiesModel.companies.allResultsFetched && !companiesModel.companies.busy) {
      companies.push(<Waypoint key='waypoint' onEnter={ loadCompanies() } topOffset={ 200 } />);
    }

    return companies;
  };

  return (
    <CompaniesContainer className={ className } title='Companies'>
      <H1>Companies</H1>
      <MainContainer>
        <CompaniesListContainer>
          <SearchContainer>
            <TextField
              fieldKind={ TextFieldKind.Pill }
              id='companies-search-input'
              label='Search Companies'
              labelHidden
              onChange={ onCompaniesSearchChange }
              placeholder='Search Companies'
              value={ companiesSearchQuery }
            />
          </SearchContainer>
          <Checkbox
            className='no-logo-only'
            label='Show only companies without logo'
            onChange={ onNoLogosCheckboxChange }
            checked={ showNoLogoOnly }
          />
          <CompaniesInfoContainer>
            { companiesModel.companies.total } Companies
          </CompaniesInfoContainer>
          <CompaniesList>
            { renderCompanies() }
          </CompaniesList>
        </CompaniesListContainer>
      </MainContainer>
      <CompanyModal
        isOpen={ showCompanyModal }
        company={ selectedCompany }
        onClose={ onCompanyModalClose }
      />
    </CompaniesContainer>
  );
};

export const Companies = observer(CompaniesBase);
