import React from 'react';
import { UserModel } from '../../../models/users';
import { ButtonKind } from '../../Button/styles';
import { ConfirmationModal } from '../ConfirmationModal';
import { UserInfo } from './styles';

interface IProps {
  className?: string;
  isOpen: boolean;
  user: UserModel;
  onDelete(): void;
  onClose(): void;
}

export const DeleteUserConfirmationModal: React.FC<IProps> = ({
  user,
  onClose,
  onDelete,
  ...restProps
}) => 
{
  const confirmationModalBody = <UserInfo className='confirmation-modal-body'>
    <ul>
      <li>{ user?.name }</li>
      <li>{ user?.primaryEmail.email }</li>
    </ul>
    <br /> 
    <p className='warning'> 
    This action is permanent and destroys data associated with this user. 
    </p>
  </UserInfo>;

  return <ConfirmationModal
    { ...restProps }
    title='Delete this user?'
    className='modal'
    body={ confirmationModalBody }
    ctas={ [
      {
        id: 'confirm-delete',
        text: 'Delete',
        kind: ButtonKind.Danger,
        onClick: onDelete,
      },
      {
        id: 'cancel-delete',
        text: 'Cancel',
        kind: ButtonKind.SecondaryWithIcon,
        onClick: onClose,
      },
    ] }
    onClose={ onClose }
  />;
};
