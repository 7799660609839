import React from 'react';
import { CompanyLogoSkeletonContainer, ICompanyLogoProps } from './styles';

interface IProps extends ICompanyLogoProps {
  className?: string;
}

export const CompanyLogoSkeleton: React.FC<IProps> = ({
  className = '',
}) => (
  <CompanyLogoSkeletonContainer
    circle
    className={ className }
  />
);
