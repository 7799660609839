import { observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React, { ChangeEvent, ChangeEventHandler, FocusEvent, FocusEventHandler, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { withTheme } from 'styled-components';
import { useErrorMessages } from '../../contexts/error-messages-store';
import { CompaniesModel, CompanyModel } from '../../models/companies';
import { IThemeProps } from '../../styles/themes';
import { Button } from '../Button';
import { ButtonKind } from '../Button/styles';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { SearchIcon } from '../svgs/icons/SearchIcon';
import { XIcon } from '../svgs/icons/XIcon';
import { TextField, TextFieldKind } from '../TextField';
import { ArticleSearchContainer, ArticleSearchWrapper, LoadingSpinnerContainer, NoResults, ResultsDropdown } from './styles';

interface IProps extends IThemeProps {
  autofocus?: boolean;
  className?: string;
  companyIdUpdate(id: string): void;
  onClearBlur?: FocusEventHandler<HTMLButtonElement>;
  onClearClick?(): void;
  onClearFocus?: FocusEventHandler<HTMLButtonElement>;
  onCompanyResultBlur?: FocusEventHandler<HTMLAnchorElement>;
  onCompanyResultClick?(): void;
  onCompanyResultFocus?: FocusEventHandler<HTMLAnchorElement>;
  onSearchBlur?: FocusEventHandler<HTMLInputElement>;
  onSearchChange?: ChangeEventHandler<HTMLInputElement>;
  onSearchFocus?: FocusEventHandler<HTMLInputElement>;
  onSearchRef?(ref: HTMLInputElement): void;
}

const ArticleCompanySearchBase: React.FC<IProps> = ({
  autofocus,
  className = '',
  companyIdUpdate,
  onClearBlur,
  onClearClick,
  onClearFocus,
  onSearchBlur,
  onSearchChange,
  onSearchFocus,
  onSearchRef,
  theme,
}) => {
  const navigate = useNavigate();
  const errorMessages = useErrorMessages();
  const companiesModel = useRef(new CompaniesModel({ limit: 10 })).current;
  const _id = useRef(nanoid(12)).current;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [query, setQuery] = useState('');
  const [searchTimeout, setSearchTimeout] = useState<number>(0);
  const [focused, setFocused] = useState(false);

  const loadMore = () => {
    let errorFound = false;

    try {
      new RegExp(query, 'g');
    } catch (err) {
      errorFound = true;
      errorMessages.push({
        title: 'Invalid Search Query',
        message: 'You have entered an invalid search query. Please try again.',
      });
    }

    if (errorFound) return;

    companiesModel.companies.loadMore({ companyName: !!query ? query : null })
      .catch(err => {
        errorMessages.push({
          title: 'Error Loading Companies',
          message: err.message,
        });
      });
  };

  useEffect(() => {
    if (!!query) {
      window.clearTimeout(searchTimeout);
      setSearchTimeout(window.setTimeout(() => {
        let errorFound = false;
      
        try {
          new RegExp(query, 'g');
        } catch (err) {
          errorFound = true;
          errorMessages.push({
            title: 'Invalid Search Query',
            message: 'You have entered an invalid search query. Please try again.',
          });
        }
      
        if (errorFound) return (): void => null;

        companiesModel.companies.refresh({ companyName: !!query ? query : null })
          .catch(err => {
            errorMessages.push({
              title: 'Error Loading Companies',
              message: err.message,
            });
          });
      }, 300));
    } else {
      window.clearTimeout(searchTimeout);
      companiesModel.companies.reset();
    }
  }, [query]);

  useEffect(() => {
    if (autofocus && inputRef.current !== null) inputRef.current.focus();
  }, [inputRef.current]);

  const _onClearBlur = useCallback((e: FocusEvent<HTMLInputElement, Element>) => {
    setFocused(false);
    onClearBlur?.(e as unknown as FocusEvent<HTMLButtonElement, Element>);
  }, []);

  const _onClearClick = useCallback(() => {
    setQuery('');
    if (inputRef.current !== null) inputRef.current.focus();
    onClearClick?.();
  }, []);

  const _onClearFocus = useCallback((e: FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true);
    onClearFocus?.(e as unknown as FocusEvent<HTMLButtonElement, Element>);
  }, []);

  const _onSearchBlur = useCallback((e: FocusEvent<HTMLInputElement, Element>) => {
    setTimeout(() => {
      setFocused(false);
      onSearchBlur?.(e);
    }, 250);
  }, []);

  const _onSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    onSearchChange?.(e);
  }, []);

  const _onSearchFocus = useCallback((e: FocusEvent<HTMLInputElement, Element>) => {
    setFocused(true);
    onSearchFocus?.(e);
  }, []);

  const _onSearchRef = useCallback((ref: HTMLInputElement) => {
    inputRef.current = ref;
    onSearchRef?.(inputRef.current);
  }, []);

  const _onSearchResultClick = useCallback((company: CompanyModel) => () => {
    if (!!company?._id) companyIdUpdate(company?._id);
  }, []);

  const onSearchKeyPress = useCallback((e: KeyboardEvent) => {
    switch (e.key) {
      case 'Enter':
        if (!!query) {
          if (inputRef.current !== null) inputRef.current.blur();
          navigate(`/search?query=${window.encodeURI(query)}`);
        }
        break;
      default:
        break;
    }

  }, [query]);

  const renderClearIcon = useCallback(() => (
    <Button
      kind={ ButtonKind.Blank }
      label='Clear Search'
      onClick={ _onClearClick }
      onBlur={ _onClearBlur as unknown as FocusEventHandler<HTMLButtonElement> }
      onFocus={ _onClearFocus as unknown as FocusEventHandler<HTMLButtonElement> }
    >
      <XIcon stroke={ theme.colors.darkGray2 } />
    </Button>
  ), []);

  const renderResults = () => {
    if (!focused || !query || (!companiesModel.companies.busy && !companiesModel.companies.firstPageLoaded)) return null;

    let content: JSX.Element[] = [];

    if (companiesModel.companies.results.length) {
      content = companiesModel.companies.results.map(company => (
        <div
          className='company-search-result-item'
          key={ company._id }
          onClick={ _onSearchResultClick(company) }
        >
          { company.companyName }
        </div>
      ));
    } else if (!companiesModel.companies.busy && companiesModel.companies.firstPageLoaded) {
      content.push((
        <NoResults key='no-companies'>
          <span id='no-company-results'>
            No companies found
          </span>
        </NoResults>
      ));
    }

    if (companiesModel.companies.busy) {
      content.push((
        <LoadingSpinnerContainer key='loading-spinner'>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      ));
    }

    if (!companiesModel.companies.allResultsFetched && !companiesModel.companies.busy) {
      content.push(<Waypoint key='waypoint' onEnter={ loadMore } topOffset={ 200 } />);
    }

    return (
      <ResultsDropdown>
        { content }
      </ResultsDropdown>
    );
  };

  return (
    <ArticleSearchContainer className={ className }>
      <ArticleSearchWrapper>
        <TextField
          id={ _id }
          className='company-search-text-field'
          placeholder='Search'
          fieldKind={ TextFieldKind.Pill }
          label='Search for Company to Set Company Id:'
          labelHidden={ false }
          leftAccessory={ <SearchIcon stroke={ theme.colors.darkGray2 } /> }
          rightAccessory={ !!query ? renderClearIcon() : undefined }
          onRef={ _onSearchRef }
          value={ query }
          onBlur={ _onSearchBlur }
          onChange={ _onSearchChange }
          onKeyPress={ onSearchKeyPress }
          onFocus={ _onSearchFocus }
        />
        { renderResults() }
      </ArticleSearchWrapper>
    </ArticleSearchContainer>
  );
};

const ArticleCompanySearchObserver = observer(ArticleCompanySearchBase);
export const ArticleCompanySearch = withTheme(ArticleCompanySearchObserver);
