import styled from 'styled-components';
import { FlexHorizontalCenter } from '../../styles/styles';

export const MainAdminContainerContainer = styled.div`
  ${ FlexHorizontalCenter }

  .side-nav {
    min-width: 300px;
    max-width: 300px;
  }

  .main-content {
    padding: 20px;
    margin-left: 300px;
    width: 100%;
  }
`;
