import styled from 'styled-components';
import { FlexCol } from '../../../styles/styles';
import { CustomModal } from '../CustomModal';

export const AnalyticDetailsContainer = styled.div``;

export const CreateUpdateAnalyticModalContainer = styled(CustomModal)`
  ${ FlexCol }
  width: 75vw;
  height: 70vh;
  overflow: hidden;

  .custom-modal-body {
    overflow: scroll;
    overflow-x: hidden;
  }

  .modal-header {
    margin-bottom: 20px;
  }

  .text-field {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const AnalyticInfoContainer = styled.div`
  flex-grow: 1;
`;

export const BannerContainer = styled.div`
  .banner-dropdown {
    max-width: 300px;
  }
`;

export const InputContainer = styled.div`
  padding-bottom: 20px;
    
  .regular-label {
    font-weight: 600;
  }
`;

export const SelectField = styled.select`
  border-radius: 999px;
  margin-top: 20px;
  min-width: 250px;
  padding: 10px;
`;
