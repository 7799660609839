import styled from 'styled-components';
import { FlexHorizontalCenter } from '../../styles/styles';

export const UserItemContainer = styled.div`
  ${ FlexHorizontalCenter }
  justify-content: space-between;
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray1};
  border-radius: 20px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.clickable:hover {
    border: 1px solid ${({ theme }) => theme.colors.darkGray1};
    transition: border .25s ease-in-out
  }

  .base-info-container {
    ${ FlexHorizontalCenter }
    min-width: 60%;
    max-width: 60%;
    overflow: hidden;

    &.full {
      min-width: 100%;
      max-width: 100%;
    }

    p {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .avatar {
    min-width: 70px;
    max-width: 70px;
    min-height: 70px;
    max-height: 70px;
    margin-right: 10px;
  }

  .name {
    text-transform: capitalize;
  }

  .email {
    color: ${({ theme }) => theme.colors.green};
  }

  .delete-button {
    background-color: ${({ theme }) => theme.colors.danger};
  }

  .date-joined {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.lightGray1};
    line-height: 1.2em;
  }

  .clickable:hover {
    cursor: pointer;
  }
`;

export const UserItemSkeletonContainer = styled(UserItemContainer)`
  .user-name {
    width: 300px;
  }

  .user-info {
    width: 125px;
  }
`;
