import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { observer } from 'mobx-react';
import React, { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { useToaster } from '../../../contexts/toaster-store';
import { IPromoEvents, IPromoTypes, IPromoUpdate, PromoModel, PromosModel } from '../../../models/promos';
import { ButtonKind } from '../../Button/styles';
import { Checkbox } from '../../Checkbox';
import { Dropdown } from '../../Dropdown';
import { TextField, TextFieldSize } from '../../TextField';
import { DateContainer, DatesSection, DatesWrapper, PromoDataContainer, PromoEventsContainer, PromoInfoContainer, PromoModalContainer, PromoTypeContainer, Row } from './styles';
import { isDaylightSavingsTime } from '../../../lib/dates';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  className?: string;
  isOpen: boolean;
  onClose(): void;
  promo?: PromoModel;
  promosModel: PromosModel;
}

const promoTypesOptions = [
  {
    id: 'cashback',
    text: 'Cashback',
    context: IPromoTypes.CASHBACK,
  },
  {
    id: 'giftcard',
    text: 'Gift Card',
    context: IPromoTypes.GIFTCARD,
  },
  {
    id: 'other',
    text: 'Other',
    context: IPromoTypes.OTHER,
  },
];

const PromoModalBase: React.FC<IProps> = ({
  className = '',
  isOpen,
  onClose,
  promo,
  promosModel,
}) => {
  const promoModel = useRef(new PromoModel(null, true)).current;
  const toaster = useToaster();
  const errorMessages = useErrorMessages();
  const [amount, setAmount] = useState(promo?.amount.toString() || '0');
  const [campaign, setCampaign] = useState(promo?.campaign?.name || '');
  const [disclaimerText, setDisclaimerText] = useState(promo?.disclaimerText || '');
  const [enabled, setEnabled] = useState(promo?.enabled || false);
  const [endDate, setEndDate] = useState(!!promo?.endDate ? dayjs(promo?.endDate).utc().format() : null);
  const [events, setEvents] = useState(promo?.events || []);
  const [headerText, setHeaderText] = useState(promo?.headerText || '');
  const [limit, setLimit] = useState(promo?.limit.toString() || '0');
  const [name, setName] = useState(promo?.name || '');
  const [promoText, setPromoText] = useState(promo?.promoText || '');
  const [startDate, setStartDate] = useState(!!promo?.startDate ? dayjs(promo?.startDate).utc().format() : null);
  const [successText, setSuccessText] = useState(promo?.successText|| '');
  const [type, setType] = useState(promo?.type || IPromoTypes.OTHER);
  const [imageUrl, setImageUrl] = useState(promo?.imageUrl || '');
  const estOffsetTime = useMemo(() => {
    if (isDaylightSavingsTime()) return 4;
    return 5;
  }, []);

  const resetModal = () => {
    setAmount('0');
    setCampaign('');
    setDisclaimerText('');
    setEnabled(false);
    setEndDate(null);
    setEvents([]);
    setHeaderText('');
    setImageUrl('');
    setLimit('0');
    setName('');
    setPromoText('');
    setStartDate(null);
    setSuccessText('');
    setType(IPromoTypes.OTHER);
  };

  const changesFound = () => {
    let changes = false;
    // update promo
    if (!!promo) {
      if (name !== promo.name || limit !== promo.limit.toString() || enabled !== promo.enabled || promoText !== promo.promoText || disclaimerText !== promo.disclaimerText || amount !== promo.amount.toString() || campaign !== promo?.campaign?.name || headerText !== promo?.headerText || type !== promo?.type || successText !== promo?.successText || startDate !== dayjs(promo?.startDate).utc().format()|| endDate !== dayjs(promo?.endDate).utc().format() || imageUrl !== promo?.imageUrl || events !== promo?.events) changes = true;
    }

    if (!promo) {
      if (!!name && !!limit && !!promoText && !!amount && !!headerText && !!type && !!successText && !!events.length && !!type) changes = true;
    }

    return changes;
  };

  const onPromoModalClose = () => {
    onClose();
  };

  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const onEventsChange = useCallback((event: any) => () => {
    let newEvents;
    if (events.includes(event)) newEvents = events.filter(v => v !== event);
    else newEvents = [...events, event];

    setEvents(newEvents);
  }, [events]);

  const onPromoTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPromoText(e.target.value);
  }, []);

  const onHeaderTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setHeaderText(e.target.value);
  }, []);

  const onDisclaimerTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setDisclaimerText(e.target.value);
  }, []);

  const onLimitChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLimit(e.target.value);
  }, []);
  
  const onSuccessTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSuccessText(e.target.value);
  }, []);

  const onAmountChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  }, []);

  const onStartDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setStartDate(dayjs(e.target.valueAsDate).utc().add(estOffsetTime, 'hour').format());
  }, [estOffsetTime]);

  const onEndDateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEndDate(dayjs(e.target.valueAsDate).utc().add(estOffsetTime, 'hour').format());
  }, [estOffsetTime]);

  const onCampaignChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCampaign(e.target.value);
  }, []);

  const onEnabledChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEnabled(e.target.checked);
  }, []);

  const onPromoTypeClick = useCallback((option: any) => {
    setType(option.context);
  }, []);

  const onImageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setImageUrl(e.target.value);
  }, []);

  const updatePromo = async () => {
    try {
      const promoData: IPromoUpdate = {
        name: !name ? promo.name : name,
        headerText: !headerText ? promo.headerText : headerText,
        events: !events ? promo.events : events,
        type: !type ? promo.type : type,
        limit: !limit ? promo.limit : parseInt(limit),
        amount:  !amount ? promo.amount : parseInt(amount),
        enabled: enabled === undefined ? promo.enabled : enabled,
        promoText: !promoText ? promo.promoText : promoText,
        successText: !successText ? promo.successText : successText,
        startDate: !startDate ? dayjs(promo.startDate).utc().format() : dayjs(startDate).utc().format(),
        endDate: !endDate ? dayjs(promo.endDate).utc().format() : dayjs(endDate).utc().format(),
        imageUrl: !imageUrl ? promo.imageUrl : imageUrl,
        disclaimerText: !disclaimerText ? promo.disclaimerText : disclaimerText,
      };

      if (!!campaign) promoData.campaign = campaign;
      const response = await promo.updatePromo(promoData);
      promosModel.updatePromo(response?._id, response);
      onClose();
      toaster.push({ 
        message: 'Promo updated successfully',
      });
    } catch (err: any) {
      errorMessages.push({
        title: 'Error updating promo',
        message: err.message,
      });
    }
  };

  const createPromo = async () => {
    try {
      const promoInfo: IPromoUpdate = {
        name,
        headerText,
        type,
        imageUrl,
        limit: parseInt(limit),
        amount: parseInt(amount),
        startDate,
        endDate,
        enabled,
        events,
        promoText,
        successText,
        disclaimerText,
      };

      if (!!campaign) promoInfo.campaign = campaign;
      const response = await promoModel.createPromo(promoInfo);
      promosModel.addPromo(response);
      onClose();
      resetModal();
      toaster.push({ 
        message: 'Promo created successfully',
      });
    } catch (err: any) {
      errorMessages.push({
        title: 'Error creating promo',
        message: err.message,
      });
    }
  };

  const onSaveClick = () => {
    if (changesFound()) {
      if (!!promo) updatePromo();
      else createPromo();
    }
  };

  const ctas = useMemo(() => ([
    {
      id: 'cancel-promo-mod',
      text: 'Cancel',
      kind: ButtonKind.SecondaryWithIcon,
      onClick: onClose,
    },
    {
      disabled: !changesFound(),
      id: 'save-promo-mod',
      text: 'Save',
      kind: ButtonKind.Primary,
      onClick: onSaveClick,
    },
  ]), [changesFound]);

  const promoEvents = useMemo(() => (
    <PromoEventsContainer>
      <label className='regular-text'>Promo Events (what triggers promo success)</label>
      {
        Object.values(IPromoEvents).map((event) => (
          <Checkbox
            key={ event }
            checked={ events.includes(event as IPromoEvents) }
            onChange={ onEventsChange(event) }
            label={ event }
          />
        ))
      }
    </PromoEventsContainer>
  ), [events]);
  
  return (
    <PromoModalContainer
      className={ className }
      title={ `${!!promo ? 'Edit' : 'New'} Promo` }
      ctas={ ctas }
      isOpen={ isOpen }
      onClose={ onPromoModalClose }
    >
      <PromoDataContainer>
        <Row>
          <PromoInfoContainer>
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Small }
              id='promo-name-field'
              label='Promo Name*'
              onChange={ onNameChange }
              placeholder='Enter a promo name'
              value={ name }
            />
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Small }
              id='promo-header-field'
              label='Promo Header (text to show as header)*'
              onChange={ onHeaderTextChange }
              placeholder='Enter header text'
              value={ headerText }
            />
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Small }
              id='promo-text-field'
              label='Promo Text (text to show as body)*'
              onChange={ onPromoTextChange }
              placeholder='Enter promo text'
              value={ promoText }
            />
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Small }
              id='promo-success-field'
              label='Promo Success Text (text for success modal)*'
              onChange={ onSuccessTextChange }
              placeholder='Enter success text for the promo'
              value={ successText }
            />
            <TextField
              className='text-field'
              fieldSize={ TextFieldSize.Small }
              id='promo-disclaimer-field'
              label='Promo Disclaimer (disclaimer text to show on site) optional'
              onChange={ onDisclaimerTextChange }
              placeholder='Enter disclaimer text for the promo'
              value={ disclaimerText }
            />
            <TextField
              className='input-field'
              fieldSize={ TextFieldSize.Small }
              id='promo-limit-field'
              label='Limit Per User*'
              onChange={ onLimitChange }
              type='number'
              placeholder='Enter a number for the limit per user'
              value={ limit }
            />
            <TextField
              className='input-field'
              fieldSize={ TextFieldSize.Small }
              id='promo-amount-field'
              label='Dollar Amount for Promo*'
              onChange={ onAmountChange }
              type='number'
              placeholder='Enter a dollar amount for the promo'
              value={ amount }
            />
            <TextField
              className='input-field'
              fieldSize={ TextFieldSize.Small }
              id='promo-image-field'
              label='Promo Image Url*'
              onChange={ onImageChange }
              type='text'
              placeholder='Enter a hosted image url here*'
              value={ imageUrl }
            />
            <PromoTypeContainer>
              <label className='regular-label'>Promo Type*</label>
              <Dropdown
                className='promo-type-dropdown'
                options={ promoTypesOptions }
                selectedOption={ promoTypesOptions.find((option) => option.context === type) }
                onOptionClick={ onPromoTypeClick }
              />
            </PromoTypeContainer>
            { promoEvents }
            <DatesSection>
              <label className='regular-label'>Promo Dates (optional)</label>
              <div className='dates-subtext'>Input a start and end date to trigger the promo to be active from these time periods. If you do not select a start or end date, a promo will be shown as long as it is marked as "Active".</div>
              <DatesWrapper>
                <DateContainer>
                  <label className='regular-label'>Start Date</label>
                  <input
                    type='date'
                    onChange={ onStartDateChange }
                  />
                </DateContainer>
                <DateContainer>
                  <label className='regular-label'>End Date</label>
                  <input
                    type='date'
                    onChange={ onEndDateChange }
                  />
                </DateContainer>
              </DatesWrapper>
            </DatesSection>
            <TextField
              className='input-field'
              fieldSize={ TextFieldSize.Small }
              id='promo-campaign-field'
              label='Associated Campaign (optional)'
              onChange={ onCampaignChange }
              placeholder='Enter the name of the campaign you want to associate with this promo'
              value={ campaign }
            />
            <Checkbox 
              className='input-enabled'
              label='Active? (check to activate promo, uncheck to deactivate)'
              onChange={ onEnabledChange }
              checked={ enabled }
            />
          </PromoInfoContainer>
        </Row>
      </PromoDataContainer>
    </PromoModalContainer>
  );
};

export const PromoModal = observer(PromoModalBase);
