import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LoadingSpinner } from '../../../components/loading/LoadingSpinner';
import { ReportModal } from '../../../components/modals/ReportModal';
import { useErrorMessages } from '../../../contexts/error-messages-store';
import { ReportModel, ReportsModel } from '../../../models/reports';
import { H1 } from '../../../styles/components/header';
import {
  MainContainer,
  ReportCard,
  ReportCardsContainer,
  ReportsContainer,
  ReportsListContainer,
  SummaryContainer,
  SummaryItem,
} from './styles';

interface IProps {
  className?: string;
}

const ReportsBase: React.FC<IProps> = ({ className = '' }) => {
  const errorMessages = useErrorMessages();
  const reportsModel = useRef(new ReportsModel()).current;
  const [selectedReport, setSelectedReport] = useState<ReportModel>(null);

  useEffect(() => {
    reportsModel.loadSummary().catch((err) => {
      errorMessages.push({
        title: 'Error Loading Reports Summary',
        message: err.message,
      });
    });

    reportsModel.loadReports().catch((err) => {
      errorMessages.push({
        title: 'Error Loading Reports',
        message: err.message,
      });
    });
  }, []);

  const onReportCardClick = (report: ReportModel) => () => {
    setSelectedReport(report);
  };

  const onReportModalClose = useCallback(() => {
    setSelectedReport(null);
  }, []);

  const renderReports = () => {
    let reports: JSX.Element[] = [];

    if (reportsModel.busy) {
      return <LoadingSpinner />;
    }

    if (!reportsModel.reports.length) {
      return <div>No reports found</div>;
    }

    const now = dayjs().utc();

    reports = reportsModel.reports
      .filter((report) => report.reportId !== 'user-signups') // disabling user singups report since that information is now in the user report
      .map((report) => {
        const lastUpdated = report.lastUpdated.isSame(now, 'date')
          ? `Today @ ${report.lastUpdated.format('HH:MM A')}`
          : report.lastUpdated.format('MM-DD-YY HH:MM A');

        return (
          <ReportCard key={ report.reportId } onClick={ onReportCardClick(report) }>
            <div className='header'>{ report.name }</div>
            <p className='desc'>{ report.description }</p>
            <p className='last-updated'>
              last updated: <br />
              { lastUpdated }
            </p>
          </ReportCard>
        );
      });

    return <ReportCardsContainer>{ reports }</ReportCardsContainer>;
  };

  const renderSummary = () => {
    const items: JSX.Element[] = [];

    if (!!reportsModel.loadingSummary) {
      return <LoadingSpinner />;
    }

    if (!!reportsModel.summary?.users) {
      items.push(
        <SummaryItem key='users'>
          <div className='header'>Users</div>
          <div className='data'>
            <div className='row'>
              <div className='label'>total:</div>
              <div className='value'>{ reportsModel.summary.users.total?.toLocaleString('en-US') } </div>
            </div>
            <div className='row'>
              <div className='label'>
                <em>without linked account(s):</em>
              </div>
              <div className='value'>{ reportsModel.summary.users.withoutCard?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>
                <em>with one or more linked account(s):</em>
              </div>
              <div className='value'>{ reportsModel.summary.users.withCard?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>
                <em>with unlinked account(s):</em>
              </div>
              <div className='value'>{ reportsModel.summary.users.withUnlinkedCard?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>
                <em>with removed account(s):</em>
              </div>
              <div className='value'>{ reportsModel.summary.users.withRemovedCard?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>
                <em>logged in 7 days:</em>
              </div>
              <div className='value'>{ reportsModel.summary.users.loggedInLastSevenDays?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>
                <em>logged in 30 days:</em>
              </div>
              <div className='value'>{ reportsModel.summary.users.loggedInLastThirtyDays?.toLocaleString('en-US') }</div>
            </div>
          </div>
        </SummaryItem>,
      );
    }

    if (reportsModel.summary?.transactions) {
      items.push(
        <SummaryItem key='transactions'>
          <div className='header'>Transactions</div>
          <div className='data'>
            <div className='row'>
              <div className='label'>total:</div>
              <div className='value'>{ reportsModel.summary.transactions.total?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>total dollars:</div>
              <div className='value'>{ `$ ${reportsModel.summary.transactions.totalDollars?.toLocaleString('en-US')}` }</div>
            </div>
            <div className='row'>
              <div className='label'>with excluded categories:</div>
              <div className='value'>{ reportsModel.summary.transactions.totalExcludingCategories?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>dollars with excluded categories:</div>
              <div className='value'>{ `$ ${reportsModel.summary.transactions.totalDollarsExcludingCategories?.toLocaleString(
                'en-US',
              )}` }</div>
            </div>
            <div className='row'>
              <div className='label'>matched with excluded categories:</div>
              <div className='value'>{ reportsModel.summary.transactions.matchedExcludingCategories?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>matched dollars with excluded categories:</div>
              <div className='value'>{ `$ ${reportsModel.summary.transactions.matchedDollarsExcludingCategories?.toLocaleString(
                'en-US',
              )}` }</div>
            </div>
            <div className='row'>
              <div className='label'>matched excluding categories / total excluding categories:</div>
              <div className='value'>{ `${((reportsModel.summary.transactions.matchedRatioExcludingCategories || 0) * 100).toLocaleString(
                'en-US',
              )}%` }</div>
            </div>
            <div className='row'>
              <div className='label'>matched dollars excluding categories / dollars excluding categories:</div>
              <div className='value'>{ `${(
                (reportsModel.summary.transactions.matchedDollarsRatioExcludingCategories || 0) * 100
              ).toLocaleString('en-US')}%` }</div>
            </div>
          </div>
        </SummaryItem>,
      );
    }

    if (!!reportsModel.summary?.commissions) {
      items.push(
        <SummaryItem key='commissions'>
          <div className='header'>Commissions</div>
          <div className='data'>
            <div className='row'>
              <div className='label'>total:</div>
              <div className='value'>{ `${reportsModel.summary.commissions.total?.toLocaleString('en-US')}` }</div>
            </div>
            <div className='row'>
              <div className='label'>total dollars:</div>
              <div className='value'>{ `$ ${reportsModel.summary.commissions.dollars?.toLocaleString('en-US')}` }</div>
            </div>
            <div className='row'>
              <div className='label'>total karma:</div>
              <div className='value'>{ `${reportsModel.summary.commissions.totalKarmaWallet?.toLocaleString('en-US')}` }</div>
            </div>
            <div className='row'>
              <div className='label'>total karma dollars:</div>
              <div className='value'>{ `$ ${reportsModel.summary.commissions.totalKarmaWalletDollars?.toLocaleString('en-US')}` }</div>
            </div>
            <div className='row'>
              <div className='label'>total wildfire:</div>
              <div className='value'>{ `${reportsModel.summary.commissions.totalWildfire?.toLocaleString('en-US')}` }</div>
            </div>
            <div className='row'>
              <div className='label'>total wildfire dollars:</div>
              <div className='value'>{ `$ ${reportsModel.summary.commissions.totalWildfireDollars?.toLocaleString('en-US')}` }</div>
            </div>
            <div className='row'>
              <div className='label'>total kard:</div>
              <div className='value'>{ `${reportsModel.summary.commissions.totalKard?.toLocaleString('en-US')}` }</div>
            </div>
            <div className='row'>
              <div className='label'>total kard dollars:</div>
              <div className='value'>{ `$ ${reportsModel.summary.commissions.totalKardDollars?.toLocaleString('en-US')}` }</div>
            </div>
          </div>
        </SummaryItem>,
      );
    }

    if (!!reportsModel.summary?.cards) {
      items.push(
        <SummaryItem key='account'>
          <div className='header'>Accounts</div>
          <div className='data'>
            <div className='row'>
              <div className='label'>linked:</div>
              <div className='value'>{ reportsModel.summary.cards.linked?.total?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>linked depository:</div>
              <div className='value'>{ reportsModel.summary.cards.linked?.depository?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>linked credit:</div>
              <div className='value'>{ reportsModel.summary.cards.linked?.credit?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>unlinked:</div>
              <div className='value'>{ reportsModel.summary.cards.unlinked?.total?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>removed:</div>
              <div className='value'>{ reportsModel.summary.cards.removed?.total?.toLocaleString('en-US') }</div>
            </div>
          </div>
        </SummaryItem>,
      );
    }

    if (!!reportsModel.summary?.payouts) {
      items.push(
        <SummaryItem key='payouts'>
          <div className='header'>Payouts</div>
          <div className='data'>
            <div className='row'>
              <div className='label'>pending total:</div>
              <div className='value'>{ reportsModel.summary.payouts?.pending?.total?.toLocaleString('en-US') } </div>
            </div>
            <div className='row'>
              <div className='label'>
                <em>pending marqeta:</em>
              </div>
              <div className='value'>{ reportsModel.summary.payouts?.pending?.marqeta?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>
                <em>pending paypal:</em>
              </div>
              <div className='value'>{ reportsModel.summary.payouts?.pending?.paypal?.toLocaleString('en-US') }</div>
            </div>
          </div>
        </SummaryItem>,
      );
    }

    if (!!reportsModel.summary?.offsets) {
      items.push(
        <SummaryItem key='carbon-offsets'>
          <div className='header'>Carbon Offsets</div>
          <div className='data'>
            <div className='row total-offsets'>
              <div className='label'>total:</div>
              <div className='value'>{ `${reportsModel.summary.offsets.total?.toLocaleString('en-US')}` }</div>
            </div>
            <div className='row total-offset-dollars'>
              <div className='label'>total dollars:</div>
              <div className='value'>{ `$ ${reportsModel.summary.offsets.dollars?.toLocaleString('en-US')}` }</div>
            </div>
            <div className='row total-offset-tonnes'>
              <div className='label'>total tons:</div>
              <div className='value'>{ `${reportsModel.summary.offsets.tons?.toLocaleString('en-US')} MT` }</div>
            </div>
          </div>
        </SummaryItem>,
      );
    }

    if (!!reportsModel.summary?.logins) {
      items.push(
        <SummaryItem key='logins'>
          <div className='header'>Logins</div>
          <div className='data'>
            <div className='row'>
              <div className='label'>
                <em>7 day total:</em>
              </div>
              <div className='value'>{ reportsModel.summary.logins.sevenDayTotal?.toLocaleString('en-US') }</div>
            </div>
            <div className='row'>
              <div className='label'>
                <em>30 day total:</em>
              </div>
              <div className='value'>{ reportsModel.summary.logins.thirtyDayTotal?.toLocaleString('en-US') }</div>
            </div>
          </div>
        </SummaryItem>,
      );
    }
    return items;
  };
  return (
    <ReportsContainer className={ className } title='Reports'>
      <H1>Reports</H1>
      <MainContainer>
        <SummaryContainer>
          <div className='header'>Summary</div>
          { renderSummary() }
        </SummaryContainer>
        <ReportsListContainer>{ renderReports() }</ReportsListContainer>
      </MainContainer>
      <ReportModal isOpen={ !!selectedReport } onClose={ onReportModalClose } report={ selectedReport } />
    </ReportsContainer>
  );
};

export const Reports = observer(ReportsBase);
