import styled from 'styled-components';
import { FlexCol, BasicContainer } from '../../../../styles/styles';

export const CommissionPayoutOverviewItemContainer = styled.div`
  ${FlexCol}
  ${BasicContainer}
  background: ${({ theme }) => theme.colors.light};
  margin-bottom: 10px;

  .pay-cta {
    margin-top: 40px;
  }
`;

export const CommissionPayoutsOverviewsRowItem = styled.div`
  padding: 5px 0;
`;
