import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { LegalItemContainer } from './styles';
import { IKarmaCardLegalText, KarmaCardLegalModel } from '../../../../models/karmaCard';
import { Button } from '../../../../components/Button';
import { ButtonKind } from '../../../../components/Button/styles';
import { PencilIcon } from '../../../../components/svgs/icons/PencilIcon';
import { IThemeProps } from '../../../../styles/themes';
import { withTheme } from 'styled-components';
import { EditLegalTextModal } from '../EditLegalTextModal';

interface IProps extends IThemeProps {
  className?: string;
  legalItem: IKarmaCardLegalText;
  karmaCardLegalTextModel?: KarmaCardLegalModel;
}

const LegalItemBase: React.FC<IProps> = ({
  className = '',
  legalItem,
  theme,
  karmaCardLegalTextModel,
}) => {
  const { name, text, createdOn, lastModified } = legalItem;
  const [editLegalTextModalOpen, setEditLegalTextModalOpen] = useState(false);
  const dateCreated = new Date(createdOn).toLocaleString();
  const dateModified = new Date(lastModified).toLocaleString();

  const onEditItemClickHandler = useCallback(() => {
    setEditLegalTextModalOpen(true);
  },[]);

  const onModalClose = useCallback(() => {
    setEditLegalTextModalOpen(false);
  },[]);

  return (
    <LegalItemContainer className={ className } >
      <h3 className='legal-text-name'>{ name }</h3>
      <p>{ text }</p>
      <div className='bottom-section'>
        <div className='date-container'>
          <p>
            <strong>Created On:</strong> 
            { dateCreated }
          </p>
          <p>
            <strong>Last Modified On:</strong>
            { dateModified }
          </p>
        </div>
        <Button 
          className='edit-button'
          kind={ ButtonKind.Blank }
          onClick={ onEditItemClickHandler }
        >
          <PencilIcon stroke={ theme.colors.grey3 } />
        </Button>
      </div>
      <EditLegalTextModal
        isOpen={ editLegalTextModalOpen }
        onClose={ onModalClose }
        legalItem={ legalItem }
        karmaCardLegalTextModel={ karmaCardLegalTextModel }
      />
    </LegalItemContainer>
  );
};

const LegalItemTheme = observer(LegalItemBase);
export const LegalItem = withTheme(LegalItemTheme);
