import styled from 'styled-components';

export const UserInfo = styled.div`
  ul {
    list-style: none;
    padding: 0;
  }

  .warning {
    color: ${({ theme }) => theme.colors.danger};
    font-style: italic;
  }
`;
