import styled from 'styled-components';
import { MainAdminContainer } from '../../../components/MainAdminContainer';
import { FlexRow } from '../../../styles/styles';

export const WebAnalyticsContainer = styled(MainAdminContainer)`
  background: ${({ theme }) => theme.colors.grey1};
  min-height: 100vh;
  position: relative;

  .main-content {
    align-self: flex-start;
  }

  .loading-spinner {
    left: calc(50% + 150px);
    position: absolute;
    top: 300px;
  }
`;

export const PageControlContainer = styled.div`
  ${FlexRow}
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const SelectField = styled.select`
  border-radius: 999px;
  margin-top: 20px;
  min-width: 250px;
  padding: 10px;
`;

export const MainContainer = styled.div`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  flex-grow: 1;
  height: calc(100% - 90px);
  margin-top: 20px;
  padding: 20px;
`;
